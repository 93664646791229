import React from "react";
import GlobalGrowl from "../grow/GlobalGrow";
import ModuleHelper from "../../util/ModuleHelper";
import ConfirmDialog from "../confirm_dialog/ConfirmDialog";
import GlobalLoader from "../loader/GlobalLoader";

interface IProps {
  className?: string;
  children: React.ReactElement;
}

const Main = (props: IProps): React.ReactElement => {
  const render = (): React.ReactElement => {
    return (
      <main className={props.className}>
        <GlobalGrowl ref={(ref: any) => (ModuleHelper.grow = ref)} />
        <GlobalLoader ref={(ref: any) => (ModuleHelper.loader = ref)} />
        <ConfirmDialog ref={(ref: any) => (ModuleHelper.confirmDialog = ref)} />
        {props.children}
      </main>
    );
  };

  return render();
};

export default Main;
