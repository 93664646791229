import { IDealershipContext } from "./index";
import { IDealershipContextDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class DealershipContext implements IDealershipContext {
  _bgrImgPublicURL: string;
  _connectedToCdk: boolean;
  _connectedToCrm: boolean;
  _country: string;
  _googlePlaceId: string;
  _id: number;
  _mainAddress: string;
  _mainPhone: string;
  _mainUrl: string;
  _name: string;
  _primaryContactName: string;
  _primaryContactPhone: string;
  _serviceAddress: string;
  _servicePhone: string;
  _serviceUrl: string;

  constructor(args?: IDealershipContextDTO) {
    this._bgrImgPublicURL = Utils.default(args?.bgrImgPublicURL, "");
    this._connectedToCdk = Utils.default(args?.connectedToCdk, false);
    this._connectedToCrm = Utils.default(args?.connectedToCrm, false);
    this._country = Utils.default(args?.country, "");
    this._googlePlaceId = Utils.default(args?.googlePlaceId, "");
    this._id = Utils.default(args?.id, 0);
    this._mainAddress = Utils.default(args?.mainAddress, "");
    this._mainPhone = Utils.default(args?.mainPhone, "");
    this._mainUrl = Utils.default(args?.mainUrl, "");
    this._name = Utils.default(args?.name, "");
    this._primaryContactName = Utils.default(args?.primaryContactName, "");
    this._primaryContactPhone = Utils.default(args?.primaryContactPhone, "");
    this._serviceAddress = Utils.default(args?.serviceAddress, "");
    this._servicePhone = Utils.default(args?.servicePhone, "");
    this._serviceUrl = Utils.default(args?.serviceUrl, "");
  }

  get bgrImgPublicURL(): string {
    return this._bgrImgPublicURL;
  }

  set bgrImgPublicURL(value: string) {
    this._bgrImgPublicURL = value;
  }

  get connectedToCdk(): boolean {
    return this._connectedToCdk;
  }

  set connectedToCdk(value: boolean) {
    this._connectedToCdk = value;
  }

  get connectedToCrm(): boolean {
    return this._connectedToCrm;
  }

  set connectedToCrm(value: boolean) {
    this._connectedToCrm = value;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get googlePlaceId(): string {
    return this._googlePlaceId;
  }

  set googlePlaceId(value: string) {
    this._googlePlaceId = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get mainAddress(): string {
    return this._mainAddress;
  }

  set mainAddress(value: string) {
    this._mainAddress = value;
  }

  get mainPhone(): string {
    return this._mainPhone;
  }

  set mainPhone(value: string) {
    this._mainPhone = value;
  }

  get mainUrl(): string {
    return this._mainUrl;
  }

  set mainUrl(value: string) {
    this._mainUrl = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get primaryContactName(): string {
    return this._primaryContactName;
  }

  set primaryContactName(value: string) {
    this._primaryContactName = value;
  }

  get primaryContactPhone(): string {
    return this._primaryContactPhone;
  }

  set primaryContactPhone(value: string) {
    this._primaryContactPhone = value;
  }

  get serviceAddress(): string {
    return this._serviceAddress;
  }

  set serviceAddress(value: string) {
    this._serviceAddress = value;
  }

  get servicePhone(): string {
    return this._servicePhone;
  }

  set servicePhone(value: string) {
    this._servicePhone = value;
  }

  get serviceUrl(): string {
    return this._serviceUrl;
  }

  set serviceUrl(value: string) {
    this._serviceUrl = value;
  }
}
