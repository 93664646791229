import React, { CSSProperties } from "react";
import { Box } from "@mui/material";
import { globalColors, globalStyle } from "../../util/ThemePalette";
import EmployeeAvatar from "../avatar/EmployeeAvatar";
import Typography from "@mui/material/Typography";
import { IBaseUser } from "../../models/user";
import { UserRole } from "../../common/Enums";
import Sales from "../../models/user/Sales";
import Advisor from "../../models/user/Advisor";
import Technician from "../../models/user/Technician";
import { capitalCase } from "change-case";

interface IProps {
  style?: CSSProperties;
  user: IBaseUser;
}

const UserDetails = (props: IProps): React.ReactElement => {
  const getEmployeeRole = (employee: IBaseUser): UserRole => {
    if (employee instanceof Advisor) {
      return UserRole.ADVISOR;
    }

    if (employee instanceof Technician) {
      return UserRole.TECHNICIAN;
    }

    if (employee instanceof Sales) {
      return UserRole.SALESPERSON;
    }

    return UserRole.UNKNOWN;
  };

  const render = (): React.ReactElement => {
    const { user } = props;

    const role = getEmployeeRole(user);

    return (
      <Box
        alignItems={"center"}
        sx={{ padding: 1, display: "flex" }}
        style={{
          ...{
            border: globalStyle.border,
            borderRadius: globalStyle.borderRadius,
          },
          ...props.style,
        }}
      >
        <EmployeeAvatar
          firstName={user.firstName}
          avatarURL={user.avatarURL}
          sx={{ backgroundColor: globalColors.avatarGray }}
          style={{ margin: 2, width: 50, height: 50 }}
        />

        <Box sx={{ paddingLeft: 1 }}>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
              {`${user.firstName} ${user.lastName}`.trim()}
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: 10 }}>
              {capitalCase(role)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return render();
};

export default UserDetails;
