import React, { useEffect, useState } from "react";
import { Chip, Alert, Grid, Dialog, Button, Box } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import BuildIcon from "@mui/icons-material/Build";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SendBirdChannel from "../../sendbird/SendBirdChannel";
import RepairOrderService from "../../../services/RepairOrder.service";
import { isEmpty } from "lodash";
import { useGlobalStyles } from "../../../util/ThemePalette";
import { UserRole } from "../../../common/Enums";
import ModuleHelper from "../../../util/ModuleHelper";
import { makeStyles } from "@mui/styles";
import MessageIcon from "@mui/icons-material/Message";
import Loader from "../../loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import VisibleContent from "../../visable_content/VisibleContent";
import { IRepairOrder } from "../../../models/repair_order";

interface IProps {
  repairOrder: IRepairOrder;
  repairOrderService: RepairOrderService;
}

type chatWith = "ALL" | "ADVISOR" | "TECH" | "NONE";

export const useStyles = makeStyles(() => ({
  dialog: {
    "&&": {
      "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded": {
        margin: 0,
        height: "100%",
        width: "100%",
        maxHeight: 900,
        borderRadius: 0,
        overflow: "hidden",
      },
    },
  },
}));

const ROMessages = (props: IProps): React.ReactElement => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [pending, setPending] = useState<boolean>(false);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [channelUrl, setChannelUrl] = useState<string>();

  const [error, setError] = useState<string>();
  const [chatWith, setChatWith] = useState<chatWith>("ADVISOR");

  useEffect(() => {
    if (showChat) {
      setPending(true);
      setError(undefined);
      setChannelUrl(undefined);

      const roles = getRoles(chatWith);
      props.repairOrderService
        .getRoMessageChannel(props.repairOrder.id, roles)
        .then((response) => {
          setChannelUrl(response.data.chatUrl);
        })
        .catch((error) => {
          setError(ModuleHelper.getErrorMessage(error));
        })
        .finally(() => setPending(false));
    }
  }, [showChat, chatWith, props]);

  const getRoles = (chatWith: chatWith): UserRole[] => {
    const roles: UserRole[] = [];

    if (chatWith === "ALL" || chatWith === "ADVISOR") {
      roles.push(UserRole.ADVISOR);
    }

    if (chatWith === "ALL" || chatWith === "TECH") {
      roles.push(UserRole.TECHNICIAN);
    }

    return roles;
  };

  const contentRender = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Loader pending={pending} disableAnimation={true} />
        <Grid
          container
          spacing={1}
          sx={{ padding: 1, borderBottom: "1px solid gray" }}
        >
          <Grid item>
            <Chip
              icon={<EngineeringIcon />}
              label={"Advisor"}
              color={chatWith === "ADVISOR" ? "primary" : "default"}
              className={globalClasses.chip}
              onClick={() => setChatWith("ADVISOR")}
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<BuildIcon />}
              label={"Tech"}
              color={chatWith === "TECH" ? "primary" : "default"}
              className={globalClasses.chip}
              onClick={() => setChatWith("TECH")}
            />
          </Grid>
          <Grid item>
            <Chip
              icon={<GroupIcon color={"primary"} />}
              label={"All"}
              color={chatWith === "ALL" ? "primary" : "default"}
              className={globalClasses.chip}
              onClick={() => setChatWith("ALL")}
            />
          </Grid>
        </Grid>
        <VisibleContent visible={!isEmpty(error)}>
          <Box sx={{ height: "auto" }}>
            <Alert severity="warning">{error}</Alert>
          </Box>
        </VisibleContent>
        <div style={{ height: "calc(100% - 84px)" }}>
          <SendBirdChannel channelUrl={channelUrl} />
        </div>
        <Button
          fullWidth={true}
          startIcon={<CloseIcon />}
          variant={"contained"}
          style={{ borderRadius: 0 }}
          onClick={() => setShowChat(false)}
        >
          Close
        </Button>
      </React.Fragment>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Button
          fullWidth={true}
          startIcon={<MessageIcon />}
          variant={"contained"}
          onClick={() => setShowChat(true)}
        >
          Messages
        </Button>

        <Dialog open={showChat} className={classes.dialog}>
          {contentRender()}
        </Dialog>
      </React.Fragment>
    );
  };

  return render();
};

export default ROMessages;
