import { IVehicleDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class Vehicle implements IVehicle {
  private _cdkId: string;
  private _color: string;
  private _id: number;
  private _lastServiceDate: string;
  private _manufacturer: string;
  private _manufacturingYear: number;
  private _mileage: number;
  private _mileageLastVisit: number;
  private _model: string;
  private _updated: string;
  private _vin: string;

  constructor(args?: IVehicleDTO) {
    this._cdkId = Utils.default(args?.cdkId, "");
    this._color = Utils.default(args?.color, "");
    this._id = Utils.default(args?.id, 0);
    this._lastServiceDate = Utils.default(args?.lastServiceDate, "");
    this._manufacturer = Utils.default(args?.manufacturer, "");
    this._manufacturingYear = Utils.default(args?.manufacturingYear, 0);
    this._mileage = Utils.default(args?.mileage, 0);
    this._mileageLastVisit = Utils.default(args?.mileageLastVisit, 0);
    this._model = Utils.default(args?.model, "");
    this._updated = Utils.default(args?.updated, "");
    this._vin = Utils.default(args?.vin, "");
  }

  get cdkId(): string {
    return this._cdkId;
  }

  set cdkId(value: string) {
    this._cdkId = value;
  }

  get color(): string {
    return this._color;
  }

  set color(value: string) {
    this._color = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get lastServiceDate(): string {
    return this._lastServiceDate;
  }

  set lastServiceDate(value: string) {
    this._lastServiceDate = value;
  }

  get manufacturer(): string {
    return this._manufacturer;
  }

  set manufacturer(value: string) {
    this._manufacturer = value;
  }

  get manufacturingYear(): number {
    return this._manufacturingYear;
  }

  set manufacturingYear(value: number) {
    this._manufacturingYear = value;
  }

  get mileage(): number {
    return this._mileage;
  }

  set mileage(value: number) {
    this._mileage = value;
  }

  get mileageLastVisit(): number {
    return this._mileageLastVisit;
  }

  set mileageLastVisit(value: number) {
    this._mileageLastVisit = value;
  }

  get model(): string {
    return this._model;
  }

  set model(value: string) {
    this._model = value;
  }

  get updated(): string {
    return this._updated;
  }

  set updated(value: string) {
    this._updated = value;
  }

  get vin(): string {
    return this._vin;
  }

  set vin(value: string) {
    this._vin = value;
  }
}
