import { AxiosResponse } from "axios";
import {
  ILaborOperationDTO,
  INotificationResult,
  IOpCodeDTO,
  IPageResponse,
  IRepairOrderResponse,
  IRepairOrderDtoDTO,
} from "../../index";
import { defaultTo, forEach, get, set, map } from "lodash";
import ModuleHelper from "../util/ModuleHelper";
import { UserRole } from "../common/Enums";
import RepairOrder from "../models/repair_order/RepairOrder";
import { IRepairOrder } from "../models/repair_order";
import { ILIFile } from "../models/file";
import LIFile from "../models/file/LIFile";

interface IRepairOrderApiClient {
  getNotification(id: number): Promise<AxiosResponse<INotificationResult>>;
  getRepairOrderFiles(repairOrderId: number): Promise<AxiosResponse>;
  approveDeclineRS(
    lineItemId: number,
    approve: boolean
  ): Promise<AxiosResponse>;
  getRoMessageChannel(roId: number, roles: UserRole[]): Promise<AxiosResponse>;
  getRepairOrder(
    id: number
  ): Promise<AxiosResponse<IPageResponse<IRepairOrderDtoDTO[]>>>;
}

class RepairOrderApiClient implements IRepairOrderApiClient {
  private readonly prefix = "repair-order";

  getNotification(id: number): Promise<AxiosResponse> {
    return window.apiClient.get(
      `${this.prefix}/ro-by-notification?notificationId=${id}`
    );
  }

  getRepairOrderFiles(repairOrderId: number): Promise<AxiosResponse> {
    return window.apiClient.get(`file/li/files/all?roId=${repairOrderId}`);
  }

  approveDeclineRS(
    lineItemId: number,
    approve: boolean
  ): Promise<AxiosResponse> {
    return window.apiClient.put(
      `recommended-service/line-item/recommended-service-response?lineItemId=${lineItemId}&response=${approve}`
    );
  }

  getRoMessageChannel(roId: number, roles: UserRole[]): Promise<AxiosResponse> {
    const params = new URLSearchParams();
    params.append("roId", `${roId}`);

    forEach(roles, (role) => {
      params.append("responseRoles", role);
    });

    return window.apiClient.get("sendbird/chat/channel", { params: params });
  }

  getRepairOrder(
    id: number
  ): Promise<AxiosResponse<IPageResponse<IRepairOrderDtoDTO[]>>> {
    return window.apiClient.get(this.prefix, {
      params: {
        id: id,
        page: 0,
        perPage: 1,
        shortVersion: false,
      },
    });
  }
}

export default class RepairOrderService {
  private repairOrderApiClient: IRepairOrderApiClient;

  constructor() {
    this.repairOrderApiClient = new RepairOrderApiClient();
  }

  public async getNotification(id: number): Promise<IRepairOrder> {
    const response = await this.repairOrderApiClient.getNotification(id);

    return await this.getRepairOrder(response.data.resultRepairOrder.id);
  }

  public async getRepairOrderFiles(repairOrderId: number): Promise<ILIFile[]> {
    const response = await this.repairOrderApiClient.getRepairOrderFiles(
      repairOrderId
    );

    return map(response.data, (item) => new LIFile(item));
  }

  public async approveDeclineRS(
    lineItemId: number,
    approve: boolean
  ): Promise<AxiosResponse> {
    return this.repairOrderApiClient.approveDeclineRS(lineItemId, approve);
  }

  public async getRoMessageChannel(
    roId: number,
    roles: UserRole[]
  ): Promise<AxiosResponse> {
    return this.repairOrderApiClient.getRoMessageChannel(roId, roles);
  }

  public async getRepairOrder(id: number): Promise<IRepairOrder> {
    const response = await this.repairOrderApiClient.getRepairOrder(id);

    const repairOrderDTO = get(response, "data.result[0]");
    const repairOrderResponse =
      RepairOrderService.convertRepairOrderDTO(repairOrderDTO);

    return new RepairOrder(repairOrderResponse);
  }

  public static getOperationDesc(laborOperation: ILaborOperationDTO): string {
    const opCode: IOpCodeDTO = get(laborOperation, "opCode");

    return ModuleHelper.getValueIfDefault(
      get(opCode, "additionalDesc"),
      get(opCode, "initDesc")
    );
  }

  private static convertRepairOrderDTO(
    repairOrderDto: IRepairOrderDtoDTO
  ): IRepairOrderResponse {
    const lineItems = defaultTo(repairOrderDto.lineItems, []);

    forEach(lineItems, (li) => {
      try {
        set(
          li,
          "laborOperation",
          defaultTo(get(li, "laborOperations[0]"), {
            price: 0,
            opCode: { id: 0 },
          })
        );
        set(
          li,
          "laborOperation.part",
          defaultTo(get(li.laborOperation, "parts[0]"), {})
        );
        li.spentTime = JSON.parse(li.spentTime);
        li.estDuration = JSON.parse(li.estDuration);
      } catch (e) {}
    });

    return repairOrderDto as unknown as IRepairOrderResponse;
  }
}
