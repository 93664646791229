import { IMainState } from "../../index";
import actions from "./actions";

export const setNotificationToken = (payload: string): any => ({
  type: actions.SET_NOTIFICATION_TOKEN,
  payload: payload,
});

// export const updateDealershipConfig = (payload: any): any => ({
//   type: actions.UPDATE_DEALERSHIP_CONFIG,
//   payload: payload,
// });

export default (
  state: IMainState = {
    notificationToken: "",
    // dealershipConfig: "",
  },
  action: { type: string; payload?: any }
): IMainState => {
  state = { ...state };

  switch (action.type) {
    case actions.SET_NOTIFICATION_TOKEN:
      state.notificationToken = action.payload;
      return state;
    // case actions.UPDATE_DEALERSHIP_CONFIG:
    //   state.dealershipConfig = action.payload;
    //   return state;
    default:
      return state;
  }
};
