import React, { useEffect, useState } from "react";
import DealershipService from "../../../services/Dealership.service";
import ModuleHelper from "../../../util/ModuleHelper";
import DealershipHelper from "../../../util/DealershipHelper";
import { IDealershipNotificationConfig, IStore } from "../../../../index";
import NotificationEvents from "./NotificationEvents";
import NotificationChannels from "./NotificationChannels";
import { shallowEqual, useSelector } from "react-redux";
import { Box } from "@mui/material";
import PaperWrapper from "../../../components/paper/PaperWrapper";

const NotificationSettings = (): React.ReactElement => {
  const dealershipService = new DealershipService();

  const [pending, setPending] = useState<boolean>(false);

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  const [notificationSettings, setNotificationSettings] =
    useState<IDealershipNotificationConfig>(
      DealershipHelper.getDefaultNotificationSettings()
    );

  useEffect(() => {
    setPending(true);
    ModuleHelper.loader.show();
    dealershipService
      .getNotificationDetails()
      .then((response) => {
        setNotificationSettings(response.data);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => {
        setPending(false);
        ModuleHelper.loader.hide();
      });
  }, [dealerContext]);

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper>
        <Box sx={{ marginBottom: 1 }}>
          <NotificationChannels
            saveDisabled={pending}
            dealershipService={dealershipService}
            notificationChannels={notificationSettings.notificationChannels}
          />
        </Box>
        <Box>
          <NotificationEvents
            saveDisabled={pending}
            dealershipService={dealershipService}
            notificationMessages={notificationSettings.notificationMessages}
            notificationEvents={notificationSettings.notificationEventsSettings}
          />
        </Box>
      </PaperWrapper>
    );
  };

  return render();
};

export default NotificationSettings;
