import { makeStyles } from "@mui/styles";
import { globalColors } from "../../../util/ThemePalette";

export const useStyles = makeStyles(() => ({
  loginWrapper: {
    "&&": {
      "& .css-1h5d2dq-MuiInputBase-root-MuiOutlinedInput-root": {
        borderRadius: "10px",
      },
    },
  },
  verifyButton: {
    "&&": {
      backgroundColor: "rgba(253, 106, 8, 0.1)",
      color: "#606060",
      "&:hover": {
        backgroundColor: "#fd7e08",
        borderColor: "#0062cc",
        boxShadow: "none",
        color: "#ffffff",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#fd7e08",
        borderColor: "#005cbf",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
  },
  textFieldRoot: {
    borderRadius: "20px",
  },
  logo: {
    position: "absolute",
    top: "-50px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100px",
    height: "100px",
    marginBottom: "auto",
    marginTop: "auto",
  },
  card: {
    top: "calc(10vh)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: globalColors.white,
    borderRadius: "20px !important",
    minHeight: "350px",
    minWidth: "270px",
    padding: "70px 30px 30px 30px",
    position: "relative",
    overflow: "initial !important",
  },
}));
