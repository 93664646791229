import React from "react";
import { Box } from "@mui/material";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../index";
import { get } from "lodash";
import { globalStyle } from "../../util/ThemePalette";
import Utils from "../../util/Utils";
import { IBaseUser } from "../../models/user";
import VisibleContent from "../visable_content/VisibleContent";
import DealershipHeaderEmployees from "./DealershipHeaderEmployees";

interface IProps {
  readonly employees?: IBaseUser[];
}

const DealershipHeader = (props: IProps): React.ReactElement => {
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const render = (): React.ReactElement => {
    const employees = Utils.default(props.employees, []);

    const imageUrl = Utils.default(
      user.dealerContext.bgrImgPublicURL,
      "./images/default-dealership.png"
    );

    const dealershipName = get(user, "dealerContext.name");

    return (
      <Box sx={{ position: "relative" }}>
        <VisibleContent visible={employees.length > 0}>
          <DealershipHeaderEmployees employees={employees} />
        </VisibleContent>
        <img
          alt={""}
          width={"100%"}
          loading={"lazy"}
          src={imageUrl}
          style={{
            objectFit: "cover",
            borderRadius: globalStyle.borderRadius,
            minHeight: 250,
          }}
        />
        <div
          style={{
            left: 0,
            right: 0,
            bottom: 8,
            padding: 5,
            position: "absolute",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderBottomLeftRadius: globalStyle.borderRadius,
            borderBottomRightRadius: globalStyle.borderRadius,
          }}
        >
          <span style={{ fontWeight: "bold", color: "white" }}>
            {dealershipName}
          </span>
        </div>
      </Box>
    );
  };

  return render();
};

export default DealershipHeader;
