import React, { CSSProperties, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { IEmployeeInfo } from "../../../../index";
import { globalColors, globalStyle } from "../../../util/ThemePalette";
import UserService from "../../../services/User.service";
import { Box, CircularProgress } from "@mui/material";
import { IBaseUser } from "../../../models/user";
import EmployeeAvatar from "../../avatar/EmployeeAvatar";

interface IProps {
  readonly employee: IBaseUser;

  readonly style?: CSSProperties;
}

const userService = new UserService();

export const EmployeeScoreDetails = (
  props: IProps
): React.ReactElement | null => {
  const [pending, setPending] = useState<boolean>(true);
  const [employee, setEmployee] = useState<IEmployeeInfo>({
    user: props.employee,
    averageScore: 0,
  });

  useEffect(() => {
    setPending(true);
    userService
      .getUserDetails(props.employee.id)
      .then((response) => {
        setEmployee(response);
      })
      .finally(() => setPending(false));
  }, []);

  const render = (): React.ReactElement | null => {
    const lastName = employee.user.lastName;
    const avatarURL = employee.user.avatarURL;
    const firstName = employee.user.firstName;

    return (
      <Box
        alignItems={"center"}
        sx={{ padding: 1, display: "flex" }}
        style={{
          ...{
            border: globalStyle.border,
            borderRadius: globalStyle.borderRadius,
          },
          ...props.style,
        }}
      >
        <EmployeeAvatar
          firstName={firstName}
          avatarURL={avatarURL}
          defaultURL={props.employee.avatarURL}
          sx={{ backgroundColor: globalColors.avatarGray }}
          style={{ margin: 2, width: 50, height: 50 }}
        />

        {pending ? (
          <Box sx={{ paddingLeft: 1 }}>
            <CircularProgress color={"primary"} size={20} />
          </Box>
        ) : (
          <Box sx={{ paddingLeft: 1 }}>
            <Box>
              <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                {`${firstName} ${lastName}`.trim()}
              </Typography>
            </Box>

            <Box>
              <div style={{ display: "flex", marginTop: 5 }}>
                <Rating name="read-only" value={1} max={1} readOnly />
                <Typography
                  component="legend"
                  style={{ marginTop: 3, marginLeft: 2 }}
                >
                  {parseFloat(`${employee.averageScore}`).toFixed(1)} Rating
                </Typography>
              </div>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return render();
};
