import React, { useEffect, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input-field";
import { get, isEmpty } from "lodash";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import ModuleHelper from "../../../util/ModuleHelper";
import { globalColors, useGlobalStyles } from "../../../util/ThemePalette";
import { IDealershipConfig } from "../../../models/dealership_config";
import { IBaseUser } from "../../../models/user";
import StripeUrlGenerator from "../../../helpers/generators/StripeUrl.generator";
import VisibleContent from "../../visable_content/VisibleContent";
import { ITipArgs } from "../../../../index";
import route from "../../../common/route";
import { useStyles } from "./Tips.styles";

interface IProps {
  readonly config: IDealershipConfig;
  readonly vehicle: IVehicle;
  readonly employee: IBaseUser;

  onSubmit(args: ITipArgs): Promise<any>;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",

  width: "100%",
  lineHeight: 1.5,
});

const TipModal = React.forwardRef((props: IProps, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const globalClasses = useGlobalStyles();

  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);

  const [error, setError] = useState<string>();

  useEffect(() => {
    const min = get(props, "config.tipMinAmountInDollars", 0);
    const max = get(props, "config.tipMaxAmountInDollars", 0);

    setMin(min);
    setMax(max);
    setValue(min);
  }, [props.config]);

  const show = (): void => {
    setOpen(true);
  };

  const hide = (): void => {
    setOpen(false);
    setPending(false);
    setError(undefined);
  };

  const onCancel = (): void => {
    history.push(route.thankYou.pathname);
  };

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const onSubmit = (): void => {
    if (value < min || value > max) {
      setError(`The tip should be between [${min} - ${max}]`);
      return;
    }

    const urls = new StripeUrlGenerator().generate();
    setError(undefined);
    setPending(true);
    props
      .onSubmit({
        cancelURL: urls.cancelURL,
        successURL: urls.successURL,
        amount: value,
      })
      .then((response) => (window.location.href = response?.data?.redirectURL))
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setPending(false));
  };

  const onTipInputChange = (val?: string): void => {
    setValue(parseInt(`${val}`) || 0);
  };

  const render = (): React.ReactElement => {
    const { config, employee, vehicle } = props;

    const tipTitle = config.getTipTitle(employee, vehicle);

    return (
      <Dialog open={open} keepMounted={true} onClose={hide}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={"bold"} textAlign={"center"}>
            Leave a tip!
          </Typography>
          <IconButton onClick={hide}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ maxWidth: 300 }}>
          <Typography variant={"h4"} sx={{ marginBottom: 1 }}>
            {tipTitle}
          </Typography>

          <CurrencyInput
            placeholder={"$0"}
            decimalsLimit={2}
            value={value}
            allowNegativeValue={false}
            maxLength={4}
            prefix={"$"}
            onValueChange={onTipInputChange}
            className={classes.currency}
          />

          <VisibleContent visible={!isEmpty(error)}>
            <Alert severity={"warning"}>{error}</Alert>
          </VisibleContent>

          <div className={classes.tipDiv}>
            {config.tipIncrementationInDollars.map((item, index) => (
              <TipItem
                key={index}
                value={item}
                selected={item === value}
                onClick={setValue}
              />
            ))}
          </div>

          <div style={{ marginTop: 30 }}>
            <SubmitButton
              loading={pending}
              variant="contained"
              size={"medium"}
              disableRipple
              color={"success"}
              disabled={pending}
              onClick={onSubmit}
              className={globalClasses.saveButton}
            >
              {config.leaveTipSubmitButton}
            </SubmitButton>
            <Button
              fullWidth={true}
              style={{ backgroundColor: "black", color: "white", marginTop: 5 }}
              variant={"contained"}
              size={"medium"}
              onClick={onCancel}
            >
              {config.leaveTipCancelButton}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return render();
});

export default TipModal;

interface ITipItem {
  readonly value: number;
  readonly selected: boolean;

  onClick(number: number): void;
}

const TipItem = (props: ITipItem): React.ReactElement => {
  const classes = useStyles();

  return (
    <div
      onClick={() => props.onClick(props.value)}
      style={{
        cursor: "pointer",
        backgroundColor: props.selected
          ? globalColors.orange
          : globalColors.white,
      }}
      className={classes.automaticTip}
    >
      <Typography fontWeight={"bold"}>${props.value}</Typography>
    </div>
  );
};
