import BaseUser from "./BaseUser";
import { IBaseUserDTO } from "../../../index";
import Utils from "../../util/Utils";
import { Images } from "../../util/constants";

export default class Sales extends BaseUser {
  constructor(args?: IBaseUserDTO) {
    super(args);
    this.avatarURL = Utils.default(this.avatarURL, Images.SALESPERSON);
  }
}
