import React, { useEffect, useState } from "react";
import DealershipService from "../../services/Dealership.service";
import ModuleHelper from "../../util/ModuleHelper";
import { IDealershipDTO, IDealershipContextDTO, IStore } from "../../../index";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { defaultPageSize } from "../../util/constants";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { get, isEmpty } from "lodash";
import DealershipDetails from "../dealership/profile/DealershipDetails";
import UserService from "../../services/User.service";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormTextField } from "../../components/shared/form_text_field/FormTextField";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useStyles } from "./Dealerships.styles";
import { globalStyle, useGlobalStyles } from "../../util/ThemePalette";
import PaperWrapper from "../../components/paper/PaperWrapper";

const validationSchema = Yup.object().shape({
  adminPassword: Yup.string().required("Required"),
});

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  width: "100%",
});

interface IAdminScheme {
  adminPassword?: string;
  dealership?: IDealershipDTO;

  hardDelete?: boolean;
  removeFromStorage?: boolean;
}

const Dealerships = (): React.ReactElement => {
  const userService = new UserService();
  const dealershipService = new DealershipService();

  const { t } = useTranslation();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [dealerships, setDealerships] = useState<IDealershipContextDTO[]>([]);

  const [adminScheme, setAdminScheme] = useState<IAdminScheme>({});

  const [showPasswordDialog, setShowPasswordDialog] = useState<boolean>(false);

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  const [tempDealershipContext, setTempDealershipContext] =
    useState<IDealershipContextDTO>();

  useEffect(() => {
    getData();
  }, []);

  const getData = (): void => {
    setLoading(true);
    dealershipService
      .getAllDealerships(0, 10000)
      .then((response) => {
        setDealerships(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const getActions = (props: GridRowParams): React.ReactElement[] => {
    return [
      <GridActionsCellItem
        key={"update"}
        label={"Update"}
        title={"Update"}
        icon={<EditIcon />}
        onClick={handleEdit(props.row)}
      />,
      <GridActionsCellItem
        key={"delete"}
        color={"error"}
        label={"Delete"}
        title={"Delete"}
        icon={<DeleteIcon />}
        onClick={handleDelete(props.row)}
      />,
    ];
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "mainAddress",
      headerName: "Main Address",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "mainPhone",
      headerName: "Main Phone",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "sellingOrganization",
      headerName: "Selling Organization",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "mainUrl",
      headerName: "URL",
      minWidth: 130,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: getActions,
    },
  ];

  const handleClosePasswordDialog = (): void => {
    setShowPasswordDialog(false);
    setAdminScheme({ adminPassword: "" });
  };

  const handleCloseDialog = (): void => {
    getData();

    setShowDialog(false);
    setTempDealershipContext(undefined);

    if (!dealerContext) {
      ModuleHelper.grow.warning(t("common.defaultErrorMessage"));
      return;
    }

    if (!tempDealershipContext) {
      return;
    }

    if (dealerContext.id !== tempDealershipContext.id) {
      ModuleHelper.confirmDialog.confirm(
        "Confirm?",
        `Switch back to "${get(dealerContext, "name")}"`,
        [
          {
            title: `Stay in (${tempDealershipContext.name})`,
            color: "info",
            onClick: () => {
              window.location.reload();
            },
          },
          {
            title: "ОК",
            color: "error",
            onClick: () => {
              ModuleHelper.loader.show();
              userService
                .switchDealershipContext(dealerContext?.id)
                .catch(() => {
                  window.location.reload();
                })
                .then(() => {
                  ModuleHelper.loader.hide();
                });
            },
          },
        ]
      );
    }
  };

  const handleEdit =
    (dealership: IDealershipContextDTO) =>
    (event: any): void => {
      event.stopPropagation();

      ModuleHelper.loader.show();
      userService
        .switchDealershipContext(dealership.id)
        .then(() => {
          setShowDialog(true);
          setTempDealershipContext(dealership);
        })
        .catch((error) => ModuleHelper.grow.showError(error))
        .finally(() => ModuleHelper.loader.hide());
    };

  const onDeleteDealership = (
    id: number,
    password: string,
    hardDelete: boolean,
    removeFromStorage: boolean
  ): void => {
    setDeleteLoading(true);
    dealershipService
      .deleteDealership({
        hardDelete: hardDelete,
        removeFromStorage: removeFromStorage,
        dealerId: id,
        adminPassword: password,
      })
      .then(() => {
        getData();
        handleClosePasswordDialog();
        ModuleHelper.grow.success(t("dealership.deletedSuccessfully"));
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setDeleteLoading(false));
  };

  const handleDelete =
    (dealership: IDealershipDTO) =>
    (event: any): void => {
      event.stopPropagation();

      setAdminScheme({
        dealership: dealership,
        adminPassword: "",
        hardDelete: false,
        removeFromStorage: false,
      });
      setShowPasswordDialog(true);
    };

  const handleAdd = (): void => {
    setShowDialog(true);
  };

  const onSuccess = (): void => {
    if (isEmpty(tempDealershipContext)) {
      handleCloseDialog();
    }
  };

  const onSubmitDelete = (values: IAdminScheme): void => {
    const dealership = get(values, "dealership") as IDealershipDTO;
    const adminPassword = get(values, "adminPassword", "");
    const hardDelete = get(values, "hardDelete", false);
    const removeFromStorage = get(values, "removeFromStorage", false);

    onDeleteDealership(
      dealership.id,
      adminPassword,
      hardDelete,
      removeFromStorage
    );
  };

  const onCheckBoxChange =
    (field: string, formikProps: FormikProps<IAdminScheme>) => (event: any) => {
      event.stopPropagation();

      formikProps.setFieldValue(field, !get(formikProps.values, field));
    };

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper
        header={"Dealerships"}
        rightHeader={
          <Button
            variant={"contained"}
            onClick={handleAdd}
            startIcon={<AddIcon />}
          >
            ADD NEW
          </Button>
        }
      >
        <DataGrid
          editMode={"row"}
          rows={dealerships}
          columns={columns}
          loading={loading}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <Dialog
          open={showDialog}
          onClose={handleCloseDialog}
          sx={{ borderRadius: globalStyle.borderRadius }}
        >
          <DialogTitle>
            <Alert severity="warning" className={globalClasses.alertTitle}>
              {tempDealershipContext ? (
                <Typography>
                  {`Your dealership context has been temporarily switched to "${tempDealershipContext.name}" while you are editing its profile.`}
                </Typography>
              ) : (
                <Typography>{`You are about to create a new dealership!`}</Typography>
              )}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DealershipDetails
              onSuccess={onSuccess}
              isNew={isEmpty(tempDealershipContext)}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={showPasswordDialog}
          onClose={handleClosePasswordDialog}
          sx={{ borderRadius: globalStyle.borderRadius }}
        >
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitDelete}
            initialValues={adminScheme}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<IAdminScheme>) => (
              <Form noValidate>
                <Grid container sx={{ padding: 2, maxWidth: 300 }}>
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      {`You are about to delete "${formikProps.values.dealership?.name}"`}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={formikProps.values.hardDelete}
                        />
                      }
                      label="Hard Delete"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      onChange={onCheckBoxChange("hardDelete", formikProps)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={formikProps.values.removeFromStorage}
                        />
                      }
                      label="Remove From Storage"
                      labelPlacement="start"
                      className={classes.switchLabel}
                      onChange={onCheckBoxChange(
                        "removeFromStorage",
                        formikProps
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="adminPassword"
                      label="Password"
                      size="small"
                      type="password"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SubmitButton
                      loading={deleteLoading}
                      type="submit"
                      variant="contained"
                      size={"large"}
                      disableRipple
                      color="primary"
                      startIcon={<DeleteIcon />}
                      className={globalClasses.saveButton}
                    >
                      Delete
                    </SubmitButton>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <Button
                      variant="contained"
                      size={"large"}
                      color={"secondary"}
                      sx={{ width: "100%" }}
                      onClick={handleClosePasswordDialog}
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog>
      </PaperWrapper>
    );
  };

  return render();
};

export default Dealerships;
