import { IEmployeeInfo } from "./index";
import { StringBool } from "../../types/types";
import { UserRole } from "../../common/Enums";
import { IEmployeeInfoDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class EmployeeInfo implements IEmployeeInfo {
  private _canEditComments: StringBool;
  private _canEditPrices: StringBool;
  private _canSendMessagesToRoles: UserRole[];
  private _canSendRSToRoles: UserRole[];
  private _id: number;
  private _venmoId: string;

  constructor(args?: IEmployeeInfoDTO) {
    this._canEditComments = Utils.default(args?.canEditComments, "false_");
    this._canEditPrices = Utils.default(args?.canEditPrices, "false_");
    this._canSendMessagesToRoles = Utils.default(
      args?.canSendMessagesToRoles,
      []
    );
    this._canSendRSToRoles = Utils.default(args?.canSendRSToRoles, []);
    this._id = Utils.default(args?.id, 0);
    this._venmoId = Utils.default(args?.venmoId, "");
  }

  get canEditComments(): StringBool {
    return this._canEditComments;
  }

  set canEditComments(value: StringBool) {
    this._canEditComments = value;
  }

  get canEditPrices(): StringBool {
    return this._canEditPrices;
  }

  set canEditPrices(value: StringBool) {
    this._canEditPrices = value;
  }

  get canSendMessagesToRoles(): UserRole[] {
    return this._canSendMessagesToRoles;
  }

  set canSendMessagesToRoles(value: UserRole[]) {
    this._canSendMessagesToRoles = value;
  }

  get canSendRSToRoles(): UserRole[] {
    return this._canSendRSToRoles;
  }

  set canSendRSToRoles(value: UserRole[]) {
    this._canSendRSToRoles = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get venmoId(): string {
    return this._venmoId;
  }

  set venmoId(value: string) {
    this._venmoId = value;
  }

  toUpdateDTO(): IEmployeeInfoDTO {
    return {
      id: this._id,
      venmoId: this._venmoId,
      canEditPrices: this._canEditPrices,
      canEditComments: this._canEditComments,
      canSendRSToRoles: this._canSendRSToRoles,
      canSendMessagesToRoles: this._canSendMessagesToRoles,
    };
  }

  toCreateDTO(): IEmployeeInfoDTO {
    return {
      venmoId: this._venmoId,
      canEditPrices: this._canEditPrices,
      canEditComments: this._canEditComments,
      canSendRSToRoles: this._canSendRSToRoles,
      canSendMessagesToRoles: this._canSendMessagesToRoles,
    };
  }
}
