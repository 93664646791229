import { forwardRef } from "react";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { getIn } from "formik";

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: "#fff",
  },
}));

const CustomPhoneInput = (props: any, ref: any) => {
  const classes = useStyles();

  const { error, helperText, id } = props;

  const isTouched = getIn(props.form.touched, id);
  const errorMessage = getIn(props.form.errors, id);

  return (
    <TextField
      margin="normal"
      variant="outlined"
      InputProps={{
        className: classes.input,
      }}
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      inputRef={ref}
      {...props}
    />
  );
};
export default forwardRef(CustomPhoneInput);
