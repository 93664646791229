import React, { CSSProperties } from "react";
import Typography from "@mui/material/Typography";
import { IVehicleDTO } from "../../../../index";
import { IRWModal } from "../recommended_work/RecommendedWorkModal";
import { useStyles } from "./Vehicle.styles";
import { get } from "lodash";
import { Box } from "@mui/material";

interface IProps {
  readonly rwRef?: IRWModal;
  readonly style?: CSSProperties;
  readonly vehicle: IVehicleDTO;
}

export const VehicleDetails = (props: IProps): React.ReactElement => {
  const classes = useStyles();

  const render = (): React.ReactElement => {
    const vehicle: IVehicleDTO = get(props, "vehicle");

    const model = get(vehicle, "model");
    const color = get(vehicle, "color");
    const manufacturer = get(vehicle, "manufacturer");
    const manufacturingYear = get(vehicle, "manufacturingYear");

    return (
      <Box
        alignItems="center"
        sx={{ padding: 1, display: "flex" }}
        style={props.style}
        className={classes.gridStyles}
      >
        <img alt={"..."} src={"/images/car.png"} className={classes.carImage} />

        <Box sx={{ paddingLeft: 1 }}>
          <Typography variant="body2" className={classes.statusLabel}>
            {`${manufacturingYear} ${manufacturer} ${model} - ${color}`}
          </Typography>

          <Typography variant="subtitle1" className={classes.statusValue}>
            Vehicle Snapshot
          </Typography>
        </Box>
      </Box>
    );
  };

  return render();
};
