import MenuBuilder from "./MenuBuilder";
import route from "../../common/route";

export default class ProfileMenuBuilder extends MenuBuilder {
  build(): any {
    const settings = [];

    if (this.hasRouteAccess(route.profile)) {
      settings.push(route.profile);
    }

    if (this.hasRouteAccess(route.logOut)) {
      settings.push(route.logOut);
    }

    return settings;
  }
}
