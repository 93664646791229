import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { globalColors } from "../util/ThemePalette";

const NotFound = (): React.ReactElement => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "50vh",
        backgroundColor: globalColors.grey,
      }}
    >
      <Typography variant="h1" style={{ color: "white" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "white" }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button variant="contained">Back Home</Button>
    </Box>
  );
};

export default NotFound;
