export enum DeviceType {
  DESKTOP = "DESKTOP",
}

export enum NotificationPermission {
  denied = "denied",
  default = "default",
  granted = "granted",
}

export enum UserRole {
  UNKNOWN = "UNKNOWN",
  SALESPERSON = "SALESPERSON",
  CUSTOMER = "CUSTOMER",
  TECHNICIAN = "TECHNICIAN",
  ADVISOR = "ADVISOR",
  USER_MANAGER = "USER_MANAGER",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum NotificationChannel {
  SMS = "SMS",
  EMAIL = "EMAIL",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
}

export enum DealershipSettings {
  TIP_WHO_RECEIVE = "TIP_WHO_RECEIVE",
  TIP_TITLE_MESSAGE = "TIP_TITLE_MESSAGE",
  LEAVE_A_TIP_CANCEL_BUTTON = "LEAVE_A_TIP_CANCEL_BUTTON",
  LEAVE_A_TIP_SUBMIT_BUTTON = "LEAVE_A_TIP_SUBMIT_BUTTON",
  RATE_TITLE_MESSAGE = "RATE_TITLE_MESSAGE",
  TIP_MIN_AMOUNT_IN_CENTS = "TIP_MIN_AMOUNT_IN_CENTS",
  TIP_MAX_AMOUNT_IN_CENTS = "TIP_MAX_AMOUNT_IN_CENTS",
  MESSAGE_SEND_TO_CUSTOMER = "MESSAGE_SEND_TO_CUSTOMER",
  ASK_CUSTOMER_TO_LEAVE_A_TIP = "ASK_CUSTOMER_TO_LEAVE_A_TIP",
  TIP_INCREMENTATION_IN_CENTS = "TIP_INCREMENTATION_IN_CENTS",
  CUSTOMER_DEFAULT_NAME = "MESSAGE_CUSTOMER_DEFAULT_NAME",
  REVIEW_REMIND_CUSTOMER_AFTER_MIN = "REVIEW_REMIND_CUSTOMER_AFTER_MIN",
  DEACTIVATE_EMPLOYEE = "DEACTIVATE_EMPLOYEE_THAT_IS_NOT_IN_CDK",
  THANK_YOU_SCREEN_MESSAGE = "THANK_YOU_SCREEN_MESSAGE",
  REVIEW_LOW_SCORE_VALUE = "REVIEW_LOW_SCORE_VALUE",
  VEHICLE_SOLD_NOTIFICATION_AM_TIME = "VEHICLE_SOLD_NOTIFICATION_AM_TIME",
  SEND_EMAILS_WITH_LOW_SCORE_REVIEW = "SEND_EMAILS_WITH_LOW_SCORE_REVIEW",
  REVIEW_LOW_SCORE_SEND_TO_EMAIL_LIST = "REVIEW_LOW_SCORE_SEND_TO_EMAIL_LIST",
  CHECK_FETCHED_FROM_CDK_NOTIFY_CUSTOMER_FIELD = "CHECK_FETCHED_FROM_CDK_NOTIFY_CUSTOMER_FIELD",
  NOTIFICATION_DURATION = "NOTIFICATION_DURATION_BETWEEN_MESSAGE_NOTIFICATION_MINUTES",
  ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN = "ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN",
}

export enum QueryParams {
  token = "t",
  vehicleId = "v",
  notificationId = "n",
}

export enum Placeholders {
  RO_NO = "<RoNo>",
  CAR_MODEL = "<CarModel>",
  TIP_AMOUNT = "<TipAmount>",
  DEALER_NAME = "<DealerName>",
  REVIEW_SCORE = "<ReviewScore>",
  TECH_LAST_NAME = "<TechLastName>",
  TECH_FIRST_NAME = "<TechFirstName>",
  CUSTOMER_FIRST_NAME = "<CustomerFirstName>",
  SALESPERSON_FIRST_NAME = "<SalespersonFirstName>",
}

export enum ScreenWidth {
  MD = 900,
  SM = 550,
}

export enum Priority {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export enum DefaultTypes {
  STRING = "n/a",
  NUMBER = -1,
  DATE = "01/01/1970",
  DATE_TIME = "00:00:00 01/01/1970",
}

export enum SpinnerActions {
  SHOW = "SHOW",
  HIDE = "HIDE",
}
