import Validation from "./Validation";
import { defaultTo, isEmpty } from "lodash";
import { DefaultTypes } from "../common/Enums";

export default class Utils {
  public static readonly validation = new Validation();

  public static default<T>(value: T | null | undefined, defaultValue: T): T {
    if (typeof value === "string") {
      if (value === DefaultTypes.STRING) {
        return defaultValue;
      }

      if (isEmpty(value)) {
        return defaultValue;
      }
    }

    return defaultTo(value, defaultValue);
  }

  public static getIfTrue<T>(condition: boolean, value: T): T | undefined {
    if (!condition) {
      return undefined;
    }

    return value;
  }
}
