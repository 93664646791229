import React from "react";
import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import RepairOrderService from "../../services/RepairOrder.service";
import ModuleHelper from "../../util/ModuleHelper";
import { useHistory } from "react-router";
import route from "../../common/route";
import { QueryParams } from "../../common/Enums";
import RepairOrderReview from "../../components/repair_order/review/RepairOrderReview";
import RepairOrderDetails from "../../components/repair_order/details/RepairOrderDetails";
import { IRepairOrder } from "../../models/repair_order";
import RepairOrder from "../../models/repair_order/RepairOrder";
import PaperWrapper from "../../components/paper/PaperWrapper";
import { Grid } from "@mui/material";
import ContentLoader from "react-content-loader";

const queryString = require("query-string");
const repairOrderService = new RepairOrderService();

const RepairOrderView = (): React.ReactElement | null => {
  const history = useHistory();

  const [repairOrder, setRepairOrder] = useState<IRepairOrder>(
    new RepairOrder()
  );

  useEffect(() => {
    const params = queryString.parse(history.location.search);

    const notificationId = params[QueryParams.notificationId];

    if (isEmpty(notificationId)) {
      history.push(route.profile.pathname);
      return;
    }

    repairOrderService
      .getNotification(notificationId)
      .then((response) => {
        setRepairOrder(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error));
  }, []);

  const render = (): React.ReactElement | null => {
    return (
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xl={6} lg={7} md={8} xs={12}>
          <PaperWrapper>
            {repairOrder.id === 0 ? (
              <ContentLoader />
            ) : (
              <ContentRender repairOrder={repairOrder} />
            )}
          </PaperWrapper>
        </Grid>
      </Grid>
    );
  };

  return render();
};

export default RepairOrderView;

const ContentRender = (props: {
  repairOrder: IRepairOrder;
}): React.ReactElement => {
  const render = (): React.ReactElement => {
    const { repairOrder } = props;

    return ModuleHelper.stringBool(repairOrder.closed) ? (
      <RepairOrderReview repairOrder={repairOrder} />
    ) : (
      <RepairOrderDetails
        repairOrder={repairOrder}
        repairOrderService={repairOrderService}
      />
    );
  };

  return render();
};
