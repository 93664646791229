import { IBaseUser } from "./index";
import { IBaseUserDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class BaseUser implements IBaseUser {
  private _id: number;
  private _avatarURL: string;
  private _firstName: string;
  private _lastName: string;

  constructor(args?: IBaseUserDTO) {
    this._id = Utils.default(args?.id, 0);
    this._avatarURL = Utils.default(args?.avatarURL, "");
    this._firstName = Utils.default(args?.firstName, "");
    this._lastName = Utils.default(args?.lastName, "");
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get avatarURL(): string {
    return this._avatarURL;
  }

  set avatarURL(value: string) {
    this._avatarURL = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }
}
