import Axios, { AxiosResponse } from "axios";
import {
  IAdvisorStatisticsDTO,
  IBaseUserDTO,
  IDealershipContextDTO,
  IEmployeeDTO,
  IEmployeeInfo,
  IEmployeeRequestDTO,
  ISellerStatisticsDTO,
  ITechnicianStatisticsDTO,
  IUserDetailsDTO,
  IContextUserDTO,
} from "../../index";
import { UserRole } from "../common/Enums";
import { find, forEach, map, defaultTo } from "lodash";
import ModuleHelper from "../util/ModuleHelper";
import BaseUser from "../models/user/BaseUser";
import SellerStatistic from "../models/statistics/SellerStatistic";
import { IEmployee, IContextUser } from "../models/user";
import ContextUser from "../models/user/ContextUser";
import {
  IAdvisorStatistic,
  ISellerStatistic,
  ITechnicianStatistic,
} from "../models/statistics";
import TechnicianStatistic from "../models/statistics/TechnicianStatistic";
import AdvisorStatistic from "../models/statistics/AdvisorStatistic";
import Employee from "../models/user/Employee";

interface IAvatarResponse {
  id: number;
  avatarURL: string;
  firstName: string;
  lastName: string;
}

interface IUserApiClient {
  updateAvatar(data: FormData): Promise<AxiosResponse<IAvatarResponse>>;
  updateUserAvatar(
    id: number,
    data: FormData
  ): Promise<AxiosResponse<IAvatarResponse>>;
  switchDealershipContext(id: number): Promise<AxiosResponse>;
  updateUserDetails(
    data: IUserDetailsDTO
  ): Promise<AxiosResponse<IContextUserDTO>>;

  getAllUsers(): Promise<AxiosResponse<IEmployeeDTO[]>>;
  getSellerStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<{ employee: ISellerStatisticsDTO[] }>>;
  getAdvisorStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<{ employee: IAdvisorStatisticsDTO[] }>>;
  getTechnicianStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<{ employee: ITechnicianStatisticsDTO[] }>>;
  updateUser(data: IEmployeeRequestDTO): Promise<AxiosResponse<IEmployeeDTO>>;
  deleteUser(userId: number): Promise<AxiosResponse>;
  createUser(employee: IEmployeeRequestDTO): Promise<AxiosResponse>;
  updateUserStatus(userId: number, status: boolean): Promise<AxiosResponse>;
  getUserDetails(
    userIds: number[]
  ): Promise<AxiosResponse<{ employee: IBaseUserDTO; averageScore: number }[]>>;
  resendInvite(userId: number): Promise<AxiosResponse>;
}

class UserApiClient implements IUserApiClient {
  private readonly prefix = "user";

  updateAvatar(data: FormData): Promise<AxiosResponse<IAvatarResponse>> {
    return window.apiClient.post(`${this.prefix}/profile-picture`, data);
  }

  updateUserAvatar(
    id: number,
    data: FormData
  ): Promise<AxiosResponse<IAvatarResponse>> {
    return window.apiClient.put(`${this.prefix}/${id}/profile-picture`, data);
  }

  switchDealershipContext(id: number): Promise<AxiosResponse> {
    return window.apiClient.get(`${this.prefix}/switch-dealership/${id}`);
  }

  updateUserDetails(
    data: IUserDetailsDTO
  ): Promise<AxiosResponse<IContextUserDTO>> {
    return window.apiClient.put("user/myself", data);
  }

  getAllUsers(): Promise<AxiosResponse<IEmployeeDTO[]>> {
    const params = new URLSearchParams();
    params.append("roles", UserRole.CUSTOMER);
    params.append("roles", UserRole.TECHNICIAN);
    params.append("roles", UserRole.ADVISOR);
    params.append("roles", UserRole.ADMIN);
    params.append("roles", UserRole.SUPER_ADMIN);
    params.append("roles", UserRole.SALESPERSON);
    params.append("roles", UserRole.USER_MANAGER);

    return window.apiClient.get(`${this.prefix}/servues/all?`, {
      params: params,
    });
  }

  getSellerStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<{ employee: ISellerStatisticsDTO[] }>> {
    return window.apiClient.get(`${this.prefix}/statistics/salesperson`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  getAdvisorStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<{ employee: IAdvisorStatisticsDTO[] }>> {
    return window.apiClient.get(`${this.prefix}/statistics/advisors`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  getTechnicianStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<AxiosResponse<{ employee: ITechnicianStatisticsDTO[] }>> {
    return window.apiClient.get(`${this.prefix}/statistics/technicians`, {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  }

  updateUser(data: IEmployeeRequestDTO): Promise<AxiosResponse<IEmployeeDTO>> {
    return window.apiClient.put(`${this.prefix}/servues`, data);
  }

  deleteUser(userId: number): Promise<AxiosResponse> {
    return window.apiClient.delete(`${this.prefix}/servues?userId=${userId}`);
  }

  createUser(employee: IEmployeeRequestDTO): Promise<AxiosResponse> {
    return window.apiClient.post(`${this.prefix}/servues`, employee);
  }

  updateUserStatus(userId: number, status: boolean): Promise<AxiosResponse> {
    return window.apiClient.put(`${this.prefix}/servues/enable-employee`, {
      enable: status,
      userId: userId,
    });
  }

  getUserDetails(
    userIds: number[]
  ): Promise<
    AxiosResponse<{ employee: IBaseUserDTO; averageScore: number }[]>
  > {
    const params = new URLSearchParams();

    params.append("includeReviews", "false");
    forEach(userIds, (userId) => {
      params.append("userIds", `${userId}`);
    });

    return window.apiClient.get(`${this.prefix}/employee/info`, {
      params: params,
    });
  }

  resendInvite(userId: number): Promise<AxiosResponse> {
    return window.apiClient.put(`${this.prefix}/servues/invite`, userId);
  }
}

export default class UserService {
  private userApiClient: IUserApiClient;

  constructor() {
    this.userApiClient = new UserApiClient();
  }

  public async updateAvatar(file: File): Promise<IAvatarResponse> {
    const formData = new FormData();
    formData.set("image", file);

    const response = await this.userApiClient.updateAvatar(formData);

    return response.data;
  }

  public async switchDealershipContext(
    id: number
  ): Promise<AxiosResponse<IDealershipContextDTO>> {
    return this.userApiClient.switchDealershipContext(id);
  }

  public async updateUserDetails(data: IUserDetailsDTO): Promise<IContextUser> {
    const response = await this.userApiClient.updateUserDetails(data);

    return new ContextUser(response.data);
  }

  public async getAllUsers(): Promise<IEmployee[]> {
    const response = await this.userApiClient.getAllUsers();

    return map(response.data, (item) => new Employee(item));
  }

  public async getUsersByRoles(): Promise<{
    [UserRole.ADVISOR]: IEmployeeDTO[];
    [UserRole.CUSTOMER]: IEmployeeDTO[];
    [UserRole.TECHNICIAN]: IEmployeeDTO[];
    [UserRole.SALESPERSON]: IEmployeeDTO[];
  }> {
    const response = await this.userApiClient.getAllUsers();

    const result: any = {
      [UserRole.ADVISOR]: [],
      [UserRole.CUSTOMER]: [],
      [UserRole.TECHNICIAN]: [],
      [UserRole.SALESPERSON]: [],
    };

    forEach(response.data, (user: IEmployeeDTO) => {
      const isAdvisor = ModuleHelper.isAdvisor(user.roles);

      if (isAdvisor) {
        result[UserRole.ADVISOR].push(user);
        return;
      }

      const isCustomer = ModuleHelper.isCustomerByRoles(user.roles);
      if (isCustomer) {
        result[UserRole.CUSTOMER].push(user);
        return;
      }

      const isTechnician = ModuleHelper.isTechnician(user.roles);
      if (isTechnician) {
        result[UserRole.TECHNICIAN].push(user);
        return;
      }

      const isSalesPerson = ModuleHelper.isSalesPerson(user.roles);
      if (isSalesPerson) {
        result[UserRole.SALESPERSON].push(user);
      }
    });

    return result;
  }

  public async getSellerStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<ISellerStatistic[]> {
    const response = await this.userApiClient.getSellerStatistics(
      startDate,
      endDate
    );

    return map(response.data.employee, (item) => new SellerStatistic(item));
  }

  public async getAdvisorStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<IAdvisorStatistic[]> {
    const response = await this.userApiClient.getAdvisorStatistics(
      startDate,
      endDate
    );

    return map(response.data.employee, (item) => new AdvisorStatistic(item));
  }

  public async getTechnicianStatistics(
    startDate?: string,
    endDate?: string
  ): Promise<ITechnicianStatistic[]> {
    const response = await this.userApiClient.getTechnicianStatistics(
      startDate,
      endDate
    );

    return map(response.data.employee, (item) => new TechnicianStatistic(item));
  }

  public async updateUserAvatar(
    id: number,
    file: File
  ): Promise<AxiosResponse<IAvatarResponse>> {
    const formData = new FormData();
    formData.set("image", file);

    return this.userApiClient.updateUserAvatar(id, formData);
  }

  public async updateUser(
    employee: IEmployee,
    avatarImage?: File
  ): Promise<IEmployee> {
    const promises: Promise<any>[] = [
      this.userApiClient.updateUser(employee.buildUpdateDTO()),
    ];

    if (avatarImage) {
      promises.push(this.updateUserAvatar(employee.id, avatarImage));
    }

    const responses = await Axios.all(promises);

    return new Employee(responses[0].data);
  }

  public async deleteUser(userId: number): Promise<AxiosResponse> {
    return this.userApiClient.deleteUser(userId);
  }

  public async createUser(
    employee: IEmployee,
    avatarImage?: File
  ): Promise<IEmployee> {
    const response = await this.userApiClient.createUser(
      employee.buildCreateDTO()
    );

    const user = new Employee(response.data);
    if (avatarImage) {
      const avatarResponse = await this.updateUserAvatar(user.id, avatarImage);
      user.avatarURL = avatarResponse.data.avatarURL;
    }

    return user;
  }

  public async updateUserStatus(
    userId: number,
    status: boolean
  ): Promise<AxiosResponse> {
    return this.userApiClient.updateUserStatus(userId, status);
  }

  public async getUserDetails(userId: number): Promise<IEmployeeInfo> {
    const response = await this.userApiClient.getUserDetails([userId]);

    const user = find(response.data, (item) => item.employee.id === userId);

    return {
      user: new BaseUser(user?.employee),
      averageScore: defaultTo(user?.averageScore, 0),
    };
  }

  public async resendInvite(userId: number): Promise<AxiosResponse> {
    return this.userApiClient.resendInvite(userId);
  }
}
