import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
  return {
    recommendedWorkItem: {
      display: "flex",
      justifyContent: "space-around",
    },
    buttonAlignment: {
      display: "flex",
      justifyContent: "center",
    },
    buttonBorder: {
      borderRight: "3px solid #F3F2F4",
      fontWeight: "bold",
    },
    parameterDiv: {
      fontSize: 11,
      display: "flex",
      paddingTop: 8,
      color: "#666684",
      justifyContent: "space-evenly",
      textTransform: "uppercase",
    },
    recommendedWorkDiv: {
      border: "1px solid #EB5757",
      marginBottom: 10,
      borderRadius: 10,
      minHeight: 125,
      padding: 10,
    },
    dialog: {
      "&&": {
        "& .MuiPaper-root": {
          margin: 0,
          width: "100%",
          maxWidth: 600,
          height: ({ height }: any) => height,
          maxHeight: ({ maxHeight }: any) => maxHeight,
          borderRadius: ({ borderRadius }: any) => borderRadius,
        },
      },
    },
  };
});
