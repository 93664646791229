import React, { CSSProperties, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "./spinner.css";
import { SpinnerActions } from "../../common/Enums";

interface IProps {
  size?: number;
  pending: boolean;
  style?: CSSProperties;
  disableAnimation?: boolean;
}

const Loader = (props: IProps): any => {
  const [action, setAction] = useState<SpinnerActions>(SpinnerActions.HIDE);

  useEffect(() => {
    if (props.pending) {
      setAction(SpinnerActions.SHOW);
    } else {
      setAction(SpinnerActions.HIDE);
    }
  }, [props.pending]);

  const getStyle = (action: SpinnerActions): any => {
    if (props.disableAnimation) {
      return {
        transition: `${0}ms`,
        top: "calc(50% - 60px)",
      };
    }

    if (action === SpinnerActions.HIDE) {
      return {
        transition: `${500}ms`,
        top: -70,
      };
    } else {
      return {
        transition: `${100}ms`,
        top: "calc(50% - 60px)",
      };
    }
  };

  const render = (): any => {
    if (!props.pending) {
      return null;
    }

    return (
      <div id={"spinner"} style={{ ...getStyle(action), ...props.style }}>
        <CircularProgress size={props.size} />
      </div>
    );
  };

  return render();
};

export default Loader;
