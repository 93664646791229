import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { globalColors, globalStyle } from "../../util/ThemePalette";
import VisibleContent from "../visable_content/VisibleContent";
import { isUndefined, isEmpty } from "lodash";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface IProps {
  readonly sx?: SxProps<Theme>;
  readonly header?: string;
  readonly children?: any;
  readonly rightHeader?: React.ReactElement;
}

const PaperWrapper = (props: IProps): React.ReactElement => {
  const render = (): React.ReactElement => {
    const showHeader = Boolean(
      !isEmpty(props.header) || !isUndefined(props.rightHeader)
    );

    return (
      <Paper sx={{ ...{ width: "100%" }, ...props.sx }}>
        <VisibleContent visible={showHeader}>
          <Box
            sx={{
              padding: 1,
              display: "flex",
              backgroundColor: "#0288d1",
              borderTopLeftRadius: globalStyle.borderRadius,
              borderTopRightRadius: globalStyle.borderRadius,
            }}
          >
            <VisibleContent visible={!isEmpty(props.header)}>
              <Typography
                sx={{ marginBottom: 1 }}
                fontWeight={"bold"}
                color={globalColors.white}
              >
                {props.header}
              </Typography>
            </VisibleContent>

            <VisibleContent visible={!isUndefined(props.rightHeader)}>
              <Box style={{ marginLeft: "auto" }}>{props.rightHeader}</Box>
            </VisibleContent>
          </Box>
        </VisibleContent>

        <Box sx={{ padding: 1 }}>{props.children}</Box>
      </Paper>
    );
  };

  return render();
};

export default PaperWrapper;
