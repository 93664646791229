import React, { useState } from "react";
import { Menu, MenuItem, TextField } from "@mui/material";
import { get, isEmpty } from "lodash";

interface IProps {
  value: string;
  label?: string;
  trigger?: string[];
  options?: string[];

  onChange(value: string): void;
}

const AutoSuggest = (props: IProps): React.ReactElement => {
  const [value, setValue] = useState<string>(props.value);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectionStart, setSelectionStart] = useState();

  const updateSelectionStart = (e: any) => {
    setSelectionStart(e.target.selectionStart);
  };

  const onClose = (): void => {
    setOpen(false);
    setAnchorEl(null);
  };

  const onKeyPress = (event: any): void => {
    if (isEmpty(props.trigger)) {
      return;
    }

    const key = get(event, "key");

    if (isEmpty(key)) {
      return;
    }

    const trigger: string[] = get(props, "trigger", []);

    if (trigger.includes(key)) {
      event.stopPropagation();

      setOpen(true);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleOptionChange = (option: string) => (event: any) => {
    event.stopPropagation();
    onClose();

    const val = [
      value.slice(0, selectionStart),
      option,
      value.slice(selectionStart),
    ].join("");

    setValue(val);
    props.onChange(val);
  };

  const onChange = (event: any): void => {
    const value = get(event, "target.value", "");

    setValue(value);
    props.onChange(value);
  };

  const render = (): React.ReactElement => {
    const options = get(props, "options", []);

    return (
      <React.Fragment>
        <TextField
          value={value}
          label={props.label}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onSelect={updateSelectionStart}
        />
        <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option}
              onClick={handleOptionChange(option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  };

  return render();
};

export default AutoSuggest;
