const actions = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_DEALERSHIP_CONTEXT: "UPDATE_DEALERSHIP_CONTEXT",
  // UPDATE_DEALERSHIP_CONFIG: "UPDATE_DEALERSHIP_CONFIG",

  SET_NOTIFICATION_TOKEN: "SET_NOTIFICATION_TOKEN",
};

export default actions;
