import React from "react";

interface IProps {
  readonly visible: boolean;
  readonly children: any;
}

const VisibleContent = (props: IProps): React.ReactElement | null => {
  const render = (): React.ReactElement | null => {
    if (!props.visible) {
      return null;
    }

    return props.children;
  };

  return render();
};

export default VisibleContent;
