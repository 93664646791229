import { IRepairOrder } from "./index";
import { IRepairOrderResponse } from "../../../index";
import { StringBool } from "../../types/types";
import { forEach, sortBy } from "lodash";
import ModuleHelper from "../../util/ModuleHelper";
import { IBaseUser } from "../user";
import { ITip } from "../tip";
import Tip from "../tip/Tip";
import { IReview } from "../review";
import Review from "../review/Review";
import Vehicle from "../vehicle/Vehicle";
import Technician from "../user/Technician";
import BaseUser from "../user/BaseUser";
import Advisor from "../user/Advisor";
import { ILineItem } from "../line_item";
import { IStatusCode } from "../status_code";
import StatusCode from "../status_code/StatusCode";
import Utils from "../../util/Utils";
import LineItem from "../line_item/LineItem";
import { ILIFile } from "../file";

export default class RepairOrder implements IRepairOrder {
  private _id: number;
  private _advisor: IBaseUser;
  private _closed: StringBool;
  private _comments: string;
  private _customer: IBaseUser;
  private _estimateCompletionAt: string;
  private _hostItemId: string;
  private _lineItems: ILineItem[];
  private _openedAt: string;
  private _review: IReview;
  private _roNum: string;
  private _statusCode: IStatusCode;
  private _tip: ITip;
  private _updated: string;
  private _vehicle: IVehicle;

  constructor(args?: IRepairOrderResponse) {
    this._tip = new Tip(args?.tip);
    this._review = new Review(args?.review);
    this._vehicle = new Vehicle(args?.vehicle);
    this._advisor = new Advisor(args?.advisor);
    this._customer = new BaseUser(args?.customer);
    this._statusCode = new StatusCode(args?.statusCode);
    this._lineItems = Utils.default(args?.lineItems, []).map(
      (item) => new LineItem(item)
    );

    this._id = Utils.default(args?.id, 0);
    this._closed = Utils.default(args?.closed, "false_");
    this._comments = Utils.default(args?.comments, "");
    this._estimateCompletionAt = Utils.default(args?.estimateCompletionAt, "");
    this._hostItemId = Utils.default(args?.hostItemId, "");
    this._openedAt = Utils.default(args?.openedAt, "");
    this._roNum = Utils.default(args?.roNum, "");
    this._updated = Utils.default(args?.updated, "");
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get advisor(): IBaseUser {
    return this._advisor;
  }

  set advisor(value: IBaseUser) {
    this._advisor = value;
  }

  get closed(): StringBool {
    return this._closed;
  }

  set closed(value: StringBool) {
    this._closed = value;
  }

  get comments(): string {
    return this._comments;
  }

  set comments(value: string) {
    this._comments = value;
  }

  get customer(): IBaseUser {
    return this._customer;
  }

  set customer(value: IBaseUser) {
    this._customer = value;
  }

  get estimateCompletionAt(): string {
    return this._estimateCompletionAt;
  }

  set estimateCompletionAt(value: string) {
    this._estimateCompletionAt = value;
  }

  get hostItemId(): string {
    return this._hostItemId;
  }

  set hostItemId(value: string) {
    this._hostItemId = value;
  }

  get lineItems(): ILineItem[] {
    return this._lineItems;
  }

  set lineItems(value: ILineItem[]) {
    this._lineItems = value;
  }

  get openedAt(): string {
    return this._openedAt;
  }

  set openedAt(value: string) {
    this._openedAt = value;
  }

  get review(): IReview {
    return this._review;
  }

  set review(value: IReview) {
    this._review = value;
  }

  get roNum(): string {
    return this._roNum;
  }

  set roNum(value: string) {
    this._roNum = value;
  }

  get statusCode(): IStatusCode {
    return this._statusCode;
  }

  set statusCode(value: IStatusCode) {
    this._statusCode = value;
  }

  get tip(): ITip {
    return this._tip;
  }

  set tip(value: ITip) {
    this._tip = value;
  }

  get updated(): string {
    return this._updated;
  }

  set updated(value: string) {
    this._updated = value;
  }

  get vehicle(): IVehicle {
    return this._vehicle;
  }

  set vehicle(value: IVehicle) {
    this._vehicle = value;
  }

  getFiles(): ILIFile[] {
    const files: ILIFile[] = [];
    this.lineItems.forEach((item) => {
      files.push(...item.files);
    });

    return files;
  }

  getActiveStep(): number {
    if (ModuleHelper.stringBool(this._closed)) {
      return 2;
    }

    const inProgress = !["open", "opened"].includes(
      `${this._statusCode.initDesc}`.toLowerCase()
    );

    if (inProgress) {
      return 1;
    }

    return 0;
  }

  getTechnician(): IBaseUser {
    const sortedLineItems = sortBy(this._lineItems, (a: ILineItem) => a.code);

    const mainLineItem = sortedLineItems.find(
      (item) => item.technician.id !== 0
    );

    return Utils.default(mainLineItem?.technician, new Technician());
  }

  getEmployees(): IBaseUser[] {
    const employees: IBaseUser[] = [];

    if (this.advisor.id !== 0) {
      employees.push(this.advisor);
    }

    const sortedLineItems = sortBy(
      this._lineItems,
      (a: ILineItem) => a.code
    ).filter((item) => item.technician.id !== 0);

    const techMap: Map<number, IBaseUser> = new Map();

    forEach(sortedLineItems, (item) => {
      techMap.set(item.technician.id, item.technician);
    });

    const technicians = Array.from(techMap.values());

    employees.push(...technicians);

    return employees;
  }

  getPendingLineItems(): ILineItem[] {
    return this.lineItems.filter(
      (item) =>
        ModuleHelper.stringBool(item.isRecommendedService) &&
        item.isApprovedByCustomer === "n_2fa"
    );
  }

  getNotApprovedLineItems(): ILineItem[] {
    return this.lineItems.filter(
      (item) =>
        ModuleHelper.stringBool(item.isRecommendedService) &&
        item.isApprovedByAdvisor === "n_2fa"
    );
  }

  getRequestedLineItems(): ILineItem[] {
    return this.lineItems.filter(
      (item) => item.isRecommendedService !== "true_"
    );
  }

  getRecommendedLineItems(): ILineItem[] {
    return this.lineItems.filter(
      (item) => item.isRecommendedService === "true_"
    );
  }
}
