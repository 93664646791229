import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { globalColors } from "../util/ThemePalette";
import route, { IPage } from "../common/route";
import Logo from "./logo/Logo";
import MenuIcon from "@mui/icons-material/Menu";
import SendReviewRequest from "./send_review_request/SendReviewRequest";
import DealershipContext from "./dealership/DealershipContext";
import Notifications from "./notifications/Notifications";
import { capitalCase } from "change-case";
import ModuleHelper from "../util/ModuleHelper";
import ResetDemoDealer from "./demo_dealer/ResetDemoDealer";
import { IAuthState, IStore } from "../../index";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { entries, get, isEmpty } from "lodash";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LoginService from "../services/Login.service";
import VisibleContent from "./visable_content/VisibleContent";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { ScreenWidth } from "../common/Enums";
import ProfileMenuBuilder from "../services/menu/ProfileMenuBuilder";
import MenuBuilderController from "../services/menu/MenuBuilderController";

const Navigation = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const size = useWindowDimensions();
  const loginService = new LoginService();

  const auth: IAuthState = useSelector(
    (store: IStore) => store.auth,
    shallowEqual
  );

  const user = auth.user;

  const menuBuilder = new MenuBuilderController(auth.getUserRole());
  const profileMenuBuilder = new ProfileMenuBuilder();

  const pages = menuBuilder.build();
  const settings = profileMenuBuilder.build();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [dealershipEl, setDealershipEl] = React.useState(null);

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenDealershipMenu = (event: any) => {
    setDealershipEl(event.currentTarget);
  };

  const handleCloseDealershipMenu = () => {
    setDealershipEl(null);
  };

  const handlePageSelect = (pathname: string) => (event: any) => {
    event.stopPropagation();
    handleCloseNavMenu();
    handleCloseDealershipMenu();
    history.push(pathname);
  };

  const getPageIcon = (page: IPage): React.ReactElement => {
    return page.icon ?? <FindInPageIcon />;
  };

  const getColorByPage = (parentPage: IPage, subPage?: IPage): string => {
    if (isCurrentPage(parentPage, subPage)) {
      return globalColors.orange;
    }

    return globalColors.grey;
  };

  const isCurrentPage = (parentPage: IPage, subPage?: IPage): boolean => {
    if (isEmpty(parentPage.pages)) {
      return parentPage.pathname === location.pathname;
    }

    if (isEmpty(subPage)) {
      for (const [, value] of entries(parentPage.pages)) {
        if (`${parentPage.pathname}${value.pathname}` === location.pathname) {
          return true;
        }
      }
      return false;
    }

    return `${parentPage.pathname}${subPage?.pathname}` === location.pathname;
  };

  const subPageRender = (parent: IPage, page: IPage): React.ReactElement => {
    const color = getColorByPage(parent, page);

    return (
      <MenuItem
        key={page.name}
        value={page.name}
        sx={{ color: color }}
        onClick={handlePageSelect(`${parent.pathname}${page.pathname}`)}
      >
        <Typography>{getPageIcon(page)}</Typography>
        <Typography textAlign="center" style={{ marginLeft: 5 }}>
          {page.name}
        </Typography>
      </MenuItem>
    );
  };

  const handleSettingsSelect = (string: string): void => {
    switch (string) {
      case route.logOut.name:
        loginService.logout().finally(() => {
          window.location.reload();
        });
        break;
      case route.profile.name:
        history.push(route.profile.pathname);
        break;
      default:
        break;
    }
    handleCloseUserMenu();
  };

  const pageRender = (page: IPage): React.ReactElement => {
    const color = getColorByPage(page);

    if (!isEmpty(page.pages)) {
      const values = Object.values(get(page, "pages", []));

      return (
        <Box sx={{ flexGrow: 0 }} key={page.name}>
          <Button
            key={page.name}
            sx={{ color: color }}
            startIcon={getPageIcon(page)}
            onClick={handleOpenDealershipMenu}
          >
            {page.name}
          </Button>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={dealershipEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(dealershipEl)}
            onClose={handleCloseDealershipMenu}
          >
            {values.map((item) => subPageRender(page, item))}
          </Menu>
        </Box>
      );
    }

    return (
      <Box sx={{ flexGrow: 0 }} key={page.name}>
        <Button
          key={page.name}
          sx={{ color: color }}
          startIcon={getPageIcon(page)}
          onClick={handlePageSelect(page.pathname)}
        >
          {page.name}
        </Button>
      </Box>
    );
  };

  const settingsRender = (page: IPage): React.ReactElement => {
    return (
      <MenuItem
        key={page.name}
        value={page.name}
        onClick={() => handleSettingsSelect(page.name)}
      >
        <Typography>{getPageIcon(page)}</Typography>
        <Typography textAlign="center" style={{ marginLeft: 5 }}>
          {page.name}
        </Typography>
      </MenuItem>
    );
  };

  const render = (): React.ReactElement => {
    const role = auth.getUserRole();
    const isSuperAdmin = ModuleHelper.isSuperAdmin(user.roles);
    const isAdmin = ModuleHelper.isAdmin(user.roles);
    const isUserManager = ModuleHelper.isUserManager(user.roles);

    const showReviewRequest = Boolean(isSuperAdmin || isAdmin || isUserManager);

    return (
      <AppBar
        position="relative"
        style={{
          backgroundColor: globalColors.white,
          top: "-10px",
          height: 64,
        }}
      >
        <Container
          style={{ width: "100%", maxWidth: "100%", height: "100%" }}
          color={globalColors.white}
        >
          <Toolbar disableGutters sx={{ height: "100%" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <Button
                style={{
                  margin: 0,
                  padding: 0,
                  height: 58,
                  borderRadius: 45,
                }}
                disabled={pages.length === 0}
                onClick={() => history.push(route.dealership.pathname)}
              >
                <Logo width={"45px"} height={"45px"} />
              </Button>
            </Typography>

            <VisibleContent visible={pages.length > 0}>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map(pageRender)}
                </Menu>
              </Box>
            </VisibleContent>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flexGrow: 10000,
                display: { xs: "flex", md: "none" },
              }}
            >
              <Button
                style={{
                  margin: 0,
                  padding: 0,
                  height: 50,
                  width: 50,
                  borderRadius: 45,
                }}
                disabled={pages.length === 0}
                onClick={() => history.push(route.dealership.pathname)}
              >
                <Logo width={"40px"} height={"40px"} />
              </Button>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map(pageRender)}
            </Box>

            <VisibleContent visible={showReviewRequest}>
              <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: 1,
                  marginRight: 1,
                  display: { xs: "none", md: "flex" },
                }}
              >
                <SendReviewRequest />
              </Box>
            </VisibleContent>

            <VisibleContent visible={size.width >= ScreenWidth.MD}>
              <Box
                sx={{
                  width: 200,
                  marginRight: 1,
                  display: "flex",
                }}
              >
                <DealershipContext />
              </Box>
            </VisibleContent>

            <Box sx={{ marginRight: 1, display: { xs: "flex", md: "flex" } }}>
              <Notifications />
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                marginRight: 1,
                display: { xs: "none", md: "block" },
              }}
            >
              <Typography lineHeight={1}>
                Welcome {auth.getUserName()}
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {capitalCase(role)}
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={user.firstName}
                    src={`${user.avatarURL}`}
                    sx={{ backgroundColor: globalColors.avatarGray }}
                    style={{ margin: 2, width: 50, height: 50 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <VisibleContent visible={size.width < ScreenWidth.MD}>
                  <Box
                    sx={{
                      padding: 2,
                      display: "flex",
                    }}
                  >
                    <DealershipContext />
                  </Box>
                </VisibleContent>

                <VisibleContent visible={isSuperAdmin}>
                  <Box
                    sx={{
                      paddingLeft: 2,
                      paddingRight: 2,
                      paddingBottom: 1,
                    }}
                  >
                    <ResetDemoDealer />
                  </Box>
                </VisibleContent>

                <VisibleContent visible={showReviewRequest}>
                  <Box
                    sx={{
                      paddingTop: 1,
                      paddingBottom: 1,
                      paddingLeft: 2,
                      paddingRight: 2,
                      display: { xs: "flex", md: "none" },
                    }}
                  >
                    <SendReviewRequest />
                  </Box>
                </VisibleContent>

                {settings.map(settingsRender)}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  };

  return render();
};

export default Navigation;
