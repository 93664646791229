import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiConfiguration } from "./ApiConfiguration";
import { handleServiceError } from "./ApiServiceErrors";

declare global {
  interface Window {
    apiClient: ApiClient;
  }
}

export interface IApiClient {
  post<TRequest, TResponse>(
    path: string,
    object?: TRequest,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>>;

  patch<TRequest, TResponse>(
    path: string,
    object?: TRequest
  ): Promise<AxiosResponse<TResponse>>;

  put<TRequest, TResponse>(
    path: string,
    object?: TRequest
  ): Promise<AxiosResponse<TResponse>>;

  get<TResponse>(path: string): Promise<AxiosResponse<TResponse>>;

  delete<TResponse>(path: string): Promise<AxiosResponse<TResponse>>;
}

export default class ApiClient implements IApiClient {
  private client: AxiosInstance;

  constructor(apiConfiguration: ApiConfiguration) {
    this.client = this.createAxiosClient(apiConfiguration);
  }

  protected createAxiosClient(
    apiConfiguration: ApiConfiguration
  ): AxiosInstance {
    return Axios.create({
      baseURL: apiConfiguration.baseURL,
      responseType: "json" as const,
      headers: {
        "Content-Type": apiConfiguration.contentType ?? "application/json",
        ...(apiConfiguration.authorization && {
          Authorization: apiConfiguration.authorization,
        }),
      },
    });
  }

  async post<TRequest, TResponse>(
    path: string,
    payload?: TRequest,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    try {
      return await this.client.post(path, payload, config);
    } catch (error: any) {
      return handleServiceError(error);
    }
  }

  async patch<TRequest, TResponse>(
    path: string,
    payload?: TRequest,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    try {
      return await this.client.patch<TResponse>(path, payload, config);
    } catch (error: any) {
      return handleServiceError(error);
    }
  }

  async put<TRequest, TResponse>(
    path: string,
    payload?: TRequest,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    try {
      return await this.client.put<TResponse>(path, payload, config);
    } catch (error: any) {
      return handleServiceError(error);
    }
  }

  async get<TResponse>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    try {
      return await this.client.get<TResponse>(path, config);
    } catch (error: any) {
      return handleServiceError(error);
    }
  }

  async delete<TResponse>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TResponse>> {
    try {
      return await this.client.delete<TResponse>(path, config);
    } catch (error: any) {
      return handleServiceError(error);
    }
  }

  public updateAccessToken(accessToken: string): void {
    Object.assign(this.client.defaults, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
