import React, { useEffect, useState } from "react";
import { IconButton, Input, ImageListItem, Grid } from "@mui/material";
import { acceptImgTypes } from "../../../util/constants";
import DealershipService from "../../../services/Dealership.service";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ModuleHelper from "../../../util/ModuleHelper";
import { useTranslation } from "react-i18next";
import { useGlobalStyles } from "../../../util/ThemePalette";
import PaperWrapper from "../../../components/paper/PaperWrapper";

interface IProps {
  imageURL?: string;
  dealershipService: DealershipService;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  width: "100%",
});

const DealershipImage = (props: IProps): React.ReactElement => {
  const classes = useGlobalStyles();
  const { t } = useTranslation();

  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string | undefined>(props.imageURL);

  useEffect(() => {
    setImageURL(props.imageURL);
  }, [props.imageURL]);

  const onUploadFile = ({ target }: any): void => {
    const img = target.files[0];

    if (!img) {
      return;
    }

    setImage(img);
    setImageURL(URL.createObjectURL(img));
  };

  const onSave = (): void => {
    if (!image) {
      return;
    }

    setLoading(true);
    props.dealershipService
      .updateDealershipBackgroundImage(image as File)
      .then(() => {
        setImage(undefined);
        ModuleHelper.grow.success(
          t("dealership.backgroundImageUpdatedSuccessfully")
        );
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper header={"BACKGROUND IMAGE"}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <IconButton
              component="label"
              style={{
                width: "100%",
                minHeight: 100,
                padding: 0,
                marginTop: 10,
              }}
            >
              <Input
                type={"file"}
                inputProps={{
                  accept: acceptImgTypes,
                }}
                style={{ display: imageURL ? "none" : "block" }}
                onChange={onUploadFile}
              />
              <ImageListItem style={{ display: !imageURL ? "none" : "block" }}>
                <img src={`${imageURL}`} alt={""} loading="lazy" />
              </ImageListItem>
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <SubmitButton
              startIcon={<SaveIcon />}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              disabled={!image}
              onClick={onSave}
              loading={loading}
              className={classes.saveButton}
            >
              Save
            </SubmitButton>
          </Grid>
        </Grid>
      </PaperWrapper>
    );
  };

  return render();
};

export default DealershipImage;
