import React, { useImperativeHandle, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import MediaCarousel from "./MediaCarousel";
import { IFile } from "../../models/file";

const MediaCarouselModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);

  const show = (files: IFile[]): void => {
    setOpen(true);
    setFiles(files);
  };

  const hide = (): void => {
    setOpen(false);
    setFiles([]);
  };

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const render = (): React.ReactElement => {
    return (
      <Dialog open={open} keepMounted={false} onClose={hide}>
        <DialogContent
          sx={{ padding: 0, overflow: "hidden", backgroundColor: "#000" }}
        >
          <MediaCarousel files={files} />
        </DialogContent>
      </Dialog>
    );
  };

  return render();
});

export default MediaCarouselModal;
