import React, { useEffect, useState } from "react";
import { StepperWrapper } from "../../stepper_wrapper/StepperWrapper";
import MediaCarousel from "../../media/MediaCarousel";
import { OrderDetails } from "../../order_details/OrderDetails";
import { EmployeeScoreDetails } from "../technician/EmployeeScoreDetails";
import { VehicleDetails } from "../vehicle/VehicleDetails";
import ROMessages from "../messages/ROMessages";
import { get, forEach, isUndefined, defaultTo } from "lodash";
import RepairOrderService from "../../../services/RepairOrder.service";
import RecommendedWorkModal from "../recommended_work/RecommendedWorkModal";
import ModuleHelper from "../../../util/ModuleHelper";
import { IRepairOrder } from "../../../models/repair_order";

interface IProps {
  repairOrder: IRepairOrder;
  repairOrderService: RepairOrderService;
}

const RepairOrderDetails = (props: IProps): React.ReactElement => {
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    getRoFiles(props.repairOrder);
  }, [props.repairOrder]);

  const getRoFiles = (repairOrder: IRepairOrder): void => {
    setPending(true);
    props.repairOrderService
      .getRepairOrderFiles(repairOrder.id)
      .then((response) => {
        const fileMap = new Map();

        const files = defaultTo(response, []);
        forEach(files, (file) => {
          if (fileMap.has(file.liId)) {
            const array = defaultTo(fileMap.get(file.liId), []);
            array.push(file);
          } else {
            fileMap.set(file.liId, [file]);
          }
        });

        props.repairOrder.lineItems.forEach((li) => {
          if (fileMap.has(li.id)) {
            li.files = fileMap.get(li.id);
          }
        });
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setPending(false));
  };

  const render = (): React.ReactElement => {
    const repairOrder = props.repairOrder;

    const files = repairOrder.getFiles();
    const activeStep = repairOrder.getActiveStep();

    const technician = repairOrder.getTechnician();

    const pendingLineItems = repairOrder.getPendingLineItems();

    const repairOrderService = get(props, "repairOrderService");

    return (
      <React.Fragment>
        <StepperWrapper activeStep={activeStep} />

        <MediaCarousel files={files} />

        <OrderDetails repairOrder={repairOrder} />

        {!isUndefined(technician) && (
          <EmployeeScoreDetails
            employee={technician}
            style={{ marginBottom: 15 }}
          />
        )}

        <VehicleDetails
          vehicle={repairOrder.vehicle}
          style={{ marginBottom: 15 }}
        />

        <div style={{ margin: 2 }}>
          <RecommendedWorkModal
            pending={pending}
            activeStep={activeStep}
            lineItems={pendingLineItems}
            repairOrderService={repairOrderService}
          />
        </div>

        <div style={{ margin: 2 }}>
          <ROMessages
            repairOrder={repairOrder}
            repairOrderService={repairOrderService}
          />
        </div>
      </React.Fragment>
    );
  };

  return render();
};

export default RepairOrderDetails;
