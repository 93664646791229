import React, { useState } from "react";
import { Channel, SendBirdProvider } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../index";
import { globalColors } from "../../util/ThemePalette";
import { useStyles } from "./SendBird.styles";
import { isEmpty } from "lodash";

interface IProps {
  channelUrl?: string;
}

const myColorSet = {
  "--sendbird-light-primary-500": globalColors.orange,
  "--sendbird-light-primary-400": globalColors.orange100,
  "--sendbird-light-primary-300": globalColors.orange300,
  "--sendbird-light-primary-200": globalColors.orange200,
  "--sendbird-light-primary-100": globalColors.orange100,
};

const SendBirdChannel = (props: IProps): React.ReactElement => {
  const classes = useStyles();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const render = (): React.ReactElement => {
    return (
      <div className={classes.container}>
        {!isEmpty(props.channelUrl) && (
          <SendBirdProvider
            userId={`${user.id}`}
            colorSet={myColorSet}
            nickname={user.firstName}
            appId={`${process.env.REACT_APP_SENDBIRD_APP_ID}`}
          >
            <div style={{ height: "100%" }}>
              <Channel
                replyType={"QUOTE_REPLY"}
                channelUrl={`${props.channelUrl}`}
                onSearchClick={() => setShowSearch(!showSearch)}
              />
            </div>
          </SendBirdProvider>
        )}
      </div>
    );
  };

  return render();
};

export default SendBirdChannel;
