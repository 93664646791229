import { IOpCodeDTO } from "../../../index";
import { IOpCode } from "./index";
import Utils from "../../util/Utils";

export default class OpCode implements IOpCode {
  private _id: number;
  private _code: string;
  private _initDesc: string;
  private _additionalDesc: string;

  constructor(args?: IOpCodeDTO) {
    this._id = Utils.default(args?.id, 0);
    this._code = Utils.default(args?.code, "");
    this._initDesc = Utils.default(args?.initDesc, "");
    this._additionalDesc = Utils.default(args?.additionalDesc, "");
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get initDesc(): string {
    return this._initDesc;
  }

  set initDesc(value: string) {
    this._initDesc = value;
  }

  get additionalDesc(): string {
    return this._additionalDesc;
  }

  set additionalDesc(value: string) {
    this._additionalDesc = value;
  }
}
