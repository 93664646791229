import React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import PaperWrapper from "../../components/paper/PaperWrapper";

const AppStore = (): React.ReactElement => {
  const render = (): React.ReactElement => {
    return (
      <Box
        sx={{
          maxWidth: 400,
          position: "relative",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <PaperWrapper sx={{ padding: 1 }}>
          <Typography variant={"h5"} textAlign={"center"}>
            <b>Download app from App Store or Google Play</b>
          </Typography>
          <Grid
            container
            spacing={0}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item>
              <IconButton
                size={"small"}
                onClick={() => {
                  window.open("https://apps.apple.com/", "_blank");
                }}
                style={{ backgroundColor: "transparent", padding: 20 }}
              >
                <img alt={""} width={"200px"} src={"./images/a-badge.svg"} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                size={"small"}
                onClick={() => {
                  window.open("https://play.google.com/store/", "_blank");
                }}
                style={{ backgroundColor: "transparent" }}
              >
                <img alt={""} width={"225px"} src={"./images/g-badge.png"} />
              </IconButton>
            </Grid>
          </Grid>
          <Typography textAlign={"center"} color={"red"}>
            Please use your mobile number to Sign In.
          </Typography>
        </PaperWrapper>
      </Box>
    );
  };

  return render();
};

export default AppStore;
