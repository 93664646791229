import React from "react";
import { Grid } from "@mui/material";
import DealershipDetails from "./DealershipDetails";

const DealershipProfile = (): React.ReactElement => {
  return (
    <Grid spacing={2} container alignItems={"center"} justifyContent={"center"}>
      <Grid item xl={5} lg={6} md={7} xs={12}>
        <DealershipDetails />
      </Grid>
    </Grid>
  );
};

export default DealershipProfile;
