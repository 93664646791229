import { useStyles } from "./RecommendedWork.styles";
import { Dialog, DialogContent, IconButton, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import RepairOrderService from "../../../services/RepairOrder.service";
import ModuleHelper from "../../../util/ModuleHelper";
import { forEach } from "lodash";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import LaborOperationRender from "./LaborOperationRender";
import EngineeringIcon from "@mui/icons-material/Engineering";
import useMobileResolution from "../../../hooks/useMobileResolution";
import { ILineItem } from "../../../models/line_item";

interface IProps {
  readonly pending: boolean;
  readonly activeStep: number;
  readonly lineItems: ILineItem[];
  readonly repairOrderService: RepairOrderService;
}

export interface IRWModal {
  hide(): void;
  show(lineItems: ILineItem[]): void;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  lineHeight: 1.5,
  width: "100%",
});

const RecommendedWorkModal = (props: IProps): React.ReactElement | null => {
  const isMobile = useMobileResolution();
  const classes = useStyles({
    height: isMobile ? "100%" : "unset",
    maxHeight: isMobile ? "unset" : "90%",
    borderRadius: isMobile ? 0 : 10,
  });

  const [, setRefreshKey] = useState<any>();

  const [open, setOpen] = useState<boolean>(false);
  const [declinePending, setDeclinePending] = useState<boolean>(false);
  const [approvedPending, setApprovedPending] = useState<boolean>(false);

  const [lineItems, setLineItems] = useState<ILineItem[]>([]);

  useEffect(() => {
    setLineItems(props.lineItems);
  }, [props.lineItems]);

  const show = (): void => {
    setOpen(true);
  };

  const hide = (): void => {
    setOpen(false);
  };

  const removeLineItem = (id: number): void => {
    const index = lineItems.findIndex((item) => item.id === id);

    if (index !== -1) {
      lineItems.splice(index, 1);

      if (lineItems.length === 0) {
        hide();
        return;
      }

      setRefreshKey(Date.now());
    }
  };

  const approveAll = (event: any) => {
    event.stopPropagation();

    ModuleHelper.confirmDialog.confirm(
      "Approve All?",
      "Are you sure you want to approve all recommended work?",
      [
        { title: "No", color: "info" },
        {
          title: "Yes",
          color: "error",
          onClick: () => {
            const promises: any[] = [];

            forEach(props.lineItems, (lineItem) => {
              promises.push(
                props.repairOrderService.approveDeclineRS(lineItem.id, true)
              );
            });

            setApprovedPending(true);
            Promise.all(promises)
              .then(() => {
                hide();
                lineItems.splice(0, lineItems.length);
              })
              .catch((error) => {
                ModuleHelper.grow.showError(error);
              })
              .finally(() => setApprovedPending(false));
          },
        },
      ]
    );
  };

  const declineAll = (event: any) => {
    event.stopPropagation();

    ModuleHelper.confirmDialog.confirm(
      "Decline All?",
      "Are you sure you want to decline all recommended work?",
      [
        { title: "No", color: "info" },
        {
          title: "Yes",
          color: "error",
          onClick: () => {
            const promises: any[] = [];

            forEach(props.lineItems, (lineItem) => {
              promises.push(
                props.repairOrderService.approveDeclineRS(lineItem.id, false)
              );
            });

            setDeclinePending(true);
            Promise.all(promises)
              .then(() => {
                hide();
                lineItems.splice(0, lineItems.length);
              })
              .catch((error) => {
                ModuleHelper.grow.showError(error);
              })
              .finally(() => setDeclinePending(false));
          },
        },
      ]
    );
  };

  const render = (): React.ReactElement | null => {
    if (lineItems.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <SubmitButton
          startIcon={<EngineeringIcon />}
          loading={props.pending}
          variant={"contained"}
          fullWidth={true}
          onClick={show}
        >
          View Recommended Work
        </SubmitButton>

        <Dialog
          open={open}
          keepMounted={false}
          className={classes.dialog}
          onClose={hide}
        >
          <div
            style={{
              display: "flex",
              marginTop: 10,
              marginLeft: 20,
              marginRight: 15,
            }}
          >
            <span style={{ marginTop: 10 }}>
              <b>{`Recommended Work (${lineItems.length})`}</b>
            </span>

            <IconButton
              onClick={hide}
              aria-label={"close"}
              style={{ marginLeft: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <DialogContent
            style={{
              maxWidth: "100%",
              minWidth: "unset",
              overflow: "auto",
              maxHeight: "100vh",
            }}
          >
            <Box>
              {lineItems.map((item) => (
                <LaborOperationRender
                  key={item.id}
                  lineItem={item}
                  files={item.files}
                  onRemove={removeLineItem}
                  repairOrderService={props.repairOrderService}
                />
              ))}
            </Box>

            <React.Fragment>
              <SubmitButton
                startIcon={<DoneIcon />}
                loading={approvedPending}
                variant={"outlined"}
                size={"medium"}
                disableRipple
                color={"success"}
                onClick={approveAll}
                style={{ marginBottom: 2 }}
              >
                Approve All
              </SubmitButton>
              <SubmitButton
                startIcon={<CancelIcon />}
                loading={declinePending}
                variant={"outlined"}
                size={"medium"}
                disableRipple
                color={"error"}
                onClick={declineAll}
              >
                Decline All
              </SubmitButton>
            </React.Fragment>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  };

  return render();
};

export default RecommendedWorkModal;
