import { ILaborOperationDTO } from "../../../index";
import Utils from "../../util/Utils";
import OpCode from "../op_code/OpCode";
import Part from "../part/Part";
import ModuleHelper from "../../util/ModuleHelper";
import Time from "../time/Time";
import { ILaborOperation } from "./index";
import { IOpCode } from "../op_code";
import { IPart } from "../part";

export default class LaborOperation implements ILaborOperation {
  private _id: number;
  private _opCode: IOpCode;
  private _parts: IPart[];
  private _price: number;
  private _spentTime: any;

  constructor(args?: ILaborOperationDTO) {
    this._opCode = new OpCode(args?.opCode);
    this._spentTime = new Time(args?.spentTime);
    this._id = Utils.default(args?.id, 0);
    this._parts = Utils.default(args?.parts, []).map((item) => new Part(item));
    this._price = ModuleHelper.centToDollar(Utils.default(args?.price, 0));
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get opCode(): IOpCode {
    return this._opCode;
  }

  set opCode(value: IOpCode) {
    this._opCode = value;
  }

  get parts(): IPart[] {
    return this._parts;
  }

  set parts(value: IPart[]) {
    this._parts = value;
  }

  get price(): number {
    return this._price;
  }

  set price(value: number) {
    this._price = value;
  }

  get spentTime(): any {
    return this._spentTime;
  }

  set spentTime(value: any) {
    this._spentTime = value;
  }

  getOperationDescription(): string {
    const opCode: IOpCode = this._opCode;

    return ModuleHelper.getValueIfDefault(
      opCode.additionalDesc,
      opCode.initDesc
    );
  }
}
