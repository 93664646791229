import { IVehicleReview } from "./index";
import { IVehicleReviewDto } from "../../../index";
import Utils from "../../util/Utils";
import BaseUser from "../user/BaseUser";
import Tip from "../tip/Tip";
import Review from "../review/Review";
import { IReview } from "../review";
import { IBaseUser } from "../user";
import { ITip } from "../tip";
import Vehicle from "../vehicle/Vehicle";
import Sales from "../user/Sales";

export default class VehicleReview implements IVehicleReview {
  private _vehicle: IVehicle;
  private _customer: IBaseUser;
  private _review: IReview;
  private _seller: IBaseUser;
  private _tip: ITip;

  constructor(args?: IVehicleReviewDto) {
    this._tip = new Tip(args?.tip);
    this._review = new Review(args?.review);
    this._customer = new BaseUser(args?.customer);
    this._seller = new Sales(args?.salesperson);
    this._vehicle = new Vehicle({
      id: Utils.default(args?.id, 0),
      cdkId: Utils.default(args?.cdkId, ""),
      model: Utils.default(args?.model, ""),
      vin: Utils.default(args?.vin, ""),
      color: Utils.default(args?.color, ""),
      updated: Utils.default(args?.updated, ""),
      lastServiceDate: Utils.default(args?.lastServiceDate, ""),
      manufacturer: Utils.default(args?.manufacturer, ""),
      mileage: Utils.default(args?.mileage, 0),
      manufacturingYear: Utils.default(args?.manufacturingYear, 0),
      mileageLastVisit: Utils.default(args?.mileageLastVisit, 0),
    });
  }

  get vehicle(): IVehicle {
    return this._vehicle;
  }

  set vehicle(value: IVehicle) {
    this._vehicle = value;
  }

  get customer(): IBaseUser {
    return this._customer;
  }

  set customer(value: IBaseUser) {
    this._customer = value;
  }

  get review(): IReview {
    return this._review;
  }

  set review(value: IReview) {
    this._review = value;
  }

  get seller(): IBaseUser {
    return this._seller;
  }

  set seller(value: IBaseUser) {
    this._seller = value;
  }

  get tip(): ITip {
    return this._tip;
  }

  set tip(value: ITip) {
    this._tip = value;
  }
}
