import { UserRole } from "./Enums";
import ShopIcon from "@mui/icons-material/Shop";
import LogoutIcon from "@mui/icons-material/Logout";
import PreviewIcon from "@mui/icons-material/Preview";
import WidgetsIcon from "@mui/icons-material/Widgets";
import SettingsIcon from "@mui/icons-material/Settings";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DescriptionIcon from "@mui/icons-material/Description";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

export interface IPage {
  name: string;
  pathname: string;

  icon?: any;
  role?: UserRole[];
  forbiddenRole?: UserRole[];
  pages?: { [key: string]: IPage };
}

const route = {
  dealership: {
    name: "Menu",
    pathname: "",
    role: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.USER_MANAGER],
    forbiddenRole: [],
    pages: {
      overview: {
        name: "Overview",
        pathname: "/",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
        forbiddenRole: [],
        icon: <PreviewIcon />,
      },
      users: {
        name: "Users",
        pathname: "/users",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.USER_MANAGER],
        forbiddenRole: [],
        icon: <EngineeringIcon />,
      },
      tipRecords: {
        name: "Tips",
        pathname: "/tips",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
        forbiddenRole: [],
        icon: <MonetizationOnIcon />,
      },
      profile: {
        name: "Dealer Profile",
        pathname: "/dealer-profile",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
        icon: <DirectionsCarIcon />,
      },
      opCodes: {
        name: "OP Codes",
        pathname: "/op-codes",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
        icon: <DescriptionIcon />,
      },
      settings: {
        name: "Settings",
        pathname: "/settings",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
        icon: <SettingsIcon />,
      },
      notificationSettings: {
        name: "Notification Settings",
        pathname: "/notification-settings",
        role: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
        icon: <NotificationsIcon />,
      },
    },
    icon: <WidgetsIcon />,
  },
  dealerships: {
    name: "Dealerships",
    pathname: "/dealerships",
    role: [UserRole.SUPER_ADMIN],
    forbiddenRole: [],
    icon: <CarRepairIcon />,
  },
  leaderboard: {
    name: "Leaderboard",
    pathname: "/employee/leaderboard",
    role: [UserRole.ADVISOR, UserRole.TECHNICIAN, UserRole.SALESPERSON],
    forbiddenRole: [],
    icon: <PreviewIcon />,
  },
  appStore: {
    name: "App Store",
    pathname: "/app-store",
    role: [UserRole.ADVISOR, UserRole.TECHNICIAN],
    forbiddenRole: [],
    icon: <ShopIcon />,
  },
  profile: {
    name: "Profile",
    pathname: "/user/profile",
    role: [],
    forbiddenRole: [],
    icon: <ManageAccountsIcon />,
  },
  thankYou: {
    name: "Thank You",
    pathname: "/thank-you",
    role: [],
  },
  repairOrder: {
    name: "Repair Order",
    pathname: "/repair-order",
    role: [UserRole.CUSTOMER],
  },
  vehicle: {
    name: "Vehicle",
    pathname: "/vehicle",
    role: [UserRole.CUSTOMER],
  },
  logOut: {
    name: "Logout",
    pathname: "",
    role: [],
    icon: <LogoutIcon />,
  },

  userActivate: {
    name: "User Activate",
    pathname: "/public/user-activate",
    role: [],
  },
  login: {
    name: "Login",
    pathname: "/login",
    role: [],
  },
  notFound: {
    name: "Page Not Found",
    pathname: "/404",
    role: [],
  },
};

export default route;
