import React from "react";
import ModuleHelper from "../../util/ModuleHelper";
import Navigation from "../../components/Navigation";
import VisibleContent from "../../components/visable_content/VisibleContent";

interface IProps {
  children: React.ReactElement;
}

const AppMenu = (props: IProps): React.ReactElement => {
  const render = (): React.ReactElement => {
    const isCustomer = ModuleHelper.isCustomer();

    return (
      <React.Fragment>
        <VisibleContent visible={!isCustomer}>
          <Navigation />
        </VisibleContent>

        <React.Fragment>
          <div
            style={{
              overflow: "auto",
              height: isCustomer ? "100%" : "calc(100% - 54px)",
            }}
            className={"wrapper"}
          >
            {props.children}
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  };

  return render();
};

export default AppMenu;
