import { IContextUser } from "./index";
import { IDealershipContext } from "../dealership";
import { IContextUserDTO } from "../../../index";
import DealershipContext from "../dealership/DealershipContext";
import Utils from "../../util/Utils";
import User from "./User";

export default class ContextUser extends User implements IContextUser {
  private _dealerContext: IDealershipContext;
  private _locale: string;

  constructor(args?: IContextUserDTO) {
    super(args);
    this._locale = Utils.default(args?.locale, "");
    this._dealerContext = new DealershipContext(args?.dealerContext);
  }

  get dealerContext(): IDealershipContext {
    return this._dealerContext;
  }

  set dealerContext(value: IDealershipContext) {
    this._dealerContext = value;
  }

  get locale(): string {
    return this._locale;
  }

  set locale(value: string) {
    this._locale = value;
  }
}
