import React from "react";
import { Grid, Typography } from "@mui/material";

const SomethingWentWrong = (): React.ReactElement => {
  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <div style={{ textAlign: "center" }}>
              <img src={"./images/warning.png"} width={200} alt={""} />
              <Typography variant={"h1"} textAlign={"center"}>
                Oops!
              </Typography>
              <Typography variant={"h2"} textAlign={"center"}>
                Something Went Wrong!
              </Typography>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return render();
};

export default SomethingWentWrong;
