import axios, { AxiosRequestConfig } from "axios";
import { get } from "lodash";
import LoginService from "../../services/Login.service";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleServiceError = async (error: any): Promise<any> => {
  const data = get(error, "response");
  const status = get(data, "status", 400);

  if (status !== 401) {
    throw error;
  }

  if (isLoginRequest(error.config)) {
    throw error;
  }

  try {
    const loginService = new LoginService();

    const response = await loginService.refreshToken();

    const accessToken = response.data.access_token;
    error.config.headers["Authorization"] = `Bearer ${accessToken}`;

    return axios.request(error.config);
  } catch (error: any) {
    throw error;
  }
};

function isLoginRequest(config: AxiosRequestConfig): boolean {
  return !!(
    config.url?.includes("protocol/openid-connect/token") &&
    config.data?.includes("grant_type=password")
  );
}
