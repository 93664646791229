import React from "react";

import PhoneInput from "react-phone-number-input";
import CustomPhoneInput from "./CustomPhoneInput";
import { FieldProps } from "formik";
import { TextFieldProps } from "@mui/material";

const FormPhoneField: React.FC<FieldProps & TextFieldProps> = (
  props
): React.ReactElement => {
  const { name, value } = props.field;
  const { setFieldValue, setFieldTouched } = props.form;

  const { field, ...rest } = props;

  const render = (): any => {
    return (
      <PhoneInput
        value={value}
        countries={["US"]}
        defaultCountry={"US"}
        limitMaxLength={true}
        addInternationalOption={false}
        onChange={(value) => setFieldValue(name, value)}
        numberInputProps={{
          ...rest,
          ...field,
          ...{ id: field.name },
        }}
        onBlur={() => setFieldTouched(name, true)}
        style={{ width: "100%" }}
        inputComponent={CustomPhoneInput}
      />
    );
  };

  return render();
};

export default FormPhoneField;
