import { ITip } from "./index";
import { ITipDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class Tip implements ITip {
  private _advisorUsername: string;
  private _allTechniciansUsernames: string;
  private _createdAt: string;
  private _currency: string;
  private _id: number;
  private _receivedAmount: number;
  private _technicianUsername: string;
  private _transferredToEmployees: any;

  constructor(args?: ITipDTO) {
    this._advisorUsername = Utils.default(args?.advisorUsername, "");
    this._allTechniciansUsernames = Utils.default(
      args?.allTechniciansUsernames,
      ""
    );
    this._createdAt = Utils.default(args?.createdAt, "");
    this._currency = Utils.default(args?.currency, "");
    this._id = Utils.default(args?.id, 0);
    this._receivedAmount = Utils.default(args?.receivedAmount, 0);
    this._technicianUsername = Utils.default(args?.technicianUsername, "");
    this._transferredToEmployees = Utils.default(
      args?.transferredToEmployees,
      ""
    );
  }

  get advisorUsername(): string {
    return this._advisorUsername;
  }

  set advisorUsername(value: string) {
    this._advisorUsername = value;
  }

  get allTechniciansUsernames(): string {
    return this._allTechniciansUsernames;
  }

  set allTechniciansUsernames(value: string) {
    this._allTechniciansUsernames = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    this._currency = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get receivedAmount(): number {
    return this._receivedAmount;
  }

  set receivedAmount(value: number) {
    this._receivedAmount = value;
  }

  get technicianUsername(): string {
    return this._technicianUsername;
  }

  set technicianUsername(value: string) {
    this._technicianUsername = value;
  }

  get transferredToEmployees(): any {
    return this._transferredToEmployees;
  }

  set transferredToEmployees(value: any) {
    this._transferredToEmployees = value;
  }
}
