import { ITimeDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class Time implements ITime {
  private _absolute: boolean;
  private _objectDTO: any;
  private _relative: boolean;
  private _sqlValue: string;

  constructor(args?: ITimeDTO) {
    this._absolute = Utils.default(args?.absolute, false);
    this._objectDTO = Utils.default(args?.objectDTO, {});
    this._relative = Utils.default(args?.relative, false);
    this._sqlValue = Utils.default(args?.sqlValue, "");
  }

  get absolute(): boolean {
    return this._absolute;
  }

  set absolute(value: boolean) {
    this._absolute = value;
  }

  get objectDTO(): any {
    return this._objectDTO;
  }

  set objectDTO(value: any) {
    this._objectDTO = value;
  }

  get relative(): boolean {
    return this._relative;
  }

  set relative(value: boolean) {
    this._relative = value;
  }

  get sqlValue(): string {
    return this._sqlValue;
  }

  set sqlValue(value: string) {
    this._sqlValue = value;
  }
}
