import { makeStyles } from "@mui/styles";
import { globalColors } from "../../util/ThemePalette";

export const useStyles = makeStyles(() => ({
  menu: {
    "&&": {
      "& .css-6hp17o-MuiList-root-MuiMenu-list": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiPaper-root": {
        top: "70px !important",
        right: 5,
        width: 280,
        maxWidth: 280,
      },
      "& .MuiListItemButton-root": {
        padding: 0,
      },
    },
  },
  loader: {
    width: 280,
    minWidth: 280,
    padding: 5,
  },
  listItem: {
    width: "100%",
    borderBottom: `1px solid ${globalColors.lightGrey} !important`,
  },
  seenBadge: {
    "&&": {
      "& .MuiBadge-anchorOriginTopRightRectangular": {
        minWidth: 10,
        width: 11,
        height: 11,
      },
    },
  },
}));
