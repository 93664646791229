import React from "react";
import { isEmpty } from "lodash";
import { GridColumnHeaderParams, GridFilterItem } from "@mui/x-data-grid";
import { Typography, IconButton } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

interface ICustomHeader {
  params: GridColumnHeaderParams;
  reset: any;
  filter: GridFilterItem[];
}

const CustomHeader = (props: ICustomHeader): React.ReactElement => {
  const filterDisplay = (field: string, filter: any) => {
    if (isEmpty(filter)) {
      return "none";
    } else if (!isEmpty(filter) && !filter[0].value) {
      return "none";
    } else {
      return field !== filter[0].columnField ? "none" : "grid";
    }
  };

  return (
    <React.Fragment>
      <Typography
        style={{
          fontWeight: 500,
          fontSize: "0.875rem",
          whiteSpace: "normal",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {props.params.colDef.headerName}
      </Typography>
      <IconButton
        size="small"
        aria-label="reset-filter"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={props.reset}
        color="inherit"
        type="button"
        style={{
          display: filterDisplay(props.params.colDef.field, props.filter),
        }}
      >
        <FilterAltOffIcon fontSize="inherit" htmlColor="rgba(0, 0, 0, 0.54)" />
      </IconButton>
    </React.Fragment>
  );
};

export default CustomHeader;
