import MenuBuilder from "./MenuBuilder";
import route, { IPage } from "../../common/route";

export default class SuperAdminMenuBuilder extends MenuBuilder {
  build(): IPage[] {
    const pages: IPage[] = [];

    this.buildSubPages(pages, route.dealership);

    if (this.hasRouteAccess(route.dealerships)) {
      pages.push(route.dealerships);
    }

    return pages;
  }
}
