import { AxiosResponse } from "axios";
import {
  ICreateDealerDTO,
  IDealershipDTO,
  IDealershipContextDTO,
  IDealershipNotificationConfig,
  INotificationChannels,
  IOpCodeDTO,
  IBaseOpCodeDTO,
  IPageResponse,
} from "../../index";
import { DealershipSettings, UserRole } from "../common/Enums";
import { defaultTo, entries, forEach } from "lodash";
import { IInitialValues } from "../views/dealership/notification_settings/NotificationEvents";
import DealershipConfig from "../models/dealership_config/DealershipConfig";
import { IDealershipConfig } from "../models/dealership_config";
import { IDealership } from "../models/dealership";
import Dealership from "../models/dealership/Dealership";

export interface IDealershipConfigDTO {
  [DealershipSettings.RATE_TITLE_MESSAGE]: string;
  [DealershipSettings.TIP_TITLE_MESSAGE]: string;
  [DealershipSettings.CUSTOMER_DEFAULT_NAME]: string;
  [DealershipSettings.REVIEW_REMIND_CUSTOMER_AFTER_MIN]: number;
  [DealershipSettings.TIP_WHO_RECEIVE]: UserRole[];
  [DealershipSettings.LEAVE_A_TIP_CANCEL_BUTTON]: string;
  [DealershipSettings.LEAVE_A_TIP_SUBMIT_BUTTON]: string;
  [DealershipSettings.TIP_MAX_AMOUNT_IN_CENTS]: number;
  [DealershipSettings.NOTIFICATION_DURATION]: number;
  [DealershipSettings.MESSAGE_SEND_TO_CUSTOMER]: UserRole[];
  [DealershipSettings.TIP_INCREMENTATION_IN_CENTS]: number[];
  [DealershipSettings.TIP_MIN_AMOUNT_IN_CENTS]: number;
  [DealershipSettings.DEACTIVATE_EMPLOYEE]: boolean;
  [DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP]: boolean;
  [DealershipSettings.THANK_YOU_SCREEN_MESSAGE]: string;
  [DealershipSettings.REVIEW_LOW_SCORE_VALUE]: number;
  [DealershipSettings.VEHICLE_SOLD_NOTIFICATION_AM_TIME]: number;
  [DealershipSettings.REVIEW_LOW_SCORE_SEND_TO_EMAIL_LIST]: string[];
  [DealershipSettings.SEND_EMAILS_WITH_LOW_SCORE_REVIEW]: boolean;
  [DealershipSettings.CHECK_FETCHED_FROM_CDK_NOTIFY_CUSTOMER_FIELD]: boolean;
  [DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN]: number;
}

interface IDeleteDealershipDto {
  dealerId: number;
  hardDelete: boolean;
  adminPassword: string;
  removeFromStorage: boolean;
}

interface IDealershipApiClient {
  getDealership(): Promise<AxiosResponse<IDealershipDTO>>;
  getAllDealerships(
    page: number,
    limit: number,
    searchString?: string
  ): Promise<AxiosResponse<IPageResponse<IDealershipContextDTO[]>>>;
  createDealership(
    data: ICreateDealerDTO
  ): Promise<AxiosResponse<IDealershipDTO>>;
  deleteDealership(dto: IDeleteDealershipDto): Promise<AxiosResponse>;
  updateDealership(
    data: IDealershipDTO
  ): Promise<AxiosResponse<IDealershipDTO>>;
  getDealershipConfig(): Promise<AxiosResponse<IDealershipConfigDTO>>;
  updateDealershipConfig(data: IDealershipConfigDTO): Promise<AxiosResponse>;
  updateDealershipBackgroundImage(data: FormData): Promise<AxiosResponse>;

  getDealershipCustomConfig(
    config: DealershipSettings[]
  ): Promise<AxiosResponse>;

  getNotificationSettings(): Promise<
    AxiosResponse<IDealershipNotificationConfig>
  >;
  updateNotificationEvents(data: {
    notificationMessages: any;
    updateNotificationEventsSettings: any;
  }): Promise<AxiosResponse>;
  updateNotificationChannels(data: {
    notificationChannels: INotificationChannels;
  }): Promise<AxiosResponse<IDealershipNotificationConfig>>;
  getOpCodes(
    page: number,
    limit: number
  ): Promise<AxiosResponse<{ result: IOpCodeDTO[] }>>;
  updateOpCode(opCode: IOpCodeDTO): Promise<AxiosResponse<IOpCodeDTO>>;
  deleteOpCode(id: number): Promise<AxiosResponse>;
  createOpCode(opCode: IBaseOpCodeDTO): Promise<AxiosResponse<IOpCodeDTO>>;
  demoDealerReset(): Promise<AxiosResponse>;
  getCountries(): Promise<AxiosResponse<{ name: string; id: number }[]>>;
}

class DealershipApiClient implements IDealershipApiClient {
  getDealershipCustomConfig(
    config: DealershipSettings[]
  ): Promise<AxiosResponse> {
    const params = new URLSearchParams();

    forEach(config, (param) => {
      params.append("configs", param);
    });

    return window.apiClient.get(`${this.prefix}/config`, { params: params });
  }
  private readonly prefix = "dealership";

  getDealership(): Promise<AxiosResponse<IDealershipDTO>> {
    return window.apiClient.get<IDealershipDTO>(`${this.prefix}/profile`);
  }

  updateDealership(
    data: IDealershipDTO
  ): Promise<AxiosResponse<IDealershipDTO>> {
    return window.apiClient.put(`${this.prefix}/profile`, data);
  }

  getDealershipConfig(): Promise<AxiosResponse<IDealershipConfigDTO>> {
    return window.apiClient.get(`${this.prefix}/config/all`);
  }

  updateDealershipConfig(data: IDealershipConfigDTO): Promise<AxiosResponse> {
    return window.apiClient.post(`${this.prefix}/config`, data);
  }

  getNotificationSettings(): Promise<
    AxiosResponse<IDealershipNotificationConfig>
  > {
    return window.apiClient.get(`${this.prefix}/notification/settings`);
  }

  updateNotificationEvents(data: {
    notificationMessages: any;
    updateNotificationEventsSettings: any;
  }): Promise<AxiosResponse<IDealershipNotificationConfig>> {
    return window.apiClient.post(`${this.prefix}/notification/settings`, data);
  }

  updateDealershipBackgroundImage(data: FormData): Promise<AxiosResponse> {
    return window.apiClient.post(`file/${this.prefix}/background-image`, data);
  }

  updateNotificationChannels(data: {
    notificationChannels: INotificationChannels;
  }): Promise<AxiosResponse<IDealershipNotificationConfig>> {
    return window.apiClient.post(`${this.prefix}/notification/settings`, data);
  }

  getOpCodes(page: number, limit: number): Promise<AxiosResponse> {
    return window.apiClient.get(`${this.prefix}/op-code/all`, {
      params: {
        page: page,
        perPage: limit,
      },
    });
  }

  updateOpCode(opCode: IOpCodeDTO): Promise<AxiosResponse> {
    return window.apiClient.put(`${this.prefix}/op-code`, opCode);
  }

  deleteOpCode(id: number): Promise<AxiosResponse> {
    return window.apiClient.delete(`${this.prefix}/op-code`, {
      params: {
        instanceId: id,
      },
    });
  }

  createOpCode(opCode: IBaseOpCodeDTO): Promise<AxiosResponse<IOpCodeDTO>> {
    return window.apiClient.post(`${this.prefix}/op-code`, opCode);
  }

  getAllDealerships(
    page: number,
    limit: number,
    searchString?: string
  ): Promise<AxiosResponse<IPageResponse<IDealershipContextDTO[]>>> {
    return window.apiClient.get(`${this.prefix}/all`, {
      params: {
        page: page,
        perPage: limit,
        name: searchString,
      },
    });
  }

  createDealership(
    data: ICreateDealerDTO
  ): Promise<AxiosResponse<IDealershipDTO>> {
    return window.apiClient.post(`${this.prefix}`, data, {
      params: {
        hasParentOrganization: false,
      },
    });
  }

  deleteDealership(dto: IDeleteDealershipDto): Promise<AxiosResponse> {
    return window.apiClient.delete(`${this.prefix}`, { data: dto });
  }

  demoDealerReset(): Promise<AxiosResponse> {
    return window.apiClient.post(`${this.prefix}/demo-dealer/reset`);
  }

  getCountries(): Promise<AxiosResponse> {
    return window.apiClient.get("public/countries");
  }
}

export default class DealershipService {
  private dealershipApiClient: IDealershipApiClient;

  constructor() {
    this.dealershipApiClient = new DealershipApiClient();
  }

  public async getDealership(): Promise<IDealership> {
    const response = await this.dealershipApiClient.getDealership();

    return new Dealership(response.data);
  }

  public async updateDealership(data: IDealership): Promise<IDealership> {
    const response = await this.dealershipApiClient.updateDealership(
      data.toDTO()
    );

    return new Dealership(response.data);
  }

  public async getDealershipConfig(): Promise<DealershipConfig> {
    const response = await this.dealershipApiClient.getDealershipConfig();

    return new DealershipConfig(response.data);
  }

  public async updateDealershipConfig(
    data: IDealershipConfig
  ): Promise<DealershipConfig> {
    const response = await this.dealershipApiClient.updateDealershipConfig(
      data.toDTO()
    );

    return new DealershipConfig(response.data);
  }

  public async getNotificationDetails(): Promise<
    AxiosResponse<IDealershipNotificationConfig>
  > {
    return this.dealershipApiClient.getNotificationSettings();
  }

  public async updateNotificationDetails(
    data: IInitialValues
  ): Promise<AxiosResponse<IDealershipNotificationConfig>> {
    entries(data.notificationEvents).forEach((entry) => {
      const value = entry[1];

      entries(value).forEach((valueEntry) => {
        const eventKey = valueEntry[0];
        const eventValue: any = valueEntry[1];

        value[eventKey] = eventValue?.isTurnedOn;
      });
    });

    return this.dealershipApiClient.updateNotificationEvents({
      notificationMessages: data.notificationMessages,
      updateNotificationEventsSettings: data.notificationEvents,
    });
  }

  public async updateDealershipBackgroundImage(
    file: File
  ): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.set("image", file);
    return this.dealershipApiClient.updateDealershipBackgroundImage(formData);
  }

  public async updateNotificationChannels(data: {
    notificationChannels: INotificationChannels;
  }): Promise<AxiosResponse<IDealershipNotificationConfig>> {
    return this.dealershipApiClient.updateNotificationChannels(data);
  }

  public async getOpCodes(page: number, limit: number): Promise<IOpCodeDTO[]> {
    const response = await this.dealershipApiClient.getOpCodes(page, limit);

    return defaultTo(response.data.result, []);
  }

  public async updateOpCode(
    opCode: IOpCodeDTO
  ): Promise<AxiosResponse<IOpCodeDTO>> {
    return this.dealershipApiClient.updateOpCode(opCode);
  }

  public async deleteOpCode(id: number): Promise<AxiosResponse> {
    return this.dealershipApiClient.deleteOpCode(id);
  }

  public async createOpCode(
    opCode: IBaseOpCodeDTO
  ): Promise<AxiosResponse<IOpCodeDTO>> {
    return this.dealershipApiClient.createOpCode(opCode);
  }

  public async getAllDealerships(
    page: number,
    limit: number,
    searchString?: string
  ): Promise<IDealershipContextDTO[]> {
    const response = await this.dealershipApiClient.getAllDealerships(
      page,
      limit,
      searchString
    );

    return defaultTo(response.data.result, []);
  }

  public async createDealership(data: IDealership): Promise<IDealership> {
    const response = await this.getCountries();

    const dealershipDto = data.toDTO();

    const dto: ICreateDealerDTO = {
      ...dealershipDto,
      ...{
        isNewDealer: "true_",
        countryId: DealershipService.getUnitedStatesCountryId(response.data),
      },
    };

    const dealerResponse = await this.dealershipApiClient.createDealership(dto);

    return new Dealership(dealerResponse.data);
  }

  public async deleteDealership(
    dto: IDeleteDealershipDto
  ): Promise<AxiosResponse> {
    return this.dealershipApiClient.deleteDealership(dto);
  }

  public async getDealershipCustomConfig(
    config: DealershipSettings[]
  ): Promise<IDealershipConfig> {
    const response = await this.dealershipApiClient.getDealershipCustomConfig(
      config
    );

    return new DealershipConfig(response.data);
  }

  public async demoDealerReset(): Promise<AxiosResponse> {
    return this.dealershipApiClient.demoDealerReset();
  }

  public async getCountries(): Promise<
    AxiosResponse<{ name: string; id: number }[]>
  > {
    return this.dealershipApiClient.getCountries();
  }

  private static getUnitedStatesCountryId(
    countries: { name: string; id: number }[]
  ): number {
    const name = "United States of America";

    const index = countries.findIndex(
      (item) => item.name.toLowerCase() === name.toLowerCase()
    );

    if (index !== -1) {
      const item = countries[index];
      return item.id;
    }

    return 0;
  }
}
