import File from "./File";
import { ILIFile } from "./index";
import Utils from "../../util/Utils";
import { ILIFileDTO } from "../../../index";

export default class LIFile extends File implements ILIFile {
  private _fileId: number;
  private _liId: number;

  constructor(args?: ILIFileDTO) {
    super(args);
    this._fileId = Utils.default(args?.fileId, 0);
    this._liId = Utils.default(args?.liId, 0);
  }

  get fileId(): number {
    return this._fileId;
  }

  set fileId(value: number) {
    this._fileId = value;
  }

  get liId(): number {
    return this._liId;
  }

  set liId(value: number) {
    this._liId = value;
  }
}
