import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "inherit",
    padding: "10px 0px",
    background: "#F5F5F5",
    "& .carousel .thumbs-wrapper": {
      margin: 0,
    },
  },
}));
