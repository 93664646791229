import { Buildable } from "../../../index";
import { UserRole } from "../../common/Enums";
import { IPage } from "../../common/route";
import SuperAdminMenuBuilder from "./SuperAdminMenuBuilder";
import DefaultMenuBuilder from "./DefaultMenuBuilder";
import UserManagerMenuBuilder from "./UserManagerMenuBuilder";

export default class MenuBuilderController implements Buildable {
  private readonly role: UserRole;

  constructor(role: UserRole) {
    this.role = role;
  }

  build(): IPage[] {
    switch (this.role) {
      case UserRole.SUPER_ADMIN:
        return new SuperAdminMenuBuilder().build();
      case UserRole.USER_MANAGER:
        return new UserManagerMenuBuilder().build();
    }

    return new DefaultMenuBuilder().build();
  }
}
