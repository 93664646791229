import {
  IDealershipNotificationConfig,
  INotificationEventConfig,
} from "../../index";
import { UserRole } from "../common/Enums";

export default class DealershipHelper {
  public static getDefaultNotificationSettings(): IDealershipNotificationConfig {
    return {
      notifyRolesFlag: {
        ADVISOR: "false_",
        CUSTOMER: "false_",
        TECHNICIAN: "false_",
        DEALERSHIP: "true_",
      },
      notificationMessages: {
        LI_STATUS_UPDATED: "",
        NEW_MESSAGE: "",
        NEW_REVIEW_HIGH_SCORE: "",
        NEW_REVIEW_LOW_SCORE: "",
        NEW_TIP: "",
        NEW_VIDEO_PIC: "",
        PARTS_ORDERED: "",
        RECOMMENDED_SERVICE_APPROVED: "",
        RECOMMENDED_SERVICE_DECLINED: "",
        RECOMMENDED_SERVICE_SENT_TO_CUSTOMER_URGENT: "",
        RECOMMENDED_SERVICE_TO_BE_REVIEWED: "",
        REVIEW_REMINDER: "",
        RO_CLOSED: "",
        RO_OPENED: "",
        RO_REOPENED: "",
        RO_STATUS_UPDATED: "",
        TECH_STARTED_JOB: "",
        TIP_FAILED: "",
      },
      notificationChannels: {
        [UserRole.ADVISOR]: [],
        [UserRole.CUSTOMER]: [],
        [UserRole.ADMIN]: [],
        [UserRole.TECHNICIAN]: [],
      },
      notificationEventsSettings: {
        [UserRole.ADVISOR]:
          DealershipHelper.getDefaultNotificationEventSettings(),
        [UserRole.CUSTOMER]:
          DealershipHelper.getDefaultNotificationEventSettings(),
        [UserRole.TECHNICIAN]:
          DealershipHelper.getDefaultNotificationEventSettings(),
        [UserRole.ADMIN]:
          DealershipHelper.getDefaultNotificationEventSettings(),
      },
    };
  }

  private static getDefaultNotificationEventSettings(): INotificationEventConfig {
    return {
      NEW_TIP: { isTurnedOn: "false_", isConfigurable: "false_" },
      NEW_MESSAGE: { isTurnedOn: "false_", isConfigurable: "false_" },
      LI_STATUS_UPDATED: { isTurnedOn: "false_", isConfigurable: "false_" },
      NEW_REVIEW_HIGH_SCORE: {
        isTurnedOn: "false_",
        isConfigurable: "false_",
      },
      NEW_REVIEW_LOW_SCORE: {
        isTurnedOn: "false_",
        isConfigurable: "false_",
      },
      NEW_VIDEO_PIC: { isTurnedOn: "false_", isConfigurable: "false_" },
      PARTS_ORDERED: { isTurnedOn: "false_", isConfigurable: "false_" },
      RECOMMENDED_SERVICE_APPROVED: {
        isTurnedOn: "false_",
        isConfigurable: "false_",
      },
      RECOMMENDED_SERVICE_DECLINED: {
        isTurnedOn: "false_",
        isConfigurable: "false_",
      },
      RECOMMENDED_SERVICE_SENT_TO_CUSTOMER_URGENT: {
        isTurnedOn: "false_",
        isConfigurable: "false_",
      },
      RECOMMENDED_SERVICE_TO_BE_REVIEWED: {
        isTurnedOn: "false_",
        isConfigurable: "false_",
      },
      REVIEW_REMINDER: { isTurnedOn: "false_", isConfigurable: "false_" },
      RO_CLOSED: { isTurnedOn: "false_", isConfigurable: "false_" },
      RO_OPENED: { isTurnedOn: "false_", isConfigurable: "false_" },
      RO_REOPENED: { isTurnedOn: "false_", isConfigurable: "false_" },
      RO_STATUS_UPDATED: { isTurnedOn: "false_", isConfigurable: "false_" },
      TECH_STARTED_JOB: { isTurnedOn: "false_", isConfigurable: "false_" },
      TIP_FAILED: { isTurnedOn: "false_", isConfigurable: "false_" },
    };
  }
}
