import React, { useEffect, useState } from "react";
import {
  Badge,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Menu,
  ListItemText,
  Grid,
  Typography,
  ListItemButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfiniteScroll from "react-infinite-scroller";
import { IUserNotificationDTO } from "../../../index";
import { capitalCase } from "change-case";
import { get, concat } from "lodash";
import { useStyles } from "./Notification.styles";
import moment from "moment";
import ModuleHelper from "../../util/ModuleHelper";
import NotificationService from "../../services/Notification.service";
import { useForceUpdate } from "../../hooks/useForceUpdate";
import { globalColors } from "../../util/ThemePalette";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 5,
    border: `2px solid  ${globalColors.orange}`,
    padding: 4,
  },
}));

const Notifications = (): React.ReactElement => {
  const notificationService = new NotificationService();

  const classes = useStyles();
  const forceUpdate = useForceUpdate();

  const [loadSet] = useState<Set<number>>(new Set());
  const [limit] = useState<number>(100);
  const [unseen, setUnseen] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState<IUserNotificationDTO[]>(
    []
  );

  useEffect(() => {
    getUnseen();
  }, []);

  const onToggle = (e: any): void => {
    setOpen(!open);
    setHasMore(true);
    setAnchorEl(e.currentTarget);
  };

  const getUnseen = (): void => {
    notificationService.getUnseen().then((response) => {
      setUnseen(response);
    });
  };

  const getData = (page: number, limit: number): void => {
    notificationService
      .getNotifications(page, limit)
      .then((response) => {
        setHasMore(response.length === limit);

        const array = concat(notifications, response);

        if (array.length === 0) {
          onClose();
          return;
        }

        setNotifications(array);
      })
      .catch((error) => ModuleHelper.grow.showError(error));
  };

  const loadMore = (page: number): void => {
    if (hasMore) {
      getData(page, limit);
    }
  };

  const onClick = (item: IUserNotificationDTO) => (event: any) => {
    event.stopPropagation();

    if (ModuleHelper.stringBool(item.seenByMe)) {
      return;
    }

    loadSet.add(item.id);
    forceUpdate();
    notificationService
      .markAsSeen(item.id)
      .then(() => {
        setUnseen(Math.max(unseen - 1, 0));
        item.seenByMe = ModuleHelper.boolean(true);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => {
        loadSet.delete(item.id);
        forceUpdate();
      });
  };

  const content = (item: IUserNotificationDTO): React.ReactElement => {
    const avatarURL = get(item, "createdBy.avatarURL");
    const firstName = get(item, "createdBy.firstName");

    return (
      <ListItemButton key={item.id} onClick={onClick(item)}>
        <ListItem className={classes.listItem}>
          <ListItemAvatar>
            <Avatar src={avatarURL} alt={firstName} />
          </ListItemAvatar>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12}>
              <ListItemText>{capitalCase(item.event)}</ListItemText>
            </Grid>

            <Grid item xs={12}>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: globalColors.notificationBlue,
                  }}
                >
                  {moment(item.createdAt).fromNow()}
                </Typography>
              </ListItemText>
            </Grid>

            {loadSet.has(item.id) && (
              <Grid item xs={12}>
                <LinearProgress color={"primary"} />
              </Grid>
            )}
          </Grid>
          <Badge
            color={"info"}
            badgeContent={ModuleHelper.stringBool(item.seenByMe) ? null : ""}
            className={classes.seenBadge}
          />
        </ListItem>
      </ListItemButton>
    );
  };

  const onClose = (): void => {
    setOpen(false);
    setNotifications([]);
  };

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <IconButton aria-label="cart" onClick={onToggle}>
          <StyledBadge badgeContent={unseen} color={"secondary"}>
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
        <Menu
          open={open}
          onClose={onClose}
          anchorEl={anchorEl}
          className={classes.menu}
        >
          <div
            style={{
              width: "100%",
              cursor: "auto",
              maxHeight: 700,
              overflow: "auto",
            }}
          >
            <InfiniteScroll
              pageStart={-1}
              loadMore={loadMore}
              hasMore={hasMore}
              loader={
                <div className={classes.loader} key={0}>
                  <LinearProgress color={"primary"} />
                </div>
              }
              useWindow={false}
            >
              <List>{notifications.map(content)}</List>
            </InfiniteScroll>
          </div>
        </Menu>
      </React.Fragment>
    );
  };

  return render();
};

export default Notifications;
