import actions from "./actions";
import { IAuthState, IDealershipContextDTO } from "../../index";
import { IPage } from "../common/route";
import { isEmpty } from "lodash";
import { UserRole } from "../common/Enums";
import ModuleHelper from "../util/ModuleHelper";
import ContextUser from "../models/user/ContextUser";
import { IContextUser } from "../models/user";

export const loginSuccess = (payload: IContextUser): any => ({
  type: actions.LOGIN_SUCCESS,
  payload: payload,
});

export const updateUser = (payload: IContextUser): any => ({
  type: actions.UPDATE_USER_PROFILE,
  payload: payload,
});

export const switchDealershipContext = (
  payload: IDealershipContextDTO
): any => ({
  type: actions.UPDATE_DEALERSHIP_CONTEXT,
  payload: payload,
});

export default (
  state: IAuthState = {
    user: new ContextUser(),
    getUserName(): string {
      if (!isEmpty(this.user.firstName)) {
        return `${this.user.firstName}`;
      }

      if (!isEmpty(this.user.email)) {
        return `${this.user.email}`;
      }

      return `${this.user.username}`;
    },
    hasRouteAccess(page: IPage): boolean {
      if (!isEmpty(page.forbiddenRole)) {
        const isForbidden = page.forbiddenRole?.some((role) =>
          this.user.roles?.includes(role)
        );

        if (isForbidden) {
          return false;
        }
      }

      if (isEmpty(page.role)) {
        return true;
      }

      return this.user.roles.some((role) => page.role?.includes(role));
    },
    getUserRole(): UserRole {
      return ModuleHelper.getUserRole(this.user.roles);
    },
  },
  action: { type: string; payload?: any }
): IAuthState => {
  state = { ...state };

  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      state.user = action.payload;
      return state;
    case actions.UPDATE_USER_PROFILE:
      state.user = action.payload;
      return state;
    case actions.UPDATE_DEALERSHIP_CONTEXT:
      state.user.dealerContext = action.payload;
      return state;
    default:
      return state;
  }
};
