import React from "react";
import { get, isEqual, isUndefined } from "lodash";
import { FieldProps, getIn } from "formik";
import { TextField, Autocomplete } from "@mui/material";
import { ISelectOption } from "../../../../index";
import Utils from "../../../util/Utils";

export const FormSelectField: React.FC<
  FieldProps & {
    error: boolean;
    required: boolean;
    options: ISelectOption[];
    label: string;
    helperText: string;
    path: string;
  }
> = (props) => {
  const { options, label, required, path } = props;
  const { name, value } = props.field;
  const { setFieldValue } = props.form;

  const { error, helperText, ...rest } = props;
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const disabled = get(props, "disabled", false);
  const disableClearable = get(props, "disableClearable", false);

  return (
    <Autocomplete
      {...rest}
      value={Utils.default(value, undefined)}
      options={options}
      onChange={(e, item) => {
        setFieldValue(name, !isUndefined(path) ? get(item, path) : item);
      }}
      disabled={disabled}
      disableClearable={disableClearable}
      isOptionEqualToValue={(option, value) => {
        return isEqual(option, value) || isEqual(option?.value, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          error={error ?? Boolean(isTouched && errorMessage)}
          helperText={
            helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
          }
          sx={{ marginBottom: 1 }}
        />
      )}
      sx={{ marginTop: 1 }}
    />
  );
};
