import { AxiosResponse } from "axios";
import {
  IRoReviewRequest,
  IBaseReviewDTO,
  IRoReviewRequestForm,
  IVehicleReviewRequestDTO,
  IROReviewRequestDTO,
  IVehicleTipRequestDTO,
  IRepairOrderTipRequestDTO,
} from "../../index";
import { UserRole } from "../common/Enums";
import UserService from "./User.service";
import { get } from "lodash";
import { IReview } from "../models/review";
import Review from "../models/review/Review";
import Utils from "../util/Utils";
import Employee from "../models/user/Employee";

interface IReviewApiClient {
  addTip(data: any): Promise<AxiosResponse>;
  addReview(data: IBaseReviewDTO): Promise<AxiosResponse>;
  sendReviewRequest(dto: IRoReviewRequest): Promise<AxiosResponse>;
}

class ReviewApiClient implements IReviewApiClient {
  private readonly prefix = "review";

  addTip(data: any): Promise<AxiosResponse> {
    return window.apiClient.post("tip/stripe/create-checkout-session", data);
  }

  addReview(data: IBaseReviewDTO): Promise<AxiosResponse> {
    return window.apiClient.post("review", data);
  }

  sendReviewRequest(dto: IRoReviewRequest): Promise<AxiosResponse> {
    return window.apiClient.post(`${this.prefix}/send-review-request`, dto);
  }
}

export default class ReviewService {
  private userService: UserService;
  private reviewApiClient: IReviewApiClient;

  constructor(userService?: UserService) {
    this.reviewApiClient = new ReviewApiClient();
    this.userService = Utils.default(userService, new UserService());
  }

  public async addRepairOrderReview(
    data: IROReviewRequestDTO
  ): Promise<IReview> {
    const response = await this.reviewApiClient.addReview(data);

    return new Review(response.data);
  }

  public async addVehicleReview(
    data: IVehicleReviewRequestDTO
  ): Promise<IReview> {
    const response = await this.reviewApiClient.addReview(data);

    return new Review(response.data);
  }

  public async addRepairOrderTip(
    data: IRepairOrderTipRequestDTO
  ): Promise<AxiosResponse<{ code: number; redirectURL: string }>> {
    return this.reviewApiClient.addTip(data);
  }

  public async addVehicleTip(
    data: IVehicleTipRequestDTO
  ): Promise<AxiosResponse<{ code: number; redirectURL: string }>> {
    return this.reviewApiClient.addTip(data);
  }

  public async sendReviewRequest(
    values: IRoReviewRequestForm
  ): Promise<AxiosResponse> {
    if (values.customerMode === "new") {
      const response = await this.userService.createUser(
        new Employee({
          roles: [UserRole.CUSTOMER],
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
        })
      );

      values.customer = {
        value: response.id,
        label: response.firstName,
      };
    }

    const dto: IRoReviewRequest = {
      roName: get(values, "name"),
      comments: get(values, "comments"),
      customerId: get(values, "customer.value"),
      firstTechId: get(values, "technician.value"),
    };

    return this.reviewApiClient.sendReviewRequest(dto);
  }
}
