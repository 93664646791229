import React, { Fragment, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Avatar, Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridRenderCellParams,
  GridRowParams,
  GridFilterItem,
} from "@mui/x-data-grid";

import EditUserForm from "./EditUserForm";
import UserService from "../../services/User.service";
import PaperWrapper from "../paper/PaperWrapper";
import { IEmployee } from "../../models/user";
import { ITechnicianStatistic } from "../../models/statistics";
import { IStore } from "../../../index";
import ModuleHelper from "../../util/ModuleHelper";
import { defaultPageSize } from "../../util/constants";
import CustomHeader from "../custom_grid_header/CustomHeader";

interface IProps {
  userService: UserService;
}

const Users = (props: IProps): React.ReactElement => {
  const { t } = useTranslation();

  const editUserRef = useRef<any>();
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pending, setPending] = useState<boolean>(false);
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const [filter, setFilter] = useState<GridFilterItem[]>([]);
  const [showResetFilter, setShowResetFilter] = useState<boolean>(false);

  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  useEffect(() => {
    getData();
  }, [dealerContext]);

  const getData = (): void => {
    setPending(true);
    props.userService
      .getAllUsers()
      .then((response) => {
        setEmployees(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setPending(false));
  };

  const handleEdit = (item: GridRowParams<IEmployee>) => (event: any) => {
    event.stopPropagation();
    editUserRef.current.edit(item.row);
  };

  const handleResendInvite =
    (item: GridRowParams<IEmployee>) => (event: any) => {
      event.stopPropagation();

      ModuleHelper.confirmDialog.confirm(
        `Resend Invite?`,
        `You are about to Resend Invite to "${item.row.firstName} ${item.row.lastName}". Are you sure you want to do this?`,
        [
          { title: "Cancel", color: "info" },
          {
            title: "Yes",
            color: "error",
            onClick: () => {
              setPending(true);
              props.userService
                .resendInvite(item.row.id)
                .then(() => {
                  ModuleHelper.grow.success(t("user.invitedSuccessfully"));
                })
                .catch((error) => ModuleHelper.grow.showError(error))
                .finally(() => getData());
            },
          },
        ]
      );
    };

  const handleDeactivate = (item: GridRowParams<IEmployee>) => (event: any) => {
    event.stopPropagation();

    ModuleHelper.confirmDialog.confirm(
      `Deactivate User?`,
      `You are about to DEACTIVATE "${item.row.firstName} ${item.row.lastName}". Are you sure you want to do this?`,
      [
        { title: "Cancel", color: "info" },
        {
          title: "Yes",
          color: "error",
          onClick: () => {
            setPending(true);
            props.userService
              .updateUserStatus(item.row.id, false)
              .then(() => {
                ModuleHelper.grow.success(t("user.deactivatedSuccessfully"));
              })
              .catch((error) => ModuleHelper.grow.showError(error))
              .finally(() => getData());
          },
        },
      ]
    );
  };

  const getActions = (props: GridRowParams): React.ReactElement[] => {
    const value = props.row as IEmployee;

    const isDisabled = !isRowEditable(value);
    const isCustomer = ModuleHelper.isCustomerByRoles(props.row.roles);

    const options: React.ReactElement[] = [
      <GridActionsCellItem
        key={"edit"}
        label={"Edit"}
        title={"Edit"}
        icon={<EditIcon />}
        disabled={isDisabled}
        onClick={handleEdit(props)}
      />,
    ];

    if (!isCustomer) {
      const isEnabled = ModuleHelper.stringBool(props.row.enabled);

      if (isEnabled) {
        options.push(
          <GridActionsCellItem
            key={"deactivate"}
            label={"Deactivate"}
            title={"Deactivate"}
            color={"error"}
            disabled={isDisabled}
            icon={<PersonAddDisabledIcon />}
            onClick={handleDeactivate(props)}
          />
        );
      } else {
        options.push(
          <GridActionsCellItem
            key={"resend-invite"}
            label={"Resend Invite"}
            title={"Resend Invite"}
            color={"success"}
            disabled={isDisabled}
            icon={<SendIcon />}
            onClick={handleResendInvite(props)}
          />
        );
      }
    }

    return options;
  };

  const handleFilterChange = (e: any) => {
    setFilter(e.items);
    e.items[0].value ? setShowResetFilter(true) : setShowResetFilter(false);
  };

  const handleFilterReset = (e: any): void => {
    e.stopPropagation();
    setFilter([]);
    setShowResetFilter(false);
  };

  const columns: GridColumns = [
    {
      field: "avatarURL",
      headerName: "Avatar",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ITechnicianStatistic>) => {
        return (
          <Avatar sx={{ margin: "auto" }} src={`${params.value}`} alt={"..."} />
        );
      },
      headerAlign: "center",
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 130,
      flex: 1,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 130,
      flex: 1,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "mobileNumber",
      headerName: "Phone Number",
      minWidth: 130,
      flex: 1,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "roles",
      headerName: "Role",
      minWidth: 130,
      flex: 0.8,
      valueGetter: (params) => {
        const value = params.value;
        if (Array.isArray(value)) {
          return value[0];
        }
        return value;
      },
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 130,
      flex: 1.5,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={params.value}>
            <span className={"MuiDataGrid-cellTextOverflow"}>
              {params.value}
            </span>
          </Tooltip>
        );
      },
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "externalRefId",
      headerName: "Employee ID",
      minWidth: 130,
      flex: 0.7,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: getActions,
    },
  ];

  const handleAddEmployee = (): void => {
    editUserRef.current.add();
  };

  const inviteUser = (employee: IEmployee, avatarImage?: File) =>
    new Promise((resolve, reject) => {
      props.userService
        .createUser(employee, avatarImage)
        .then(() => {
          getData();
          resolve(true);
          ModuleHelper.grow.success(t("user.invitedSuccessfully"));
        })
        .catch((error) => {
          reject(false);
          ModuleHelper.grow.showError(error);
        });
    });

  const updateUser = (employee: IEmployee, avatarImage?: File): Promise<any> =>
    new Promise((resolve, reject) => {
      props.userService
        .updateUser(employee, avatarImage)
        .then(() => {
          getData();
          resolve(true);
          ModuleHelper.grow.success(t("user.updatedSuccessfully"));
        })
        .catch((error) => {
          reject(false);
          ModuleHelper.grow.showError(error);
        });
    });

  const handleSubmitUser = (
    employee: IEmployee,
    avatarImage?: File
  ): Promise<any> => {
    if (employee.id !== 0) {
      return updateUser(employee, avatarImage);
    }

    return inviteUser(employee, avatarImage);
  };

  const isRowEditable = (value: IEmployee): boolean => {
    if (value.id === user.id) {
      return false;
    }

    if (isEmpty(value.roles)) {
      return false;
    }

    const myRoleRank = ModuleHelper.getMyRoleRank();
    const userRoleRank = ModuleHelper.getRoleRank(value.roles);

    return myRoleRank >= userRoleRank;
  };

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper
        header={"USERS"}
        rightHeader={
          <Fragment>
            <Button
              variant={"contained"}
              startIcon={<FilterAltOffIcon />}
              onClick={handleFilterReset}
              style={{
                display: showResetFilter ? "inline-block" : "none",
                marginRight: "1rem",
              }}
            >
              Reset
            </Button>
            <Button
              variant={"contained"}
              onClick={handleAddEmployee}
              startIcon={<PersonAddAltIcon />}
            >
              Add User
            </Button>
          </Fragment>
        }
      >
        <DataGrid
          editMode={"row"}
          rows={employees}
          columns={columns}
          loading={pending}
          autoHeight={true}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          onFilterModelChange={handleFilterChange}
          filterModel={{ items: filter }}
          isCellEditable={(params: GridCellParams<IEmployee>) =>
            isRowEditable(params.row)
          }
        />

        <EditUserForm ref={editUserRef} onSubmit={handleSubmitUser} />
      </PaperWrapper>
    );
  };

  return render();
};

export default Users;
