import { IPartDTO } from "../../../index";
import Utils from "../../util/Utils";
import { IPart } from "./index";

export default class Part implements IPart {
  private _id: number;
  private _number: string;
  private _quantity: number;
  private _description: string;

  constructor(args?: IPartDTO) {
    this._id = Utils.default(args?.id, 0);
    this._number = Utils.default(args?.number, "");
    this._quantity = Utils.default(args?.quantity, 0);
    this._description = Utils.default(args?.description, "");
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get number(): string {
    return this._number;
  }

  set number(value: string) {
    this._number = value;
  }

  get quantity(): number {
    return this._quantity;
  }

  set quantity(value: number) {
    this._quantity = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }
}
