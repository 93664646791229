import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import RestartAlt from "@mui/icons-material/RestartAlt";
import DealershipService from "../../services/Dealership.service";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useGlobalStyles } from "../../util/ThemePalette";
import ModuleHelper from "../../util/ModuleHelper";
import { get } from "lodash";

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  width: "100%",
});

interface IData {
  advisorPassword: string;
  advisorUsername: string;
  customerClosedRoLink: string;
  customerOpenRoLink: string;
  dealerName: string;
  linkedClosedRoNum: string;
  linkedOpenRoNum: string;
  techPassword: string;
  techUsername: string;
}

const ResetDemoDealer = (): React.ReactElement => {
  const classes = useGlobalStyles();
  const dealershipService = new DealershipService();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IData>();

  const reset = (): void => {
    setLoading(true);
    dealershipService
      .demoDealerReset()
      .then((response) => {
        setOpen(true);
        setData(response.data);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const render = (): React.ReactElement => {
    const dealerName = get(data, "dealerName", "");
    const techPassword = get(data, "techPassword", "");
    const techUsername = get(data, "techUsername", "");
    const advisorPassword = get(data, "advisorPassword", "");
    const advisorUsername = get(data, "advisorUsername", "");
    const linkedOpenRoNum = get(data, "linkedOpenRoNum", "");
    const linkedClosedRoNum = get(data, "linkedClosedRoNum", "");
    const customerOpenRoLink = get(data, "customerOpenRoLink", "");
    const customerClosedRoLink = get(data, "customerClosedRoLink", "");

    return (
      <React.Fragment>
        <MenuItem sx={{ padding: 0 }}>
          <SubmitButton
            startIcon={<RestartAlt />}
            loading={loading}
            variant="contained"
            size={"medium"}
            disableRipple
            color={"error"}
            onClick={reset}
            className={classes.saveButton}
          >
            Reset Demo Dealer
          </SubmitButton>
        </MenuItem>

        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>Dealership Details</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <Grid container={true}>
              <Grid item xs={6}>
                Dealership Name
              </Grid>
              <Grid item xs={6}>
                {dealerName}
              </Grid>

              <Grid item xs={6}>
                Advisor Username
              </Grid>
              <Grid item xs={6}>
                {advisorUsername}
              </Grid>

              <Grid item xs={6}>
                Advisor Password
              </Grid>
              <Grid item xs={6}>
                {advisorPassword}
              </Grid>

              <Grid item xs={6}>
                Technician Username
              </Grid>
              <Grid item xs={6}>
                {techUsername}
              </Grid>

              <Grid item xs={6}>
                Technician Password
              </Grid>
              <Grid item xs={6}>
                {techPassword}
              </Grid>

              <Grid item xs={6}>
                Opened Repair Order Num
              </Grid>
              <Grid item xs={6}>
                {linkedOpenRoNum}
              </Grid>

              <Grid item xs={6}>
                Closed Repair Order Num
              </Grid>
              <Grid item xs={6}>
                {linkedClosedRoNum}
              </Grid>

              <Grid item xs={6}>
                Customer Open RO Link
              </Grid>
              <Grid item xs={6}>
                <a href={customerOpenRoLink} target={"_blank"} rel="noreferrer">
                  {customerOpenRoLink}
                </a>
              </Grid>

              <Grid item xs={6}>
                Customer Closed RO Link
              </Grid>
              <Grid item xs={6}>
                <a
                  href={customerClosedRoLink}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {customerClosedRoLink}
                </a>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  };

  return render();
};

export default ResetDemoDealer;
