import React, { useState } from "react";
import { IRepairOrder } from "../../../models/repair_order";
import ReviewView from "../../review/ReviewView";
import { IReview } from "../../../models/review";
import ReviewService from "../../../services/Review.service";
import TipService from "../../../services/Tip.service";

interface IProps {
  repairOrder: IRepairOrder;
}

const tipService = new TipService();
const reviewService = new ReviewService();

const RepairOrderReview = (props: IProps): React.ReactElement => {
  const [review, setReview] = useState<IReview>(props.repairOrder.review);

  const onChange = (review: IReview): void => {
    setReview(review);
  };

  const onSubmit = (): Promise<IReview> =>
    reviewService.addRepairOrderReview({
      text: review.text,
      score: review.score,
      relatedToRoId: props.repairOrder.id,
    });

  const handleTipSubmit = (args: any): Promise<any> =>
    tipService.addRepairOrderTip({
      amount: args.amount,
      cancelURL: args.cancelURL,
      successURL: args.successURL,
      roId: props.repairOrder.id,
      tipWhoReceiveList: [props.repairOrder.getTechnician().id],
    });

  const render = (): React.ReactElement => {
    const { repairOrder } = props;

    return (
      <ReviewView
        review={review}
        tip={repairOrder.tip}
        updated={repairOrder.updated}
        vehicle={repairOrder.vehicle}
        employees={repairOrder.getEmployees()}
        mainEmployee={repairOrder.getTechnician()}
        onSubmit={onSubmit}
        onChange={onChange}
        onTipSubmit={handleTipSubmit}
      />
    );
  };

  return render();
};

export default RepairOrderReview;
