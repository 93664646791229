import MenuBuilder from "./MenuBuilder";
import route, { IPage } from "../../common/route";

export default class UserManagerMenuBuilder extends MenuBuilder {
  build(): IPage[] {
    const pages: IPage[] = [];

    if (this.hasRouteAccess(route.dealership.pages.users)) {
      pages.push(route.dealership.pages.users);
    }

    return pages;
  }
}
