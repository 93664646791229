import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Container, Typography, Grid, Card } from "@mui/material";
import { ParsedQuery } from "query-string";
import { isEmpty } from "lodash";

import { Formik, FormikHelpers, Form, Field } from "formik";
import { FormTextField } from "../../../components/shared/form_text_field/FormTextField";

import LoginService from "../../../services/Login.service";
import ModuleHelper from "../../../util/ModuleHelper";
import { loginSuccess } from "../../../reducers/auth";
// import { updateDealershipConfig } from "../../../reducers/main";
import Logo from "../../../components/logo/Logo";
import route from "../../../common/route";
import { QueryParams } from "../../../common/Enums";
import { IContextUser } from "../../../models/user";
import VisibleContent from "../../../components/visable_content/VisibleContent";
import { useStyles } from "./Login.styles";
// import DealershipService from "../../../services/Dealership.service";
// import { DealershipSettings } from "../../../common/Enums";

interface FormValues {
  name: string;
  password: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  width: "100%",

  padding: "14px 12px",

  lineHeight: 1.5,
  borderRadius: "14px",
  backgroundColor: "#F3F2F4",
  color: "#110F11",

  "&:hover": {
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const loginService = new LoginService();
const queryString = require("query-string");

const Login = (): any => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [pending, setPending] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ModuleHelper.loader.show();
    const params = queryString.parse(history.location.search);

    const smsToken = params[QueryParams.token];

    if (isEmpty(smsToken)) {
      loginService.myself().then(handleLogin).catch(handleLoginError);
    } else {
      loginService
        .smsLogin(smsToken)
        .then((response) => {
          handleLogin(response);
          smsLoginRouter(params);
        })
        .catch(handleLoginError);
    }
  }, []);

  const smsLoginRouter = (params: ParsedQuery): void => {
    const notificationId = params[QueryParams.notificationId];

    if (!isEmpty(notificationId)) {
      history.push(
        `${route.repairOrder.pathname}?${[
          QueryParams.notificationId,
        ]}=${notificationId}`
      );
      return;
    }

    const vehicleId = params[QueryParams.vehicleId];

    if (!isEmpty(vehicleId)) {
      history.push(
        `${route.vehicle.pathname}?${[QueryParams.vehicleId]}=${vehicleId}`
      );
    }
  };

  const handleLogin = (user: IContextUser) => {
    setPending(false);
    ModuleHelper.loader.hide();
    dispatch(loginSuccess(user));

    // const dealershipConfig = getDealershipConfig();
    // dispatch(updateDealershipConfig(dealershipConfig));
  };

  // const getDealershipConfig = async () => {
  //   const dealershipService: DealershipService = new DealershipService();
  //   const configResponse = await dealershipService.getDealershipCustomConfig([
  //     DealershipSettings.TIP_WHO_RECEIVE,
  //   ]);
  //   return configResponse;
  // };

  const handleLoginError = () => {
    setPending(false);
    ModuleHelper.loader.hide();
    history.push(route.login.pathname);
  };

  const onSubmit = (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ): void => {
    setLoading(true);
    loginService
      .login(values.name, values.password)
      .then((response) => {
        setLoading(false);
        dispatch(loginSuccess(response));

        // const dealershipConfig = getDealershipConfig();
        // dispatch(updateDealershipConfig(dealershipConfig));
      })
      .catch((error) => {
        setLoading(false);
        ModuleHelper.grow.showError(error);
      });
    formikHelpers.setSubmitting(false);
  };

  const formRender = (): React.ReactElement => {
    return (
      <Form noValidate autoComplete="on">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              name="name"
              label="Username / Phone"
              size="small"
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="password"
              label="Password"
              size="small"
              type="password"
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              size={"large"}
              disableRipple
              color="primary"
              className={classes.verifyButton}
            >
              Sign In
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const render = (): any => {
    return (
      <VisibleContent visible={!pending}>
        <Container
          component="main"
          maxWidth="xs"
          className={classes.loginWrapper}
        >
          <Card className={classes.card}>
            <Logo className={classes.logo} />
            <Typography component="h2" variant="h5">
              <b>Welcome</b>
            </Typography>
            <Typography variant="body1">Repair Service Orders</Typography>
            <Formik
              initialValues={{
                name: "",
                password: "",
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {formRender}
            </Formik>
          </Card>
        </Container>
      </VisibleContent>
    );
  };

  return render();
};

export default Login;
