import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useGlobalStyles } from "../../util/ThemePalette";
import SendIcon from "@mui/icons-material/Send";
import {
  IEmployeeDTO,
  IRoReviewRequestForm,
  ISelectOption,
  IVehicleReviewRequestForm,
} from "../../../index";
import UserService from "../../services/User.service";
import ModuleHelper from "../../util/ModuleHelper";
import { UserRole } from "../../common/Enums";
import { Field, Form, Formik, FormikProps } from "formik";
import Utils from "../../util/Utils";
import { FormTextField } from "../shared/form_text_field/FormTextField";
import i18n from "i18next";
import { isNull, isEmpty } from "lodash";
import { FormSelectField } from "../shared/form_select_field/FormSelectField";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import ReviewService from "../../services/Review.service";
import FormPhoneField from "../shared/form_phone_field/FormPhoneField";
import { ValidationError } from "yup";
import Validation from "../../util/Validation";
import VisibleContent from "../visable_content/VisibleContent";
import VehicleService from "../../services/Vehicle.service";
import CloseIcon from "@mui/icons-material/Close";

const Yup = Utils.validation.Yup;

const roValidationSchema = Yup.object().shape({
  technician: Yup.object()
    .required(i18n.t("validation.required"))
    .typeError(i18n.t("validation.required")),

  customerMode: Yup.string().test(function (value: any) {
    return customerValidation(this, value);
  }),
});

const vehicleValidationSchema = Yup.object().shape({
  salesperson: Yup.object()
    .required(i18n.t("validation.required"))
    .typeError(i18n.t("validation.required")),

  customerMode: Yup.string().test(function (value: any) {
    return customerValidation(this, value);
  }),
});

function customerValidation(args: any, value: any) {
  const { parent, createError } = args;
  const { firstName, lastName, mobileNumber, customer } = parent;

  if (value === "existing") {
    if (isNull(customer)) {
      return createError({
        path: "customer",
        message: i18n.t("validation.required"),
      });
    }
    return true;
  }

  const errors: any = [];

  if (isEmpty(firstName)) {
    errors.push(
      new ValidationError(i18n.t("validation.required"), firstName, "firstName")
    );
  }

  if (isEmpty(lastName)) {
    errors.push(
      new ValidationError(i18n.t("validation.required"), lastName, "lastName")
    );
  }

  if (
    isEmpty(mobileNumber) ||
    mobileNumber.length < 10 ||
    !Validation.isValidPhoneNumber(mobileNumber)
  ) {
    errors.push(
      new ValidationError(
        i18n.t("validation.invalidPhoneNumber"),
        mobileNumber,
        "mobileNumber"
      )
    );
  }

  if (!isEmpty(errors)) {
    return createError({
      message: () => errors,
    });
  }

  return true;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  width: "100%",
});

const userService = new UserService();
const reviewService = new ReviewService(userService);
const vehicleService = new VehicleService(userService);

const SendReviewRequest = (): React.ReactElement | null => {
  const classes = useGlobalStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<"repair_order" | "vehicle">(
    "repair_order"
  );

  const [customers, setCustomers] = useState<ISelectOption[]>([]);
  const [technicians, setTechnicians] = useState<ISelectOption[]>([]);
  const [salespersons, setSalespersons] = useState<ISelectOption[]>([]);
  const [employeePending, setEmployeePending] = useState<boolean>(false);

  const [roForm] = useState<IRoReviewRequestForm>({
    name: "",
    comments: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    advisor: null,
    customer: null,
    technician: null,
    technicians: [],
    customerMode: "existing",
  });

  const [vehicleForm] = useState<IVehicleReviewRequestForm>({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    customer: null,
    salesperson: null,
    customerMode: "existing",
    vehicle: {
      model: "",
      color: "",
      manufacturer: "",
    },
  });

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOpen = (): void => {
    setOpen(true);

    getEmployees();
  };

  const optionMapper = (item: IEmployeeDTO): ISelectOption => {
    return { value: item.id, label: `${item.firstName} ${item.lastName}` };
  };

  const getEmployees = (): void => {
    setEmployeePending(true);
    userService
      .getUsersByRoles()
      .then((response) => {
        setCustomers(response[UserRole.CUSTOMER].map(optionMapper));
        setTechnicians(response[UserRole.TECHNICIAN].map(optionMapper));
        setSalespersons(response[UserRole.SALESPERSON].map(optionMapper));
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setEmployeePending(false));
  };

  const roFormRender = (
    formikProps: FormikProps<IRoReviewRequestForm>
  ): React.ReactElement => {
    const customerMode = formikProps.values.customerMode;

    return (
      <Form noValidate autoComplete="on">
        <Card sx={{ marginBottom: 1 }}>
          <CardContent>
            <FormControl>
              <FormLabel>Details</FormLabel>
            </FormControl>
            <Field
              name="name"
              size="small"
              label="RO Number"
              component={FormTextField}
            />
            <Field
              name="technician"
              size="small"
              label="Technician"
              options={technicians}
              required={true}
              disableClearable={true}
              loading={employeePending}
              component={FormSelectField}
            />
            <Field
              name="comments"
              size="small"
              label="Comments"
              multiline={true}
              component={FormTextField}
            />
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: 1 }}>
          <CardContent>
            <FormControl>
              <FormLabel>Customer</FormLabel>
              <RadioGroup
                row={true}
                value={customerMode}
                onChange={(e) =>
                  formikProps.setFieldValue("customerMode", e.target.value)
                }
              >
                <FormControlLabel
                  value="existing"
                  label="Existing"
                  control={<Radio />}
                />
                <FormControlLabel value="new" label="New" control={<Radio />} />
              </RadioGroup>
            </FormControl>

            {customerMode === "existing" ? (
              <Field
                name="customer"
                size="small"
                label="Customer"
                options={customers}
                disableClearable={false}
                required={true}
                loading={employeePending}
                component={FormSelectField}
              />
            ) : (
              <React.Fragment>
                <Field
                  name="firstName"
                  size="small"
                  label="First Name"
                  required={true}
                  component={FormTextField}
                />

                <Field
                  name="lastName"
                  size="small"
                  label="Last Name"
                  required={true}
                  component={FormTextField}
                />

                <Field
                  name="mobileNumber"
                  size="small"
                  label="Mobile Number"
                  required={true}
                  component={FormPhoneField}
                />
              </React.Fragment>
            )}
          </CardContent>
        </Card>

        <SubmitButton
          startIcon={<SendIcon />}
          loading={loading}
          type="submit"
          variant="contained"
          size={"medium"}
          className={classes.saveButton}
        >
          Send
        </SubmitButton>
      </Form>
    );
  };

  const vehicleFormRender = (
    formikProps: FormikProps<IVehicleReviewRequestForm>
  ): React.ReactElement => {
    const customerMode = formikProps.values.customerMode;

    return (
      <Form noValidate autoComplete="on">
        <Card sx={{ marginBottom: 1 }}>
          <CardContent>
            <FormControl>
              <FormLabel>Vehicle</FormLabel>
            </FormControl>

            <Field
              name="vehicle.manufacturer"
              size="small"
              label="Manufacturer"
              component={FormTextField}
            />

            <Field
              name="vehicle.model"
              size="small"
              label="Model"
              component={FormTextField}
            />

            <Field
              name="vehicle.color"
              size="small"
              label="Color"
              component={FormTextField}
            />
          </CardContent>
        </Card>

        <Card sx={{ marginBottom: 1 }}>
          <CardContent>
            <FormControl>
              <FormLabel>Salesperson</FormLabel>
            </FormControl>

            <Field
              name="salesperson"
              size="small"
              label="Sales Person"
              options={salespersons}
              disableClearable={false}
              required={true}
              loading={employeePending}
              component={FormSelectField}
            />
          </CardContent>
        </Card>
        <Card sx={{ marginBottom: 1 }}>
          <CardContent>
            <FormControl>
              <FormLabel>Customer</FormLabel>
              <RadioGroup
                row={true}
                value={customerMode}
                onChange={(e) =>
                  formikProps.setFieldValue("customerMode", e.target.value)
                }
              >
                <FormControlLabel
                  value="existing"
                  label="Existing"
                  control={<Radio />}
                />
                <FormControlLabel value="new" label="New" control={<Radio />} />
              </RadioGroup>
            </FormControl>

            {customerMode === "existing" ? (
              <Field
                name="customer"
                size="small"
                label="Customer"
                options={customers}
                disableClearable={false}
                required={true}
                loading={employeePending}
                component={FormSelectField}
              />
            ) : (
              <React.Fragment>
                <Field
                  name="firstName"
                  size="small"
                  label="First Name"
                  required={true}
                  component={FormTextField}
                />

                <Field
                  name="lastName"
                  size="small"
                  label="Last Name"
                  required={true}
                  component={FormTextField}
                />

                <Field
                  name="mobileNumber"
                  size="small"
                  label="Mobile Number"
                  required={true}
                  component={FormPhoneField}
                />
              </React.Fragment>
            )}
          </CardContent>
        </Card>

        <SubmitButton
          startIcon={<SendIcon />}
          loading={loading}
          type="submit"
          variant="contained"
          size={"medium"}
          className={classes.saveButton}
        >
          Send
        </SubmitButton>
      </Form>
    );
  };

  const onRoSubmit = async (values: IRoReviewRequestForm) => {
    setLoading(true);
    reviewService
      .sendReviewRequest(values)
      .then(() => {
        handleClose();
        ModuleHelper.grow.success(
          i18n.t("dealership.reviewRequestSentSuccessfully")
        );
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const onVehicleSubmit = async (values: IVehicleReviewRequestForm) => {
    setLoading(true);
    vehicleService
      .sendReviewRequest(values)
      .then(() => {
        handleClose();
        ModuleHelper.grow.success(
          i18n.t("dealership.reviewRequestSentSuccessfully")
        );
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const render = (): React.ReactElement | null => {
    return (
      <React.Fragment>
        <MenuItem sx={{ padding: 0 }}>
          <Button
            variant={"contained"}
            startIcon={<SendIcon />}
            onClick={handleOpen}
          >
            Send Review Request
          </Button>
        </MenuItem>

        <Dialog onClose={handleClose} open={open}>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontSize={18} textAlign={"center"}>
              Send Review Request
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: 2, width: 400 }}>
            <FormControl>
              <RadioGroup
                row={true}
                value={formType}
                onChange={(e: any) => setFormType(e.target.value)}
              >
                <FormControlLabel
                  value="repair_order"
                  label="Repair Order"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="vehicle"
                  label="Vehicle"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
            <VisibleContent visible={formType === "repair_order"}>
              <Formik
                onSubmit={onRoSubmit}
                initialValues={roForm}
                validationSchema={roValidationSchema}
              >
                {roFormRender}
              </Formik>
            </VisibleContent>

            <VisibleContent visible={formType === "vehicle"}>
              <Formik
                onSubmit={onVehicleSubmit}
                initialValues={vehicleForm}
                validationSchema={vehicleValidationSchema}
              >
                {vehicleFormRender}
              </Formik>
            </VisibleContent>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  };

  return render();
};

export default SendReviewRequest;
