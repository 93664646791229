import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { format, parseISO } from "date-fns";
import { OrderList } from "../order_list/OrderList";
import { get, isEmpty, defaultTo } from "lodash";
import {
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { IRepairOrder } from "../../models/repair_order";

interface IProps {
  readonly repairOrder: IRepairOrder;
}

export const OrderDetails = (props: IProps): JSX.Element => {
  const [showDetails, setShowDetails] = useState<boolean>(false);

  const render = (): React.ReactElement => {
    const repairOrder = props.repairOrder;

    const lineItems = repairOrder.lineItems;
    const estimateCompletionAt = defaultTo(
      repairOrder.estimateCompletionAt,
      ""
    );
    const statusCodeDesc = get(repairOrder, "statusCode.initDesc");

    return (
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "grey.100" }}>
            {!isEmpty(lineItems) &&
              `EST COMPLETION: ${format(
                parseISO(estimateCompletionAt),
                "MMM dd  @ hh:mm a"
              )}`}
          </Typography>
          <Typography variant="h2">{statusCodeDesc}</Typography>
        </Grid>

        <Grid item xs={12}>
          {!isEmpty(lineItems) && (
            <ListItemButton onClick={() => setShowDetails(!showDetails)}>
              <ListItemIcon sx={{ minWidth: "unset", marginRight: 1 }}>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontWeight: "bold" }} fontSize={"large"}>
                  Repair Order {repairOrder.roNum}
                </Typography>
              </ListItemText>
              {showDetails ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          )}

          <Collapse in={showDetails} sx={{ marginTop: 1 }}>
            <OrderList
              repairOrder={props.repairOrder}
              style={{ marginBottom: 15 }}
            />
          </Collapse>
        </Grid>
      </Grid>
    );
  };

  return render();
};
