import React from "react";
import Users from "../../components/employee/Users";
import UserService from "../../services/User.service";

const UsersView = (): React.ReactElement => {
  const userService = new UserService();

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Users userService={userService} />
      </React.Fragment>
    );
  };

  return render();
};

export default UsersView;
