import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Button } from "@mui/material";
import DealershipHeader from "../dealership/DealershipHeader";
import { IStore } from "../../../index";
import { isEmpty } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import DealershipService from "../../services/Dealership.service";
import { DealershipSettings } from "../../common/Enums";
import PaperWrapper from "../paper/PaperWrapper";
import VisibleContent from "../visable_content/VisibleContent";
import { IDealershipContext } from "../../models/dealership";
import ModuleHelper from "../../util/ModuleHelper";

const ThankYou = (): JSX.Element => {
  const dealershipService = new DealershipService();

  const dealerContext: IDealershipContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  const [pending, setPending] = useState<boolean>(true);
  const [thankYouMessage, setThankYouMessage] = useState<string>("");

  useEffect(() => {
    if (isEmpty(dealerContext.googlePlaceId)) {
      setPending(true);
      ModuleHelper.loader.show();
      dealershipService
        .getDealershipCustomConfig([
          DealershipSettings.THANK_YOU_SCREEN_MESSAGE,
        ])
        .then((response) => {
          setThankYouMessage(response.thankYouMessage);
        })
        .finally(() => {
          setPending(false);
          ModuleHelper.loader.hide();
        });
    } else {
      setPending(false);
    }
  }, [dealerContext]);

  const onClick = (googlePlaceId: string) => (event: any) => {
    event.stopPropagation();

    window.open(
      `https://search.google.com/local/writereview?placeid=${googlePlaceId}`
    );
  };

  const render = (): React.ReactElement => {
    return (
      <VisibleContent visible={!pending}>
        <Grid
          spacing={2}
          container
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xl={6} lg={7} md={8} xs={12}>
            <PaperWrapper sx={{ padding: 1 }}>
              <DealershipHeader />

              {isEmpty(dealerContext.googlePlaceId) ? (
                <Typography
                  sx={{ marginBottom: 1 }}
                  textAlign={"center"}
                  fontSize={18}
                >
                  {thankYouMessage}
                </Typography>
              ) : (
                <React.Fragment>
                  <Typography
                    sx={{ marginBottom: 1 }}
                    textAlign={"center"}
                    fontSize={18}
                  >
                    If you have an extra minute, please review us on Google
                    Reviews
                  </Typography>

                  <Button
                    color="success"
                    fullWidth={true}
                    variant="contained"
                    onClick={onClick(`${dealerContext.googlePlaceId}`)}
                  >
                    Leave a Google Review
                  </Button>
                </React.Fragment>
              )}
            </PaperWrapper>
          </Grid>
        </Grid>
      </VisibleContent>
    );
  };

  return render();
};

export default ThankYou;
