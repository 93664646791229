import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DealershipHeader from "../dealership/DealershipHeader";
import { defaultTo, get, isEmpty } from "lodash";
import { EmployeeScoreDetails } from "../repair_order/technician/EmployeeScoreDetails";
import Rating from "@mui/material/Rating";
import VisibleContent from "../visable_content/VisibleContent";
import TipModal from "../repair_order/tips/TipModal";
import { IDealershipConfig } from "../../models/dealership_config";
import DealershipConfig from "../../models/dealership_config/DealershipConfig";
import { ITip } from "../../models/tip";
import { IBaseUser } from "../../models/user";
import { IReview } from "../../models/review";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useGlobalStyles } from "../../util/ThemePalette";
import Review from "../../models/review/Review";
import DealershipService from "../../services/Dealership.service";
import { DealershipSettings } from "../../common/Enums";
import ModuleHelper from "../../util/ModuleHelper";
import Tip from "../../models/tip/Tip";
import { ITipArgs } from "../../../index";
import LazyContent from "../lazy/LazyContent";
import ContentLoader from "react-content-loader";
import route from "../../common/route";
import { useHistory } from "react-router-dom";

interface IProps {
  readonly tip: ITip;
  readonly review: IReview;
  readonly vehicle: IVehicle;
  readonly mainEmployee: IBaseUser;
  readonly updated: string;

  readonly employees?: IBaseUser[];

  onChange(review: IReview): void;

  onSubmit(): Promise<IReview>;

  onTipSubmit(args: ITipArgs): Promise<any>;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",

  height: "56px",
  lineHeight: 1.5,
});

const dealershipService = new DealershipService();

const ReviewView = (props: IProps): React.ReactElement => {
  const tipRef = useRef<any>();
  const globalClasses = useGlobalStyles();
  const history = useHistory();

  const [pending, setPending] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [config, setConfig] = useState<IDealershipConfig>(
    new DealershipConfig()
  );

  const [tip, setTip] = useState<ITip>(defaultTo(props.tip, new Tip()));

  const [review, setReview] = useState<IReview>(
    defaultTo(props.review, new Review())
  );

  useEffect(() => {
    setTip(new Tip(props.tip));
  }, [props.tip]);

  useEffect(() => {
    setReview(new Review(props.review));
  }, [props.review]);

  useEffect(() => {
    setPending(true);
    dealershipService
      .getDealershipCustomConfig([
        DealershipSettings.LEAVE_A_TIP_SUBMIT_BUTTON,
        DealershipSettings.LEAVE_A_TIP_CANCEL_BUTTON,
        DealershipSettings.TIP_TITLE_MESSAGE,
        DealershipSettings.RATE_TITLE_MESSAGE,
        DealershipSettings.TIP_MAX_AMOUNT_IN_CENTS,
        DealershipSettings.TIP_MIN_AMOUNT_IN_CENTS,
        DealershipSettings.TIP_INCREMENTATION_IN_CENTS,
        DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP,
        DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN,
        DealershipSettings.TIP_WHO_RECEIVE,
      ])
      .then((response) => {
        setConfig(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => {
        setPending(false);
      });
  }, []);

  const showTip = (): void => tipRef.current?.show();

  const handleRating = (event: any, value: number | null) => {
    event.stopPropagation();

    const r = new Review({
      id: review.id,
      text: review.text,
      createdAt: review.createdAt,
      score: defaultTo(value, 0),
    });

    setReview(r);
    props.onChange(r);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const r = new Review({
      id: review.id,
      text: get(event, "target.value", ""),
      score: review.score,
      createdAt: review.createdAt,
    });

    setReview(r);
    props.onChange(r);
  };

  const handleReview = (): void => {
    setLoading(true);
    props
      .onSubmit()
      .then((response) => {
        setReview(response);

        if (isTipAllowed()) {
          showTip();
        } else {
          history.push(route.thankYou.pathname);
        }
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const isTipScoreReached = (): boolean => {
    return review.score >= config.askCustomerToLeaveTipAfterScore;
  };

  const isTipAllowed = (): boolean => {
    if (isEmpty(config)) {
      return false;
    }

    if (!config.askCustomerToLeaveTip) {
      return false;
    }

    const hasTip = Boolean(tip.id);

    if (hasTip) {
      return false;
    }

    if (props.mainEmployee.id === 0) {
      return false;
    }

    return isTipScoreReached();
  };

  const tipButtonEnabled = (hasReview: boolean): boolean => {
    return Boolean(isTipAllowed() && hasReview);
  };

  const render = (): React.ReactElement => {
    const { vehicle, mainEmployee, employees, updated } = props;

    const hasReview = Boolean(review.id);
    const showTextReview = !isTipScoreReached();
    const showTipButton = tipButtonEnabled(hasReview);
    const textReviewDisabled = Boolean(hasReview || review.score === 0);

    return (
      <LazyContent
        pending={pending}
        visible={!pending}
        loader={<ContentLoader />}
      >
        <React.Fragment>
          <DealershipHeader employees={employees} />
          <React.Fragment>
            <Typography variant="h2">{config.rateTitleMessage}</Typography>
            <Typography>{moment(updated).format("MMM DD, YYYY")}</Typography>
          </React.Fragment>

          <Grid item container>
            <Grid item xs={12}>
              <EmployeeScoreDetails employee={mainEmployee} />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Rating
              value={review.score}
              size={"large"}
              onChange={handleRating}
              style={{ marginTop: 20, marginBottom: 5 }}
              readOnly={hasReview}
            />

            <VisibleContent visible={showTextReview}>
              <TextField
                rows={5}
                multiline={true}
                placeholder={"Add a comment"}
                value={review.text}
                onChange={handleChange}
                InputProps={{
                  readOnly: textReviewDisabled,
                  disabled: textReviewDisabled,
                }}
              />
            </VisibleContent>

            <VisibleContent visible={!hasReview}>
              <SubmitButton
                loading={loading}
                variant="contained"
                size={"medium"}
                disableRipple
                color={"success"}
                onClick={handleReview}
                disabled={review.score === 0}
                sx={{ marginTop: 1 }}
                className={globalClasses.saveButton}
              >
                SUBMIT RATING
              </SubmitButton>
            </VisibleContent>

            <VisibleContent visible={showTipButton}>
              <Button
                variant="contained"
                fullWidth
                onClick={showTip}
                sx={{
                  mr: 1,
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "56px",
                  color: "#ffffff",
                  backgroundColor: "black",
                  marginTop: 2,
                  textTransform: "none",
                  columnGap: "10%",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                LEAVE A TIP
              </Button>
            </VisibleContent>
          </Box>

          <TipModal
            ref={tipRef}
            config={config}
            vehicle={vehicle}
            employee={mainEmployee}
            onSubmit={props.onTipSubmit}
          />
        </React.Fragment>
      </LazyContent>
    );
  };

  return render();
};

export default ReviewView;
