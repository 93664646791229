import { IBaseUser } from "../user";
import { ISellerStatisticsDTO } from "../../../index";
import Utils from "../../util/Utils";
import ModuleHelper from "../../util/ModuleHelper";
import { ISellerStatistic } from "./index";
import Sales from "../user/Sales";

export default class SellerStatistic implements ISellerStatistic {
  private _averageScore: number;
  private _employee: IBaseUser;
  private _id: number;
  private _totalTipAmount: number;

  constructor(args?: ISellerStatisticsDTO) {
    this._employee = new Sales(args?.employee);
    this._id = Utils.default(args?.employee.id, 0);
    this._averageScore = ModuleHelper.round(
      Utils.default(args?.averageScore, 0)
    );
    this._totalTipAmount = ModuleHelper.centToDollar(
      Utils.default(args?.totalTipAmount, 0)
    );
  }

  get averageScore(): number {
    return this._averageScore;
  }

  set averageScore(value: number) {
    this._averageScore = value;
  }

  get employee(): IBaseUser {
    return this._employee;
  }

  set employee(value: IBaseUser) {
    this._employee = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get totalTipAmount(): number {
    return this._totalTipAmount;
  }

  set totalTipAmount(value: number) {
    this._totalTipAmount = value;
  }
}
