import React, { useEffect, useState } from "react";
import { useGlobalStyles } from "../../../util/ThemePalette";
import { IStore } from "../../../../index";
import { shallowEqual, useSelector } from "react-redux";
import ModuleHelper from "../../../util/ModuleHelper";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import {
  DataGrid,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  Avatar,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import PaperWrapper from "../../paper/PaperWrapper";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TipService from "../../../services/Tip.service";
import { ITipStatistic } from "../../../models/tip";
import VisibleContent from "../../visable_content/VisibleContent";
import { StringBool } from "../../../types/types";

interface IProps {
  tipService: TipService;
}

const TipStatistics = (props: IProps): React.ReactElement => {
  const classes = useGlobalStyles();

  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState(100);
  const [pending, setPending] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<ITipStatistic[]>([]);

  const [filter, setFilter] = useState<StringBool>("n_2fa");

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  useEffect(() => {
    getData(0, pageSize, filter).finally(() => setPage(0));
  }, [dealerContext, filter]);

  const getData = (page: number, limit: number, filter: StringBool) =>
    new Promise((resolve, reject) => {
      setPending(true);
      props.tipService
        .getStatistics({
          page: page,
          perPage: limit,
          transferredToEmployees: filter === "n_2fa" ? undefined : filter,
        })
        .then((response) => {
          if (response.length === limit) {
            setRowCount(Math.max(1, page) * limit + 1);
          }

          setStatistics(response);

          resolve(response);
        })
        .catch((error) => {
          ModuleHelper.grow.showError(error);

          reject();
        })
        .finally(() => setPending(false));
    });

  const moneyCellRender = (
    params: GridRenderCellParams
  ): React.ReactElement => (
    <React.Fragment>
      <AttachMoneyIcon sx={{ fontSize: 15 }} color={"primary"} />
      <Typography>{params.value}</Typography>
    </React.Fragment>
  );

  const usernameCellRender = (
    params: GridRenderCellParams<ITipStatistic>
  ): React.ReactElement => (
    <React.Fragment>
      <Typography>{params.row.employee.username}</Typography>
    </React.Fragment>
  );

  const venmoIdCellRender = (
    params: GridRenderCellParams<ITipStatistic>
  ): React.ReactElement => (
    <React.Fragment>
      <Typography>
        {params.row.employee.additionalEmployeeInfo.venmoId}
      </Typography>
    </React.Fragment>
  );

  const getActions = (params: GridRowParams): React.ReactElement[] => {
    const value = params.row as ITipStatistic;

    return [
      <TransferredAction
        key={"t"}
        value={value}
        tipService={props.tipService}
      />,
    ];
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams<ITipStatistic>) => {
        return (
          <section style={{ display: "flex" }}>
            <Avatar
              src={params.row?.employee.avatarURL}
              alt={params.row?.employee.firstName}
            />
            <Typography style={{ marginTop: 12, marginLeft: 5 }}>
              {params.row?.employee.firstName} {params.row?.employee.lastName}
            </Typography>
          </section>
        );
      },
      headerAlign: "center",
    },
    {
      field: "username",
      headerName: "Username",
      minWidth: 140,
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      renderCell: usernameCellRender,
    },
    {
      field: "receivedAmount",
      headerName: "Received Amount",
      minWidth: 140,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: moneyCellRender,
    },
    {
      field: "venmoId",
      headerName: "Venmo ID",
      minWidth: 140,
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      renderCell: venmoIdCellRender,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      flex: 1,
      type: "dateTime",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      type: "actions",
      minWidth: 140,
      headerName: "Transferred",
      cellClassName: "actions",
      getActions: getActions,
    },
  ];

  const onPageChange = (page: number): void => {
    getData(page, pageSize, filter).finally(() => setPage(page));
  };

  const onPageSizeChange = (pageSize: number): void => {
    getData(page, pageSize, filter).finally(() => setPageSize(pageSize));
  };

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper
        header={"Tips"}
        rightHeader={
          <Select
            value={filter}
            sx={{ height: 30, backgroundColor: "white" }}
            onChange={(e: any) => setFilter(e.target.value)}
          >
            <MenuItem value={"n_2fa"}>All</MenuItem>
            <MenuItem value={"true_"}>Transferred</MenuItem>
            <MenuItem value={"false_"}>Not Transferred</MenuItem>
          </Select>
        }
      >
        <DataGrid
          rows={statistics}
          columns={columns}
          loading={pending}
          autoHeight={true}
          rowCount={rowCount}
          pageSize={pageSize}
          className={classes.grid}
          onPageChange={onPageChange}
          paginationMode={"server"}
          onPageSizeChange={onPageSizeChange}
        />
      </PaperWrapper>
    );
  };

  return render();
};

export default TipStatistics;

interface ITransferredActionProps {
  value: ITipStatistic;
  tipService: TipService;
}

export const TransferredAction = (
  props: ITransferredActionProps
): React.ReactElement => {
  const [pending, serPending] = useState<boolean>(false);

  const markAsTransferred = (value: ITipStatistic): void => {
    serPending(true);
    props.tipService
      .markAsTransferred({
        tipId: value.id,
        isTransferred: !value.isTransferred(),
      })
      .then(() => {
        value.setIsTransferred(!value.isTransferred());
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => serPending(false));
  };

  const render = (): React.ReactElement => {
    const { value } = props;

    return (
      <React.Fragment>
        <VisibleContent visible={pending}>
          <CircularProgress style={{ position: "absolute" }} size={20} />
        </VisibleContent>
        <FormControlLabel
          key={"transferred"}
          label={""}
          disabled={pending}
          control={<Switch color="primary" checked={value.isTransferred()} />}
          labelPlacement="start"
          onChange={() => markAsTransferred(value)}
        />
      </React.Fragment>
    );
  };

  return render();
};
