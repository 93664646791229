import React, { useEffect, useState } from "react";
import DealershipService from "../../../services/Dealership.service";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { INotificationChannels } from "../../../../index";
import * as Yup from "yup";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { PromptIfDirty } from "../../../components/prompt/PromptIfDirty";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { NotificationChannel } from "../../../common/Enums";
import { defaults, get, some } from "lodash";
import ModuleHelper from "../../../util/ModuleHelper";
import { useGlobalStyles } from "../../../util/ThemePalette";
import PaperWrapper from "../../../components/paper/PaperWrapper";

interface IFormData {
  notificationChannels: INotificationChannels;
}

interface IProps {
  readonly saveDisabled?: boolean;
  readonly dealershipService: DealershipService;
  readonly notificationChannels: INotificationChannels;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
});

const validationSchema = Yup.object().shape({});

const NotificationChannels = (props: IProps): React.ReactElement => {
  const globalClasses = useGlobalStyles();
  const { t } = useTranslation();

  const [refreshKey, setRefreshKey] = useState(Date.now());
  const [loading, setLoading] = useState<boolean>(false);

  const [notificationChannels, setNotificationChannels] =
    useState<INotificationChannels>(props.notificationChannels);

  useEffect(() => {
    setNotificationChannels(props.notificationChannels);
  }, [props.notificationChannels]);

  useEffect(() => {
    setRefreshKey(Date.now());
  }, [notificationChannels]);

  const onSubmit = (values: IFormData, submitProps: any): void => {
    setLoading(true);
    props.dealershipService
      .updateNotificationChannels(values)
      .then((response) => {
        const data = response.data;

        submitProps.resetForm({
          notifyRolesFlag: data.notifyRolesFlag,
          notificationChannels: data.notificationChannels,
        });

        setNotificationChannels(data.notificationChannels);
        ModuleHelper.grow.success(
          t("notificationSettings.updatedSuccessfully")
        );
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const handleCheckboxChange =
    (
      role: string,
      channel: NotificationChannel,
      formikProps: FormikProps<IFormData>
    ) =>
    (event: any) => {
      event.stopPropagation();

      const values: NotificationChannel[] = defaults(
        get(formikProps.values.notificationChannels, `${role}`),
        []
      );

      const index = values.indexOf(channel);

      if (index !== -1) {
        values.splice(index, 1);
      } else {
        values.push(channel);
      }

      formikProps.setFieldValue(`notificationChannels.${role}`, values);
    };

  const colRenderByRole = (
    role: string,
    channel: NotificationChannel,
    formikProps: FormikProps<IFormData>
  ): React.ReactElement => {
    const values: NotificationChannel[] = defaults(
      get(formikProps.values.notificationChannels, `${role}`),
      []
    );

    const checked = some(values, (item) => item === channel);

    return (
      <TableCell component="th" scope="row">
        <Checkbox
          size={"medium"}
          checked={checked}
          onChange={handleCheckboxChange(role, channel, formikProps)}
        />
      </TableCell>
    );
  };

  const formRender = (formikProps: FormikProps<IFormData>) => {
    const roles = Object.keys(formikProps.values.notificationChannels);

    return (
      <Form noValidate autoComplete="on">
        <PaperWrapper
          header={"CHANNELS"}
          rightHeader={
            <SubmitButton
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              disabled={props.saveDisabled}
              className={globalClasses.saveButton}
            >
              Save
            </SubmitButton>
          }
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={"20%"}>Channel</TableCell>
                  {roles.map((role, index) => (
                    <TableCell width={100} key={index}>
                      {role}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SMS
                  </TableCell>
                  {roles.map((role, index) => (
                    <React.Fragment key={index}>
                      {colRenderByRole(
                        role,
                        NotificationChannel.SMS,
                        formikProps
                      )}
                    </React.Fragment>
                  ))}
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email
                  </TableCell>
                  {roles.map((role, index) => (
                    <React.Fragment key={index}>
                      {colRenderByRole(
                        role,
                        NotificationChannel.EMAIL,
                        formikProps
                      )}
                    </React.Fragment>
                  ))}
                  <TableCell />
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Push Notifications
                  </TableCell>
                  {roles.map((role, index) => (
                    <React.Fragment key={index}>
                      {colRenderByRole(
                        role,
                        NotificationChannel.PUSH_NOTIFICATION,
                        formikProps
                      )}
                    </React.Fragment>
                  ))}
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </PaperWrapper>
        <PromptIfDirty message={t("common.unsavedChanges")} />
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Formik
          key={refreshKey}
          onSubmit={onSubmit}
          initialValues={{
            notificationChannels: notificationChannels,
          }}
          validationSchema={validationSchema}
        >
          {formRender}
        </Formik>
      </React.Fragment>
    );
  };

  return render();
};

export default NotificationChannels;
