import { makeStyles } from "@mui/styles";
import { globalStyle } from "../../../util/ThemePalette";

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent !important",
  },
  dollarDiv: {
    textAlign: "center",
  },
  automaticTip: {
    padding: 10,
    border: "2px solid #F3F2F4",
    borderRadius: 5,
  },
  tipButton: {
    padding: 20,
  },
  crossIcon: {
    display: "flex",
    marginLeft: "auto",
  },
  dollarImage: {
    width: "25px",
    height: "25px",
  },
  titleStyle: {
    textAlign: "center",
    padding: 5,
  },
  currency: {
    height: 100,
    width: "100%",
    textAlign: "center",
    border: "none",
    color: "black",
    fontSize: 40,
  },
  tipButtonStyle: {
    width: "100%",
    borderRadius: globalStyle.borderRadius,
  },
  tipDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 10,
  },
}));
