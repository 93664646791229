import React, { ForwardedRef, useImperativeHandle, useState } from "react";
import { CircularProgress } from "@mui/material";
import { SpinnerActions } from "../../common/Enums";
import "./spinner.css";
import { defaultTo } from "lodash";

interface IHideProps {
  ms?: number;
  animationDuration?: number;
}

let timeout: any = 0;

const GlobalLoader = React.forwardRef((props, ref: ForwardedRef<any>) => {
  const [action, setAction] = useState<SpinnerActions>(SpinnerActions.HIDE);

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }));

  const show = (): void => {
    clearTimeout(timeout);
    setAction(SpinnerActions.SHOW);
  };
  const hide = (args?: IHideProps): void => {
    timeout = setTimeout(() => {
      setAction(SpinnerActions.HIDE);
    }, defaultTo(args?.ms, 0));
  };

  const getStyle = (action: SpinnerActions): any => {
    if (action === SpinnerActions.HIDE) {
      return {
        transition: `${0}ms`,
        top: -70,
      };
    } else {
      return {
        transition: `${0}ms`,
        top: "calc(50% - 60px)",
      };
    }
  };

  const render = (): React.ReactElement => {
    return (
      <div id={"spinner"} style={getStyle(action)}>
        <CircularProgress size={40} />
      </div>
    );
  };

  return render();
});

export default GlobalLoader;
