import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import MuiAlert from "@mui/material/Alert";
import { AxiosError } from "axios";
import { get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const GlobalGrowl = forwardRef((props: any, ref: any) => {
  const [duration, setDuration] = useState<number>(5000);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [severity, setSeverity] = useState<AlertColor>("success");
  const { t } = useTranslation();

  const Alert: any = React.forwardRef(function Alert(props, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const success = (message: string, duration?: number) => {
    onOpen(message, "success", duration);
  };

  const warning = (message: string, duration?: number) => {
    onOpen(message, "warning", duration);
  };

  const error = (message: string, duration?: number) => {
    onOpen(message, "error", duration);
  };

  const info = (message: string, duration?: number) => {
    onOpen(message, "info", duration);
  };

  const showError = (error: AxiosError): void => {
    const message =
      get(error, "response.data.error_description") ??
      get(error, "response.data.message");

    if (!isEmpty(message)) {
      onOpen(message, "error");
      return;
    }

    onOpen(t("common.defaultErrorMessage"), "error");
  };

  const onOpen = (
    message: string,
    severity: AlertColor,
    duration = 5000
  ): void => {
    setMessage(message);
    setSeverity(severity);
    setDuration(duration);
    setOpen(true);
  };

  useImperativeHandle(ref, () => ({
    success: success,
    warning: warning,
    error: error,
    info: info,
    showError: showError,
  }));

  const handleClose = (event: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={duration}
      open={open}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ maxWidth: 400 }}>
        {message}
      </Alert>
    </Snackbar>
  );
});

export default GlobalGrowl;
