import React, { CSSProperties, useState } from "react";
import { ILaborOperationDTO } from "../../../index";
import { get } from "lodash";
import { capitalCase } from "change-case";
import RepairOrderService from "../../services/RepairOrder.service";
import {
  Chip,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ChipLabel, Color } from "../../types/types";
import LoopIcon from "@mui/icons-material/Loop";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { globalStyle } from "../../util/ThemePalette";
import { IRepairOrder } from "../../models/repair_order";
import { ILineItem } from "../../models/line_item";
import { ILaborOperation } from "../../models/labor_operation";

interface IProps {
  readonly style?: CSSProperties;
  readonly repairOrder: IRepairOrder;
}

export const OrderList = (props: IProps): JSX.Element => {
  const [customerLineItems] = useState<ILineItem[]>(
    props.repairOrder.getRequestedLineItems()
  );

  const [recommendedLineItems] = useState<ILineItem[]>(
    props.repairOrder.getRecommendedLineItems()
  );

  const lineItemRender = (data: ILineItem): React.ReactElement => {
    const laborOperation: ILaborOperationDTO = get(data, "laborOperations[0]");
    const description = RepairOrderService.getOperationDesc(laborOperation);

    return (
      <ListItem key={data.id}>
        <ListItemText>
          <Typography>{capitalCase(description)}</Typography>
        </ListItemText>
      </ListItem>
    );
  };

  const getIconByChipLabel = (chip: ChipLabel): React.ReactElement => {
    switch (chip) {
      case "APPROVED":
        return <DoneIcon />;
      case "DECLINED":
        return <CloseIcon />;
      case "PENDING":
        return <LoopIcon />;
    }
  };

  const recommendedLineItemRender = (data: ILineItem): React.ReactElement => {
    const laborOperation: ILaborOperation = data.getLaborOperation();
    const description = laborOperation.getOperationDescription();

    const color: Color = data.getChipColor();
    const chipLabel: ChipLabel = data.getChipLabel();

    return (
      <ListItem key={data.id}>
        <ListItemText>
          <Typography>{capitalCase(description)}</Typography>
        </ListItemText>
        <ListItemIcon>
          <Chip
            color={color}
            label={chipLabel}
            style={{ width: 125 }}
            icon={getIconByChipLabel(chipLabel)}
          />
        </ListItemIcon>
      </ListItem>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <Grid
        item
        container
        sx={{ padding: 1 }}
        style={{
          ...{
            border: globalStyle.border,
            borderRadius: globalStyle.borderRadius,
          },
          ...props.style,
        }}
      >
        <Grid item xs={12}>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "unset" }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight={"bold"}>Requested by Customer</Typography>
            </ListItemText>
          </ListItem>
          <div style={{ padding: 5 }}>
            {customerLineItems.map(lineItemRender)}
          </div>
        </Grid>

        <Grid item xs={12}>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "unset" }}>
              <EngineeringIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography fontWeight={"bold"}>Recommended by Dealer</Typography>
            </ListItemText>
          </ListItem>
          <div style={{ padding: 5 }}>
            {recommendedLineItems.map(recommendedLineItemRender)}
          </div>
        </Grid>
      </Grid>
    );
  };

  return render();
};
