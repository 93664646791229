import React from "react";
import TipService from "../../services/Tip.service";
import TipStatistics from "../../components/statistics/tip/TipStatistics";

const tipService = new TipService();

const TipsStatisticView = (): React.ReactElement => {
  const render = (): React.ReactElement => {
    return <TipStatistics tipService={tipService} />;
  };

  return render();
};

export default TipsStatisticView;
