import { IFile } from "./index";
import { IFileDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class File implements IFile {
  private _fileType: string;
  private _name: string;
  private _publicURL: string;

  constructor(args?: IFileDTO) {
    this._fileType = Utils.default(args?.fileType, "");
    this._name = Utils.default(args?.name, "");
    this._publicURL = Utils.default(args?.publicURL, "");
  }

  get fileType(): string {
    return this._fileType;
  }

  set fileType(value: string) {
    this._fileType = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get publicURL(): string {
    return this._publicURL;
  }

  set publicURL(value: string) {
    this._publicURL = value;
  }
}
