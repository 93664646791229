import BaseUser from "./BaseUser";
import { IUser } from "./index";
import { UserRole } from "../../common/Enums";
import { IUserDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class User extends BaseUser implements IUser {
  private _email: string;
  private _mobileNumber: string;
  private _roles: UserRole[];
  private _username: string;

  constructor(args?: IUserDTO) {
    super(args);
    this._email = Utils.default(args?.email, "");
    this._mobileNumber = Utils.default(args?.mobileNumber, "");
    this._roles = Utils.default(args?.roles, []);
    this._username = Utils.default(args?.username, "");
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get mobileNumber(): string {
    return this._mobileNumber;
  }

  set mobileNumber(value: string) {
    this._mobileNumber = value;
  }

  get roles(): UserRole[] {
    return this._roles;
  }

  set roles(value: UserRole[]) {
    this._roles = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }
}
