import { AxiosResponse } from "axios";
import { multiply } from "lodash";
import {
  IVehicleTipRequestDTO,
  IRepairOrderTipRequestDTO,
  ITipStatisticDto,
} from "../../index";
import { StringBool } from "../types/types";
import { ITipStatistic } from "../models/tip";
import { map } from "lodash";
import TipStatistic from "../models/tip/TipStatistic";

export interface ITipStatisticRequest {
  page: number;
  perPage: number;
  transferredToEmployees?: StringBool;
}

export interface IMarkAsTransferredDto {
  tipId: number;
  isTransferred: boolean;
}

interface ITipApiClient {
  addTip(data: any): Promise<AxiosResponse>;
  getStatistics(
    dto: ITipStatisticRequest
  ): Promise<AxiosResponse<{ result: ITipStatisticDto[] }>>;
  markAsTransferred(dto: IMarkAsTransferredDto): Promise<AxiosResponse>;
}

class TipApiClient implements ITipApiClient {
  private readonly prefix = "tip";

  addTip(data: any): Promise<AxiosResponse> {
    return window.apiClient.post(
      `${this.prefix}/stripe/create-checkout-session`,
      data
    );
  }

  getStatistics(
    dto: ITipStatisticRequest
  ): Promise<AxiosResponse<{ result: ITipStatisticDto[] }>> {
    return window.apiClient.get(`${this.prefix}`, { params: dto });
  }

  markAsTransferred(dto: IMarkAsTransferredDto): Promise<AxiosResponse> {
    return window.apiClient.put(
      `${this.prefix}/mark-transferred-tips`,
      undefined,
      {
        params: {
          tipIds: dto.tipId,
          isTransferred: dto.isTransferred,
        },
      }
    );
  }
}

export default class TipService {
  private tipApiClient: ITipApiClient;

  constructor() {
    this.tipApiClient = new TipApiClient();
  }

  public async addRepairOrderTip(
    data: IRepairOrderTipRequestDTO
  ): Promise<AxiosResponse<{ code: number; redirectURL: string }>> {
    data.amount = multiply(data.amount, 100);

    return this.tipApiClient.addTip(data);
  }

  public async addVehicleTip(
    data: IVehicleTipRequestDTO
  ): Promise<AxiosResponse<{ code: number; redirectURL: string }>> {
    data.amount = multiply(data.amount, 100);

    return this.tipApiClient.addTip(data);
  }

  public async getStatistics(
    dto: ITipStatisticRequest
  ): Promise<ITipStatistic[]> {
    const response = await this.tipApiClient.getStatistics(dto);

    return map(response.data.result, (item) => new TipStatistic(item));
  }

  public async markAsTransferred(
    dto: IMarkAsTransferredDto
  ): Promise<AxiosResponse> {
    return this.tipApiClient.markAsTransferred(dto);
  }
}
