import { makeStyles } from "@mui/styles";
import { globalStyle } from "../../../util/ThemePalette";

export const useStyles = makeStyles(() => ({
  gridStyles: {
    border: globalStyle.border,
    borderRadius: globalStyle.borderRadius,
  },
  statusLabel: {
    opacity: "50%",
  },
  statusValue: {
    fontWeight: 400,
    fontSize: 14,
  },
  carImage: {
    width: "60px",
  },
  backgroundColor: {
    backgroundColor: "#0C288E",
  },
}));
