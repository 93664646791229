export const acceptImgTypes = "image/png, image/jpg, image/jpeg, image/gif";

export const defaultValueRegex = new RegExp(/^n\/a$/);

export const urlRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
);

export const defaultPageSize = 25;

export const steps = [
  {
    label: "OPENED",
    description: ``,
  },
  {
    label: "IN PROGRESS",
    description: ``,
  },
  {
    label: "CLOSED",
    description: ``,
  },
];

export const Images = {
  ADVISOR: "../images/default-advisor.png",
  TECHNICIAN: "../images/default-tech.png",
  SALESPERSON: "../images/default-sales.png",
};
