import React from "react";
import TechnicianStatistics from "../../components/statistics/technician/TechnicianStatistics";
import AdvisorStatistics from "../../components/statistics/advisor/AdvisorStatistics";
import UserService from "../../services/User.service";
import { Box } from "@mui/material";
import SellerStatistics from "../../components/statistics/seller/SellerStatistics";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../index";
import VisibleContent from "../../components/visable_content/VisibleContent";
import ModuleHelper from "../../util/ModuleHelper";

const userService = new UserService();

const EmployeeOverview = (): React.ReactElement => {
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const render = (): React.ReactElement => {
    const isAdvisor = ModuleHelper.isAdvisor(user.roles);
    const isTechnician = ModuleHelper.isTechnician(user.roles);
    const isSalesPerson = ModuleHelper.isSalesPerson(user.roles);

    return (
      <React.Fragment>
        <VisibleContent visible={isAdvisor || isTechnician}>
          <Box sx={{ marginBottom: 1 }}>
            <TechnicianStatistics userService={userService} />
          </Box>
        </VisibleContent>
        <VisibleContent visible={isAdvisor}>
          <Box sx={{ marginBottom: 1 }}>
            <AdvisorStatistics userService={userService} />
          </Box>
        </VisibleContent>
        <VisibleContent visible={isSalesPerson}>
          <Box>
            <SellerStatistics userService={userService} />
          </Box>
        </VisibleContent>
      </React.Fragment>
    );
  };

  return render();
};

export default EmployeeOverview;
