import store from "../../config/store";
import { Generable } from "./Generable";
import { get, isEmpty } from "lodash";
import route from "../../common/route";
import { DefaultTypes } from "../../common/Enums";

export default class StripeUrlGenerator implements Generable {
  private readonly dealership;

  constructor() {
    this.dealership = store.getState().auth.user.dealerContext;
  }

  generate(): { successURL: string; cancelURL: string } {
    let success = `https://${window.location.host}${route.thankYou.pathname}`;

    const googlePlaceId = get(this.dealership, "googlePlaceId");

    if (!isEmpty(googlePlaceId) && googlePlaceId !== DefaultTypes.STRING) {
      success = `https://search.google.com/local/writereview?placeid=${googlePlaceId}`;
    }

    return {
      successURL: success,
      cancelURL: `https://${window.location.host}${window.location.pathname}${window.location.search}`,
    };
  }
}
