import { IDealershipConfig } from "./index";
import { mapValues, multiply, isUndefined } from "lodash";
import { DealershipSettings, UserRole } from "../../common/Enums";
import { IDealershipConfigDTO } from "../../services/Dealership.service";
import TipTemplateEngine from "../../engine/template/TipTemplateEngine";
import { IBaseUser } from "../user";
import Utils from "../../util/Utils";
import ModuleHelper from "../../util/ModuleHelper";

export default class DealershipConfig implements IDealershipConfig {
  private _askCustomerToLeaveTip: boolean;
  private _askCustomerToLeaveTipAfterScore: number;
  private _deactivateEmployee: boolean;
  private _customerDefaultName: string;
  private _messageSendToCustomer: UserRole[];
  private _notificationDuration: number;
  private _rateTitleMessage: string;
  private _reviewRemindCustomerDays: number;
  private _reviewLowScoreEmailList: string[];
  private _reviewLowScoreValue: number;
  private _reviewRemindCustomerHours: number;
  private _sendLowScoreEmails: boolean;
  private _thankYouMessage: string;
  private _tipIncrementationInDollars: number[];
  private _tipMaxAmountInDollars: number;
  private _tipMinAmountInDollars: number;
  private _tipTitleMessage: string;
  private _checkFetchedFromCdkNotifyCustomerField: boolean;
  private _vehicleSoldNotificationTime: number;
  private _tipWhoReceive: UserRole[];
  private _leaveTipCancelButton: string;
  private _leaveTipSubmitButton: string;

  private readonly visibleMap = new Map<string, boolean>();

  constructor(args?: IDealershipConfigDTO) {
    this._askCustomerToLeaveTip = Utils.default(
      args?.ASK_CUSTOMER_TO_LEAVE_A_TIP,
      false
    );
    this._askCustomerToLeaveTipAfterScore = Utils.default(
      args?.ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN,
      0
    );
    this._customerDefaultName = Utils.default(
      args?.MESSAGE_CUSTOMER_DEFAULT_NAME,
      ""
    );
    this._checkFetchedFromCdkNotifyCustomerField = Utils.default(
      args?.CHECK_FETCHED_FROM_CDK_NOTIFY_CUSTOMER_FIELD,
      false
    );
    this._deactivateEmployee = Utils.default(
      args?.DEACTIVATE_EMPLOYEE_THAT_IS_NOT_IN_CDK,
      false
    );
    this._notificationDuration = Utils.default(
      args?.NOTIFICATION_DURATION_BETWEEN_MESSAGE_NOTIFICATION_MINUTES,
      0
    );
    this._messageSendToCustomer = Utils.default(
      args?.MESSAGE_SEND_TO_CUSTOMER,
      []
    );
    this._leaveTipCancelButton = Utils.default(
      args?.LEAVE_A_TIP_CANCEL_BUTTON,
      "NO THANK YOU"
    );
    this._leaveTipSubmitButton = Utils.default(
      args?.LEAVE_A_TIP_SUBMIT_BUTTON,
      "LEAVE A TIP"
    );
    this._rateTitleMessage = Utils.default(args?.RATE_TITLE_MESSAGE, "");
    this._reviewLowScoreEmailList = Utils.default(
      args?.REVIEW_LOW_SCORE_SEND_TO_EMAIL_LIST,
      []
    );
    this._reviewLowScoreValue = Utils.default(args?.REVIEW_LOW_SCORE_VALUE, 0);

    this._reviewRemindCustomerDays =
      DealershipConfig.getReviewRemindCustomerUnit(
        Utils.default(args?.REVIEW_REMIND_CUSTOMER_AFTER_MIN, 0),
        "days"
      );
    this._reviewRemindCustomerHours =
      DealershipConfig.getReviewRemindCustomerUnit(
        Utils.default(args?.REVIEW_REMIND_CUSTOMER_AFTER_MIN, 0),
        "hours"
      );
    this._sendLowScoreEmails = Utils.default(
      args?.SEND_EMAILS_WITH_LOW_SCORE_REVIEW,
      false
    );
    this._vehicleSoldNotificationTime = Utils.default(
      args?.VEHICLE_SOLD_NOTIFICATION_AM_TIME,
      0
    );
    this._tipIncrementationInDollars = ModuleHelper.centsToDollars(
      args?.TIP_INCREMENTATION_IN_CENTS
    );
    this._tipMaxAmountInDollars = ModuleHelper.centToDollar(
      args?.TIP_MAX_AMOUNT_IN_CENTS
    );
    this._tipMinAmountInDollars = ModuleHelper.centToDollar(
      args?.TIP_MIN_AMOUNT_IN_CENTS
    );
    this._tipTitleMessage = Utils.default(args?.TIP_TITLE_MESSAGE, "");
    this._tipWhoReceive = Utils.default(args?.TIP_WHO_RECEIVE, []);
    this._thankYouMessage = Utils.default(args?.THANK_YOU_SCREEN_MESSAGE, "");

    this.initVisibleMap(args);
  }

  private initVisibleMap(args?: IDealershipConfigDTO): void {
    mapValues(DealershipSettings, (key) => {
      this.visibleMap.set(key, !isUndefined(args?.[key]));
    });
  }

  private static getReviewRemindCustomerUnit(
    value: number,
    unit: "days" | "hours" | "minutes"
  ): number {
    const Time = DealershipConfig.SplitTime(value / 60);

    return Time[unit];
  }

  get askCustomerToLeaveTip(): boolean {
    return this._askCustomerToLeaveTip;
  }

  set askCustomerToLeaveTip(value: boolean) {
    this._askCustomerToLeaveTip = value;
  }

  get askCustomerToLeaveTipAfterScore(): number {
    return this._askCustomerToLeaveTipAfterScore;
  }

  set askCustomerToLeaveTipAfterScore(value: number) {
    this._askCustomerToLeaveTipAfterScore = value;
  }

  get deactivateEmployee(): boolean {
    return this._deactivateEmployee;
  }

  set deactivateEmployee(value: boolean) {
    this._deactivateEmployee = value;
  }

  get customerDefaultName(): string {
    return this._customerDefaultName;
  }

  set customerDefaultName(value: string) {
    this._customerDefaultName = value;
  }

  get messageSendToCustomer(): UserRole[] {
    return this._messageSendToCustomer;
  }

  set messageSendToCustomer(value: UserRole[]) {
    this._messageSendToCustomer = value;
  }

  get notificationDuration(): number {
    return this._notificationDuration;
  }

  set notificationDuration(value: number) {
    this._notificationDuration = value;
  }

  get rateTitleMessage(): string {
    return this._rateTitleMessage;
  }

  set rateTitleMessage(value: string) {
    this._rateTitleMessage = value;
  }

  get reviewRemindCustomerDays(): number {
    return this._reviewRemindCustomerDays;
  }

  set reviewRemindCustomerDays(value: number) {
    this._reviewRemindCustomerDays = value;
  }

  get reviewLowScoreEmailList(): string[] {
    return this._reviewLowScoreEmailList;
  }

  set reviewLowScoreEmailList(value: string[]) {
    this._reviewLowScoreEmailList = value;
  }

  get reviewLowScoreValue(): number {
    return this._reviewLowScoreValue;
  }

  set reviewLowScoreValue(value: number) {
    this._reviewLowScoreValue = value;
  }

  get reviewRemindCustomerHours(): number {
    return this._reviewRemindCustomerHours;
  }

  set reviewRemindCustomerHours(value: number) {
    this._reviewRemindCustomerHours = value;
  }

  get sendLowScoreEmails(): boolean {
    return this._sendLowScoreEmails;
  }

  set sendLowScoreEmails(value: boolean) {
    this._sendLowScoreEmails = value;
  }

  get thankYouMessage(): string {
    return this._thankYouMessage;
  }

  set thankYouMessage(value: string) {
    this._thankYouMessage = value;
  }

  get tipIncrementationInDollars(): number[] {
    return this._tipIncrementationInDollars;
  }

  set tipIncrementationInDollars(value: number[]) {
    this._tipIncrementationInDollars = value;
  }

  get tipMaxAmountInDollars(): number {
    return this._tipMaxAmountInDollars;
  }

  set tipMaxAmountInDollars(value: number) {
    this._tipMaxAmountInDollars = value;
  }

  get tipMinAmountInDollars(): number {
    return this._tipMinAmountInDollars;
  }

  set tipMinAmountInDollars(value: number) {
    this._tipMinAmountInDollars = value;
  }

  get tipTitleMessage(): string {
    return this._tipTitleMessage;
  }

  set tipTitleMessage(value: string) {
    this._tipTitleMessage = value;
  }

  get tipWhoReceive(): UserRole[] {
    return this._tipWhoReceive;
  }

  set tipWhoReceive(value: UserRole[]) {
    this._tipWhoReceive = value;
  }

  get firstTipIncrementationInDollars(): number {
    return Utils.default(this.tipIncrementationInDollars[0], 0);
  }

  set firstTipIncrementationInDollars(value: number) {
    this.tipIncrementationInDollars[0] = value;
  }

  get secondTipIncrementationInDollars(): number {
    return Utils.default(this.tipIncrementationInDollars[1], 0);
  }

  set secondTipIncrementationInDollars(value: number) {
    this.tipIncrementationInDollars[1] = value;
  }

  get thirdTipIncrementationInDollars(): number {
    return Utils.default(this.tipIncrementationInDollars[2], 0);
  }

  set thirdTipIncrementationInDollars(value: number) {
    this.tipIncrementationInDollars[2] = value;
  }

  get forthTipIncrementationInDollars(): number {
    return Utils.default(this.tipIncrementationInDollars[3], 0);
  }

  set forthTipIncrementationInDollars(value: number) {
    this.tipIncrementationInDollars[3] = value;
  }

  get vehicleSoldNotificationTime(): number {
    return this._vehicleSoldNotificationTime;
  }

  set vehicleSoldNotificationTime(value: number) {
    this._vehicleSoldNotificationTime = value;
  }

  get checkFetchedFromCdkNotifyCustomerField(): boolean {
    return this._checkFetchedFromCdkNotifyCustomerField;
  }

  set checkFetchedFromCdkNotifyCustomerField(value: boolean) {
    this._checkFetchedFromCdkNotifyCustomerField = value;
  }

  get leaveTipCancelButton(): string {
    return this._leaveTipCancelButton;
  }

  set leaveTipCancelButton(value: string) {
    this._leaveTipCancelButton = value;
  }

  get leaveTipSubmitButton(): string {
    return this._leaveTipSubmitButton;
  }

  set leaveTipSubmitButton(value: string) {
    this._leaveTipSubmitButton = value;
  }

  showTipWhoReceive(): boolean {
    return !!this.visibleMap.get(DealershipSettings.TIP_WHO_RECEIVE);
  }

  showLowScoreEmails(): boolean {
    const hasLowScoreEmail = !!this.visibleMap.get(
      DealershipSettings.SEND_EMAILS_WITH_LOW_SCORE_REVIEW
    );
    const hasLowScoreReview = !!this.visibleMap.get(
      DealershipSettings.REVIEW_LOW_SCORE_VALUE
    );
    const hasLowScoreEmailList = !!this.visibleMap.get(
      DealershipSettings.REVIEW_LOW_SCORE_SEND_TO_EMAIL_LIST
    );

    return Boolean(
      hasLowScoreEmail && hasLowScoreReview && hasLowScoreEmailList
    );
  }

  showReviewReminder(): boolean {
    return !!this.visibleMap.get(
      DealershipSettings.REVIEW_REMIND_CUSTOMER_AFTER_MIN
    );
  }

  showThankYouMessage(): boolean {
    return !!this.visibleMap.get(DealershipSettings.THANK_YOU_SCREEN_MESSAGE);
  }

  showTipTitleMessage(): boolean {
    return !!this.visibleMap.get(DealershipSettings.TIP_TITLE_MESSAGE);
  }

  showRateTitleMessage(): boolean {
    return !!this.visibleMap.get(DealershipSettings.RATE_TITLE_MESSAGE);
  }

  showDeactivateEmployee(): boolean {
    return !!this.visibleMap.get(DealershipSettings.DEACTIVATE_EMPLOYEE);
  }

  showCustomerDefaultName(): boolean {
    return !!this.visibleMap.get(DealershipSettings.CUSTOMER_DEFAULT_NAME);
  }

  showNotificationDuration(): boolean {
    return !!this.visibleMap.get(DealershipSettings.NOTIFICATION_DURATION);
  }

  showLeaveTipCancelButton(): boolean {
    return !!this.visibleMap.get(DealershipSettings.LEAVE_A_TIP_CANCEL_BUTTON);
  }

  showLeaveTipSubmitButton(): boolean {
    return !!this.visibleMap.get(DealershipSettings.LEAVE_A_TIP_SUBMIT_BUTTON);
  }

  showMessageSendToCustomer(): boolean {
    return !!this.visibleMap.get(DealershipSettings.MESSAGE_SEND_TO_CUSTOMER);
  }

  showTipMaxAmountInDollars(): boolean {
    return !!this.visibleMap.get(DealershipSettings.TIP_MAX_AMOUNT_IN_CENTS);
  }

  showTipMinAmountInDollars(): boolean {
    return !!this.visibleMap.get(DealershipSettings.TIP_MIN_AMOUNT_IN_CENTS);
  }

  showAskCustomerToLeaveTip(): boolean {
    return !!this.visibleMap.get(
      DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP
    );
  }

  showTipIncrementationInDollars(): boolean {
    return !!this.visibleMap.get(
      DealershipSettings.TIP_INCREMENTATION_IN_CENTS
    );
  }

  showVehicleSoldNotificationTime(): boolean {
    return !!this.visibleMap.get(
      DealershipSettings.VEHICLE_SOLD_NOTIFICATION_AM_TIME
    );
  }

  showAskCustomerToLeaveTipAfterScore(): boolean {
    return !!this.visibleMap.get(
      DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN
    );
  }

  showCheckFetchedFromCdkNotifyCustomerField(): boolean {
    return !!this.visibleMap.get(
      DealershipSettings.CHECK_FETCHED_FROM_CDK_NOTIFY_CUSTOMER_FIELD
    );
  }

  getTipTitle(employee: IBaseUser, vehicle: IVehicle): string {
    const templateEngine = new TipTemplateEngine({
      text: this.tipTitleMessage,
      vehicle: vehicle,
      employee: employee,
    });

    return templateEngine.build();
  }

  toDTO(): IDealershipConfigDTO {
    const days = this.reviewRemindCustomerDays;
    const hours = this.reviewRemindCustomerHours;

    const minutes = multiply(days, 24 * 60) + multiply(hours, 60);

    return {
      [DealershipSettings.RATE_TITLE_MESSAGE]: this._rateTitleMessage,
      [DealershipSettings.TIP_TITLE_MESSAGE]: this._tipTitleMessage,
      [DealershipSettings.THANK_YOU_SCREEN_MESSAGE]: this._thankYouMessage,
      [DealershipSettings.DEACTIVATE_EMPLOYEE]: this._deactivateEmployee,
      [DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP]:
        this._askCustomerToLeaveTip,
      [DealershipSettings.CUSTOMER_DEFAULT_NAME]: this._customerDefaultName,
      [DealershipSettings.MESSAGE_SEND_TO_CUSTOMER]:
        this._messageSendToCustomer,
      [DealershipSettings.TIP_INCREMENTATION_IN_CENTS]:
        ModuleHelper.dollarsToCents(this._tipIncrementationInDollars),
      [DealershipSettings.TIP_MAX_AMOUNT_IN_CENTS]: ModuleHelper.dollarToCent(
        this._tipMaxAmountInDollars
      ),
      [DealershipSettings.TIP_MIN_AMOUNT_IN_CENTS]: ModuleHelper.dollarToCent(
        this._tipMinAmountInDollars
      ),
      [DealershipSettings.NOTIFICATION_DURATION]: this._notificationDuration,
      [DealershipSettings.TIP_WHO_RECEIVE]: this._tipWhoReceive,
      [DealershipSettings.REVIEW_REMIND_CUSTOMER_AFTER_MIN]: minutes,
      [DealershipSettings.REVIEW_LOW_SCORE_SEND_TO_EMAIL_LIST]:
        this._reviewLowScoreEmailList,
      [DealershipSettings.REVIEW_LOW_SCORE_VALUE]: this._reviewLowScoreValue,
      [DealershipSettings.SEND_EMAILS_WITH_LOW_SCORE_REVIEW]:
        this._sendLowScoreEmails,
      [DealershipSettings.ASK_CUSTOMER_TO_LEAVE_A_TIP_AFTER_SCORE_HIGHER_THAN]:
        this._askCustomerToLeaveTipAfterScore,
      [DealershipSettings.VEHICLE_SOLD_NOTIFICATION_AM_TIME]:
        this._vehicleSoldNotificationTime,
      [DealershipSettings.CHECK_FETCHED_FROM_CDK_NOTIFY_CUSTOMER_FIELD]:
        this._checkFetchedFromCdkNotifyCustomerField,
      [DealershipSettings.LEAVE_A_TIP_CANCEL_BUTTON]:
        this._leaveTipCancelButton,
      [DealershipSettings.LEAVE_A_TIP_SUBMIT_BUTTON]:
        this._leaveTipSubmitButton,
    };
  }

  private static SplitTime(numberOfHours: number) {
    const Days = Math.floor(numberOfHours / 24);
    const Remainder = numberOfHours % 24;
    const Hours = Math.floor(Remainder);
    const Minutes = Math.floor(60 * (Remainder - Hours));
    return { days: Days, hours: Hours, minutes: Minutes };
  }
}
