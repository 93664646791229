import React from "react";
import { Box } from "@mui/material";
import { globalStyle } from "../../util/ThemePalette";
import UserDetails from "../employee/UserDetails";
import { IBaseUser } from "../../models/user";
import Utils from "../../util/Utils";
import useMobileResolution from "../../hooks/useMobileResolution";

interface IProps {
  employees?: IBaseUser[];
}

const DealershipHeaderEmployees = (props: IProps): React.ReactElement => {
  const employees = Utils.default(props.employees, []);

  const isMobile = useMobileResolution();

  const render = (): React.ReactElement => {
    return (
      <Box>
        <Box
          sx={{
            width: "100%",
            padding: 1,
            overflow: "auto",
            position: "absolute",
            justifyContent: "center",
            height: "calc(100% - 50px)",
          }}
        >
          {employees.map((employee, index) => (
            <Box
              key={index}
              sx={{
                margin: 1,
                float: "left",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
              }}
              style={{
                borderRadius: globalStyle.borderRadius,
                width: isMobile ? "-webkit-fill-available" : "unset",
              }}
            >
              <UserDetails user={employee} style={{ borderColor: "#000" }} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return render();
};
export default DealershipHeaderEmployees;
