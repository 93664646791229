import { TemplateEngine } from "./TemplateEngine";
import { Placeholders } from "../../common/Enums";
import store from "../../config/store";
import { defaultTo } from "lodash";
import { IBaseUser } from "../../models/user";
import { IDealershipContext } from "../../models/dealership";

export default class TipTemplateEngine extends TemplateEngine {
  private readonly text: string;
  private readonly vehicle: IVehicle;
  private readonly employee: IBaseUser;
  private readonly dealershipContext: IDealershipContext;

  constructor(args: { text: string; vehicle: IVehicle; employee: IBaseUser }) {
    super();

    this.text = args.text;
    this.vehicle = args.vehicle;
    this.employee = args.employee;
    this.dealershipContext = store.getState().auth.user.dealerContext;
  }

  build(): string {
    const vehicle = this.vehicle;
    const employee = this.employee;

    return defaultTo(this.text, "")
      .replace(
        Placeholders.CAR_MODEL,
        defaultTo(vehicle?.model, Placeholders.CAR_MODEL)
      )
      .replace(
        Placeholders.TECH_LAST_NAME,
        defaultTo(employee?.lastName, Placeholders.TECH_LAST_NAME)
      )
      .replace(
        Placeholders.TECH_FIRST_NAME,
        defaultTo(employee?.firstName, Placeholders.TECH_FIRST_NAME)
      )
      .replace(
        Placeholders.DEALER_NAME,
        defaultTo(this.dealershipContext.name, Placeholders.DEALER_NAME)
      );
  }
}
