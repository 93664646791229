import { IDealership } from "./index";
import { IDealershipDTO } from "../../../index";
import Utils from "../../util/Utils";
import DealershipContext from "./DealershipContext";
import Validation from "../../util/Validation";

export default class Dealership
  extends DealershipContext
  implements IDealership
{
  private _cdkId: string;
  private _crmImportFilePrefix: string;
  private _crmIntegration: string;
  private _launchDate: string;
  private _primaryContactEmailAddress: string;
  private _sellingOrganization: string;
  private _timeZone: string;

  constructor(args?: IDealershipDTO) {
    super(args);
    this._cdkId = Utils.default(args?.cdkId, "");
    this._crmIntegration = Utils.default(args?.crmIntegration, "");
    this._launchDate = Utils.default(args?.launchDate, "");
    this._primaryContactEmailAddress = Utils.default(
      args?.primaryContactEmailAddress,
      ""
    );
    this._sellingOrganization = Utils.default(args?.sellingOrganization, "");
    this._crmImportFilePrefix = Utils.default(args?.crmImportFilePrefix, "");
    this._timeZone = Utils.default(args?.timeZone, "");
  }

  get cdkId(): string {
    return this._cdkId;
  }

  set cdkId(value: string) {
    this._cdkId = value;
  }

  get crmImportFilePrefix(): string {
    return this._crmImportFilePrefix;
  }

  set crmImportFilePrefix(value: string) {
    this._crmImportFilePrefix = value;
  }

  get crmIntegration(): string {
    return this._crmIntegration;
  }

  set crmIntegration(value: string) {
    this._crmIntegration = value;
  }

  get launchDate(): string {
    return this._launchDate;
  }

  set launchDate(value: string) {
    this._launchDate = value;
  }

  get primaryContactEmailAddress(): string {
    return this._primaryContactEmailAddress;
  }

  set primaryContactEmailAddress(value: string) {
    this._primaryContactEmailAddress = value;
  }

  get sellingOrganization(): string {
    return this._sellingOrganization;
  }

  set sellingOrganization(value: string) {
    this._sellingOrganization = value;
  }

  get timeZone(): string {
    return this._timeZone;
  }

  set timeZone(value: string) {
    this._timeZone = value;
  }

  toDTO(): IDealershipDTO {
    return {
      id: this._id,
      name: this._name,
      mainUrl: this._mainUrl,
      country: this._country,
      mainPhone: Validation.phoneFormatter(this._mainPhone),
      serviceUrl: this._serviceUrl,
      mainAddress: this._mainAddress,
      crmIntegration: this._crmIntegration,
      connectedToCrm: this._connectedToCrm,
      serviceAddress: this._serviceAddress,
      servicePhone: Validation.phoneFormatter(this._servicePhone),
      googlePlaceId: this._googlePlaceId,
      primaryContactPhone: Validation.phoneFormatter(this._primaryContactPhone),
      primaryContactName: this._primaryContactName,
      connectedToCdk: this._connectedToCdk,
      bgrImgPublicURL: this._bgrImgPublicURL,
      cdkId: this._cdkId,
      launchDate: this._launchDate,
      primaryContactEmailAddress: this._primaryContactEmailAddress,
      timeZone: this._timeZone,
      sellingOrganization: this._sellingOrganization,
      crmImportFilePrefix: this._crmImportFilePrefix,
    };
  }
}
