import { AxiosResponse } from "axios";
import {
  IVehicleReviewDto,
  IVehicleReviewRequest,
  IVehicleReviewRequestForm,
} from "../../index";
import VehicleReview from "../models/vehicle_review/VehicleReview";
import { IVehicleReview } from "../models/vehicle_review";
import { UserRole } from "../common/Enums";
import UserService from "./User.service";
import { get } from "lodash";
import Utils from "../util/Utils";
import Employee from "../models/user/Employee";

interface IVehicleApiClient {
  get(id: number): Promise<AxiosResponse<IVehicleReviewDto>>;
  sendReviewRequest(dto: IVehicleReviewRequest): Promise<AxiosResponse>;
}

class VehicleApiClient implements IVehicleApiClient {
  private readonly prefix = "vehicle";

  get(id: number): Promise<AxiosResponse<IVehicleReviewDto>> {
    return window.apiClient.get(`${this.prefix}/${id}`);
  }

  sendReviewRequest(dto: IVehicleReviewRequest): Promise<AxiosResponse> {
    return window.apiClient.post(`${this.prefix}/send-review-request`, dto);
  }
}

export default class VehicleService {
  private userService: UserService;
  private vehicleApiClient: IVehicleApiClient;

  constructor(userService?: UserService) {
    this.vehicleApiClient = new VehicleApiClient();
    this.userService = Utils.default(userService, new UserService());
  }

  public async get(id: number): Promise<IVehicleReview> {
    const response = await this.vehicleApiClient.get(id);

    return new VehicleReview(response.data);
  }

  public async sendReviewRequest(values: IVehicleReviewRequestForm) {
    if (values.customerMode === "new") {
      const response = await this.userService.createUser(
        new Employee({
          id: 0,
          roles: [UserRole.CUSTOMER],
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
        })
      );

      values.customer = {
        value: response.id,
        label: response.firstName,
      };
    }

    const dto: IVehicleReviewRequest = {
      customerId: get(values, "customer.value"),
      salespersonId: get(values, "salesperson.value"),
      vehicle: get(values, "vehicle"),
    };

    return this.vehicleApiClient.sendReviewRequest(dto);
  }
}
