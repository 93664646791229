import ApiClient, { IApiClient } from "../config/http_client/ApiClient";
import appConfig from "../config/appConfig";
import qs from "qs";
import { AxiosResponse } from "axios";
import { IContextUserDTO } from "../../index";
import FirebaseService from "./firebase/Firebase.service";
import { UserRole } from "../common/Enums";
import { map, isEmpty } from "lodash";
import store from "../config/store";
import ContextUser from "../models/user/ContextUser";
import { IContextUser } from "../models/user";

interface ILoginData {
  client_id: string;
  username: string;
  password: string;
  grant_type: string;
}

interface IRefreshTokenData {
  client_id: string;
  grant_type: string;
  refresh_token: string;
}

interface IAuthResponse {
  access_token: string;
  expires_in: number;
  "not-before-policy": number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

interface ILoginApiClient {
  logout(token: string): Promise<AxiosResponse>;

  login(data: ILoginData): Promise<AxiosResponse<IAuthResponse>>;

  smsLogin(token: string): Promise<AxiosResponse<IAuthResponse>>;

  refreshToken(data: IRefreshTokenData): Promise<AxiosResponse<IAuthResponse>>;

  getUserActivateInfo(token: string): Promise<AxiosResponse>;

  setUserPassword(token: string, password: string): Promise<AxiosResponse>;
}

export const storageKeys = {
  access_token: "access_token",
  refresh_token: "refresh_token",
};

class LoginApiClient implements ILoginApiClient {
  private apiKeyClockClient: IApiClient;

  constructor() {
    this.apiKeyClockClient = new ApiClient({
      baseURL: appConfig.apiKeyCloakURL,
      contentType: "application/x-www-form-urlencoded",
    });
  }

  login(data: ILoginData): Promise<AxiosResponse<IAuthResponse>> {
    return this.apiKeyClockClient.post(
      `auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
      qs.stringify(data)
    );
  }

  refreshToken(data: IRefreshTokenData): Promise<AxiosResponse<IAuthResponse>> {
    return this.apiKeyClockClient.post(
      `auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM_NAME}/protocol/openid-connect/token`,
      qs.stringify(data)
    );
  }

  smsLogin(token: string): Promise<AxiosResponse<IAuthResponse>> {
    const apiClient = new ApiClient({
      baseURL: appConfig.apiBaseURL,
      authorization: `sms-token ${token}`,
    });

    return apiClient.get(`user/authentication/token`);
  }

  getUserActivateInfo(token: string): Promise<AxiosResponse> {
    const apiClient = new ApiClient({
      baseURL: appConfig.apiBaseURL,
    });

    return apiClient.post(`public/user/activate/info`, { token: token });
  }

  setUserPassword(token: string, password: string): Promise<AxiosResponse> {
    const apiClient = new ApiClient({
      baseURL: appConfig.apiBaseURL,
    });

    return apiClient.put(`public/user/activate/set-password`, {
      token: token,
      password: password,
    });
  }

  logout(token: string): Promise<AxiosResponse> {
    return window.apiClient.post("user/push-notification/log-out", {
      token: token,
    });
  }
}

export default class LoginService {
  private loginApiClient: ILoginApiClient;

  constructor() {
    this.loginApiClient = new LoginApiClient();
  }

  public async myself(): Promise<IContextUser> {
    const accessToken = localStorage.getItem(storageKeys.access_token);

    const apiClient = new ApiClient({
      baseURL: appConfig.apiBaseURL,
      authorization: `Bearer ${accessToken}`,
    });

    window.apiClient = apiClient;

    const response = await apiClient.get<IContextUserDTO>(
      "user/myself-servues"
    );
    response.data.roles = map(response.data.roles, (role) =>
      role.toUpperCase()
    ) as UserRole[];

    await LoginService.pushSubscribe(apiClient);

    return new ContextUser(response.data);
  }

  public async logout() {
    const token = store.getState().main.notificationToken;

    if (!isEmpty(token)) {
      await this.loginApiClient.logout(token);
    }

    await LoginService.removeAuthTokens();
  }

  public async login(
    username: string,
    password: string
  ): Promise<IContextUser> {
    const data: ILoginData = {
      client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
      username: username,
      password: password,
      grant_type: "password",
    };

    const response = await this.loginApiClient.login(data);

    LoginService.setAuthTokens(response.data);

    return this.myself();
  }

  public async smsLogin(token: string): Promise<IContextUser> {
    try {
      const response = await this.loginApiClient.smsLogin(token);

      LoginService.setAuthTokens(response.data);
    } catch (error) {
      LoginService.removeAuthTokens();
      throw error;
    }

    return this.myself();
  }

  public async getUserActivateInfo(token: string): Promise<IContextUser> {
    const response = await this.loginApiClient.getUserActivateInfo(token);

    return new ContextUser(response.data);
  }

  public async setUserPassword(
    token: string,
    password: string
  ): Promise<AxiosResponse> {
    return this.loginApiClient.setUserPassword(token, password);
  }

  public async refreshToken(): Promise<AxiosResponse<IAuthResponse>> {
    const data: IRefreshTokenData = {
      client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
      grant_type: "refresh_token",
      refresh_token: `${localStorage.getItem(storageKeys.refresh_token)}`,
    };

    const response = await this.loginApiClient.refreshToken(data);

    LoginService.setAuthTokens(response.data);

    window.apiClient.updateAccessToken(response.data.access_token);

    return response;
  }

  private static setAuthTokens(data: IAuthResponse): void {
    localStorage.setItem(storageKeys.access_token, data.access_token);
    localStorage.setItem(storageKeys.refresh_token, data.refresh_token);
  }

  private static removeAuthTokens(): void {
    localStorage.removeItem(storageKeys.access_token);
    localStorage.removeItem(storageKeys.refresh_token);
  }

  private static async pushSubscribe(apiClient: IApiClient) {
    try {
      const supported = await FirebaseService.supported();

      if (!supported) {
        console.warn("The browser does not support Web Push API.");
        return;
      }

      const firebaseService = new FirebaseService();

      await firebaseService.subscribe(apiClient);
    } catch (error) {
      console.warn(error);
    }
  }
}
