import React from "react";
import Loader from "../loader/Loader";
import SomethingWentWrong from "../something_went_wrong/SomethingWentWrong";
import VisibleContent from "../visable_content/VisibleContent";

interface IProps {
  children: any;
  pending?: boolean;
  visible?: boolean;
  showError?: boolean;
  loader?: React.ReactElement;
}

const LazyContent = (props: IProps): React.ReactElement => {
  const render = (): React.ReactElement => {
    if (props.showError) {
      return <SomethingWentWrong />;
    }

    return (
      <React.Fragment>
        <VisibleContent visible={Boolean(props.pending)}>
          {props.loader ? (
            props.loader
          ) : (
            <Loader pending={Boolean(props.pending)} disableAnimation={true} />
          )}
        </VisibleContent>
        <VisibleContent visible={Boolean(props.visible)}>
          {props.children}
        </VisibleContent>
      </React.Fragment>
    );
  };

  return render();
};

export default LazyContent;
