import { IEmployee, IEmployeeInfo } from "./index";
import { StringBool } from "../../types/types";
import { UserRole } from "../../common/Enums";
import { IEmployeeDTO, IEmployeeRequestDTO } from "../../../index";
import EmployeeInfo from "./EmployeeInfo";
import Utils from "../../util/Utils";
import Validation from "../../util/Validation";
import { isEmpty } from "lodash";
import ModuleHelper from "../../util/ModuleHelper";
import { Images } from "../../util/constants";

export default class Employee implements IEmployee {
  private _additionalEmployeeInfo: IEmployeeInfo;
  private _email: string;
  private _enabled: StringBool;
  private _externalRefId: string;
  private _firstName: string;
  private _id: number;
  private _lastName: string;
  private _mobileNumber: string;
  private _roles: UserRole[];
  private _username: string;
  private _avatarURL: string;

  constructor(args?: IEmployeeDTO) {
    this._additionalEmployeeInfo = new EmployeeInfo(
      args?.additionalEmployeeInfo
    );
    this._email = Utils.default(args?.email, "");
    this._enabled = Utils.default(args?.enabled, "false_");
    this._externalRefId = Utils.default(args?.externalRefId, "");
    this._firstName = Utils.default(args?.firstName, "");
    this._id = Utils.default(args?.id, 0);
    this._lastName = Utils.default(args?.lastName, "");
    this._mobileNumber = Validation.phoneFormatter(
      Utils.default(args?.mobileNumber, "")
    );
    this._roles = Utils.default(args?.roles, []);
    this._username = Utils.default(args?.username, "");
    this._avatarURL = Utils.default(
      args?.avatarURL,
      Employee.getAvatarByRoles(this.roles)
    );
  }

  get additionalEmployeeInfo(): IEmployeeInfo {
    return this._additionalEmployeeInfo;
  }

  set additionalEmployeeInfo(value: IEmployeeInfo) {
    this._additionalEmployeeInfo = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get enabled(): StringBool {
    return this._enabled;
  }

  set enabled(value: StringBool) {
    this._enabled = value;
  }

  get externalRefId(): string {
    return this._externalRefId;
  }

  set externalRefId(value: string) {
    this._externalRefId = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get mobileNumber(): string {
    return this._mobileNumber;
  }

  set mobileNumber(value: string) {
    this._mobileNumber = value;
  }

  get roles(): UserRole[] {
    return this._roles;
  }

  set roles(value: UserRole[]) {
    this._roles = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get avatarURL(): string {
    return this._avatarURL;
  }

  set avatarURL(value: string) {
    this._avatarURL = value;
  }

  getName(): string {
    if (!isEmpty(this.firstName)) {
      return `${this.firstName} ${this.lastName}`;
    }

    if (!isEmpty(this.email)) {
      return this.email;
    }

    if (!isEmpty(this.mobileNumber)) {
      return this.mobileNumber;
    }

    return this.username;
  }

  isEmployee(): boolean {
    return (
      this.roles.includes(UserRole.ADVISOR) ||
      this.roles.includes(UserRole.TECHNICIAN) ||
      this.roles.includes(UserRole.SALESPERSON)
    );
  }

  isCustomer(): boolean {
    return this.roles.includes(UserRole.CUSTOMER);
  }

  buildCreateDTO(): IEmployeeRequestDTO {
    if (this.isEmployee()) {
      return this.buildEmployeeCreateDTO();
    }

    if (this.isCustomer()) {
      return this.buildCustomerCreateDTO();
    }

    return this.buildBaseCreateDTO();
  }

  buildUpdateDTO(): IEmployeeRequestDTO {
    if (this.isEmployee()) {
      return this.buildEmployeeUpdateDTO();
    }

    if (this.isCustomer()) {
      return this.buildCustomerUpdateDTO();
    }

    return this.buildBaseUpdateDto();
  }

  private static getAvatarByRoles(roles: UserRole[]): string {
    const userRole = ModuleHelper.getUserRole(roles);

    switch (userRole) {
      case UserRole.ADVISOR:
        return Images.ADVISOR;
      case UserRole.TECHNICIAN:
        return Images.TECHNICIAN;
      case UserRole.SALESPERSON:
        return Images.SALESPERSON;
      default:
        return "";
    }
  }

  private buildBaseCreateDTO(): IEmployeeRequestDTO {
    return {
      email: this.email,
      roles: this.roles,
      username: this.username,
      lastName: this.lastName,
      firstName: this.firstName,
      externalRefId: this.externalRefId,
      mobileNumber: Validation.phoneFormatter(this.mobileNumber),
    };
  }

  private buildEmployeeCreateDTO(): IEmployeeRequestDTO {
    return {
      email: this.email,
      roles: this.roles,
      username: this.username,
      lastName: this.lastName,
      firstName: this.firstName,
      externalRefId: this.externalRefId,
      additionalEmployeeInfo: this.additionalEmployeeInfo.toCreateDTO(),
      mobileNumber: Validation.phoneFormatter(this.mobileNumber),
    };
  }

  private buildCustomerCreateDTO(): IEmployeeRequestDTO {
    return {
      roles: this.roles,
      lastName: this.lastName,
      firstName: this.firstName,
      email: Utils.getIfTrue(!isEmpty(this.email), this.email),
      mobileNumber: Validation.phoneFormatter(this.mobileNumber),
    };
  }

  private buildBaseUpdateDto(): IEmployeeRequestDTO {
    return {
      userId: this._id,
      email: this.email,
      roles: this.roles,
      username: this.username,
      lastName: this.lastName,
      firstName: this.firstName,
      externalRefId: this.externalRefId,
      mobileNumber: Validation.phoneFormatter(this.mobileNumber),
    };
  }

  private buildEmployeeUpdateDTO(): IEmployeeRequestDTO {
    return {
      userId: this.id,
      email: this.email,
      roles: this.roles,
      username: this.username,
      lastName: this.lastName,
      firstName: this.firstName,
      externalRefId: this.externalRefId,
      additionalEmployeeInfo: this.additionalEmployeeInfo.toUpdateDTO(),
      mobileNumber: Validation.phoneFormatter(this.mobileNumber),
    };
  }

  private buildCustomerUpdateDTO(): IEmployeeRequestDTO {
    return {
      userId: this.id,
      email: this.email,
      roles: this.roles,
      lastName: this.lastName,
      firstName: this.firstName,
      mobileNumber: Validation.phoneFormatter(this.mobileNumber),
    };
  }
}
