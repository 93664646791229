import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    "&&": {
      "& .sendbird-chat-header__right__info.sendbird-iconbutton": {
        display: "none",
      },
      "& .sendbird-thumbnail-message-item-body": {
        ["@media (max-width:550px)"]: {
          minWidth: 200,
        },
      },
    },
  },
  searchCollapseContainer: {
    "&&": {
      "& .MuiCollapse-horizontal": {
        width: "100%",
      },
    },
  },
}));
