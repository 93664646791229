import * as Yup from "yup";
import { isEmpty } from "lodash";
import { AnyObject, Maybe } from "yup/es/types";

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends Yup.BaseSchema<TType, TContext, TOut> {
    notNA(errorMessage?: string): StringSchema<TType, TContext>;
    equalTo(ref: any, msg?: string): StringSchema<TType, TContext>;
    username(errorMessage: string): StringSchema<TType, TContext>;
    employeeId(errorMessage: string): StringSchema<TType, TContext>;
    mobileNumber(errorMessage: string): StringSchema<TType, TContext>;
  }
}

const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();

export default class Validation {
  public readonly Yup = Yup;

  constructor() {
    this.initYup();
  }

  private initYup() {
    this.Yup.addMethod(Yup.string, "equalTo", function equalTo(ref, msg) {
      return this.test({
        name: "equalTo",
        exclusive: false,
        message: msg || "${path} must be the same as ${reference}",
        params: {
          reference: ref.path,
        },
        test: function (value) {
          return value === this.resolve(ref);
        },
      });
    });

    this.Yup.addMethod(Yup.string, "notNA", function notNA(msg) {
      return this.test({
        name: "notNA",
        exclusive: false,
        message: msg || "${path} must be not equal to n/a",
        test: function (value) {
          return value?.toLowerCase() !== "n/a";
        },
      });
    });

    this.Yup.addMethod(Yup.string, "mobileNumber", function (errorMessage) {
      return this.test(`mobile-number-test`, errorMessage, function (value) {
        const { path, createError } = this;

        if (!value || isEmpty(value)) {
          return true;
        }

        if (value.length < 10) {
          return createError({ path, message: errorMessage });
        }

        if (!Validation.isValidPhoneNumber(value)) {
          return createError({ path, message: errorMessage });
        }

        return true;
      });
    });
  }

  public static isValidPhoneNumber(phone?: string): boolean {
    try {
      const number = phoneUtil.parse(phone, "US");
      return (
        phoneUtil.isValidNumber(number) || phoneUtil.isPossibleNumber(number)
      );
    } catch (error) {
      return false;
    }
  }

  public static phoneFormatter(phone?: string): string {
    try {
      const number = phoneUtil.parse(phone, "US");

      return phoneUtil.format(number, PNF.NATIONAL);
    } catch (error) {
      return `${phone}`;
    }
  }
}
