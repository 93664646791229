import React from "react";
import { useStyles } from "./MediaCarousel.styles";
import { useState } from "react";
import ReactPlayer from "react-player/lazy";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { IFile } from "../../models/file";

interface IProps {
  files: IFile[];
}
const MediaCarousel = (props: IProps): JSX.Element => {
  const classes = useStyles();

  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);

  const videoRender = (url: string, index: number): React.ReactElement => {
    if (index !== activeIndex) {
      return <React.Fragment key={index} />;
    }

    return (
      <ReactPlayer
        key={index}
        url={url}
        loop={true}
        width={"100%"}
        controls={true}
        style={{ paddingLeft: 28, paddingRight: 28 }}
      />
    );
  };

  const imageRender = (url: string, index: number): React.ReactElement => {
    if (index !== activeIndex) {
      return <React.Fragment key={index} />;
    }

    return (
      <img
        alt={""}
        src={url}
        key={index}
        width={"100%"}
        style={{ objectFit: "contain" }}
      />
    );
  };

  const contentRender = (item: IFile, index: number): React.ReactElement => {
    if (item.fileType.includes("image")) {
      return imageRender(item.publicURL, index);
    }

    return videoRender(item.publicURL, index);
  };

  const render = (): React.ReactElement => {
    return (
      <div className={classes.videoDiv}>
        <Carousel
          autoPlay={false}
          showArrows={true}
          showThumbs={false}
          showIndicators={true}
          dynamicHeight={true}
          showStatus={true}
          onChange={(next) => setActiveIndex(next)}
        >
          {props.files.map(contentRender)}
        </Carousel>
      </div>
    );
  };

  return render();
};

export default MediaCarousel;
