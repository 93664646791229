import React from "react";
import TechnicianStatistics from "../../components/statistics/technician/TechnicianStatistics";
import AdvisorStatistics from "../../components/statistics/advisor/AdvisorStatistics";
import UserService from "../../services/User.service";
import { Box } from "@mui/material";
import SellerStatistics from "../../components/statistics/seller/SellerStatistics";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../../../index";
import VisibleContent from "../../components/visable_content/VisibleContent";

const userService = new UserService();

const AdminOverview = (): React.ReactElement => {
  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Box sx={{ marginBottom: 1 }}>
          <TechnicianStatistics userService={userService} />
        </Box>
        <Box sx={{ marginBottom: 1 }}>
          <AdvisorStatistics userService={userService} />
        </Box>
        <VisibleContent visible={dealerContext.connectedToCrm}>
          <Box>
            <SellerStatistics userService={userService} />
          </Box>
        </VisibleContent>
      </React.Fragment>
    );
  };

  return render();
};

export default AdminOverview;
