import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent !important",
  },
  recommendedText: {
    fontWeight: "bold",
    marginBottom: 20,
  },
  homeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#ffffff",
    borderRadius: "20px",
    padding: "40px 0px",
    position: "relative",
    minHeight: "100%",
    height: "fit-content",
  },
  inProgressDot: {
    width: "100% !important",
    height: "10px !important",
    borderRadius: "20px !important",
    backgroundColor: "#FC6C09 !important",
    opacity: 0.2,
    "&:nth-child(1)": {
      opacity: 1,
    },
  },
  completedDot: {
    width: "100% !important",
    height: "10px !important",
    borderRadius: "20px !important",
    backgroundColor: "#FC6C09 !important",
    opacity: 0.2,
    "&:nth-child(n)": {
      opacity: 1,
    },
  },
  dot: {
    width: "100% !important",
    height: "10px !important",
    borderRadius: "20px !important",
    backgroundColor: "#FC6C09 !important",
    opacity: 0.2,
    // "&:first-child": {
    //   borderTopLeftRadius: "20px !important",
    //   borderBottomLeftRadius: "20px !important",
    // },
    // "&:last-child": {
    //   borderTopRightRadius: "20px !important",
    //   borderBottomRightRadius: "20px !important",
    // },
  },

  lineItemOpCodes: {
    borderColor: "#F3F2F4",
    margin: 15,
    border: "3px solid #F3F2F4",
    borderWidth: 1,
    padding: 15,
    marginTop: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textTransform: "capitalize",
  },
  statusText: {
    fontSize: 10,
    paddingTop: 5,
  },
  laborOpCodesItem: {
    borderColor: "#F3F2F4",
    borderRadius: 5,
    marginTop: 5,
    border: "2px solid #F3F2F4",
    padding: 8,
    marginBottom: 5,
    display: "flex",
    justifyContent: "space-between",
  },
  repairOrderText: {
    padding: 5,
    paddingTop: 15,
    fontSize: 14,
    fontWeight: "bold",
  },
  dotDetail: {
    width: "100% !important",
    height: "22px !important",
    borderRadius: "0px !important",
    backgroundColor: "#FC6C09 !important",
    "&:first-child": {
      borderTopLeftRadius: "20px !important",
      borderBottomLeftRadius: "20px !important",
    },
    "&:last-child": {
      borderTopRightRadius: "20px !important",
      borderBottomRightRadius: "20px !important",
    },
  },
  dotActive: {
    backgroundColor: "#FC6C09 !important",
    opacity: 1,
  },
  dots: {
    width: "100% !important",
  },
  imageHeight: {
    width: "100%",
  },
  statusLabel: {
    opacity: "50%",
  },
  statusValue: {
    fontSize: 14,
    fontWeight: 400,
  },
  recommendedWorkDiv: {
    border: "1px solid #EB5757",
    marginBottom: 10,
    borderRadius: 10,
    height: 120,
    padding: 10,
  },
  detailsButton: {
    "&&": {
      display: "flex",
      justifyContent: "flex-start",
      width: "100%",
      height: "56px",
      borderRadius: "15px",
      backgroundColor: "rgba(253, 106, 8, 0.1)",
      textTransform: "none",
      columnGap: "10%",
      fontSize: "16px",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#fd7e08",
        borderColor: "#0062cc",
        boxShadow: "none",
        color: "#ffffff",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: "#fd7e08",
        borderColor: "#005cbf",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
    },
  },
  stepperGrid: {
    padding: "0px 0px",
  },
}));
