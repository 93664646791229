import moment from "moment-timezone";
import { IConfirmDialog, IGrowl, ISelectOption } from "../../index";
import { defaultValueRegex } from "./constants";
import { divide, multiply, get, isEmpty, defaultTo, map } from "lodash";
import { StringBool } from "../types/types";
import { UserRole } from "../common/Enums";
import store from "../config/store";
import i18next from "i18next";
import { AxiosError } from "axios";
import { IGlobalLoader } from "../components/loader";

export default class ModuleHelper {
  public static grow: IGrowl;
  public static loader: IGlobalLoader;
  public static confirmDialog: IConfirmDialog;

  public static getTimezone(): string {
    return moment.tz.guess();
  }

  // Filter out only main US timezones
  public static getTimezones = (): ISelectOption[] => {
    const abbrs: Record<string, unknown> = {
      EST: "Eastern Time",
      CST: "Central Time",
      MST: "Mountain Time",
      PST: "Pacific Time",
      // HST: "Hawaii Time", // Uncomment in case there are dealerships, located in that timezone.
      // AKDT: "Alaska Time", // Uncomment in case there are dealerships, located in that timezone.
    };
    return Object.keys(abbrs).map((item) => {
      return {
        label: `${abbrs[item]}`,
        value: item,
      };
    });
  };

  public static validateValue(value: string, regex: RegExp): boolean {
    if (isEmpty(value)) {
      return false;
    }

    const isValid = regex.test(value);
    const isValidDefaultValue = defaultValueRegex.test(value);
    return !(!isValid && !isValidDefaultValue);
  }

  public static stringBool(str?: StringBool): boolean {
    if (str === "false_") {
      return false;
    }

    return str === "true_";
  }

  public static boolean(bool: boolean): StringBool {
    if (bool) {
      return "true_";
    }

    return "false_";
  }

  public static stringFlagOpposite(str: StringBool): StringBool {
    if (str === "false_") {
      return "true_";
    }

    return "false_";
  }

  public static getValueIfDefault(val1: string, val2: string): string {
    if (!val1 || val1 === "n/a") {
      return val2;
    }

    return val1;
  }

  public static centToDollar(cent?: number): number {
    return divide(defaultTo(cent, 0), 100);
  }

  public static centsToDollars(cents?: number[]): number[] {
    return map(cents, (cent) => ModuleHelper.centToDollar(cent));
  }

  public static dollarToCent(dollar: number): number {
    return multiply(dollar, 100);
  }

  public static dollarsToCents(dollars?: number[]): number[] {
    return map(dollars, (dollar) => ModuleHelper.dollarToCent(dollar));
  }

  public static round(number: number): number {
    return Math.round(number * 100) / 100;
  }

  public static isAdmin = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!(
      roles.includes(UserRole.USER_MANAGER) ||
      roles.includes(UserRole.ADMIN) ||
      roles.includes(UserRole.SUPER_ADMIN)
    );
  };

  public static isSuperAdmin = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!roles.includes(UserRole.SUPER_ADMIN);
  };

  public static isUserManager = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!roles.includes(UserRole.USER_MANAGER);
  };

  public static isAdvisor = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!roles.includes(UserRole.ADVISOR);
  };

  public static isTechnician = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!roles.includes(UserRole.TECHNICIAN);
  };

  public static isEmployee = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!(
      roles.includes(UserRole.ADVISOR) ||
      roles.includes(UserRole.TECHNICIAN) ||
      roles.includes(UserRole.SALESPERSON)
    );
  };

  public static isCustomer = (): boolean => {
    const auth = store.getState().auth;

    const roles = defaultTo(get(auth, "user.roles"), []);

    return !!roles.includes(UserRole.CUSTOMER);
  };

  public static isSalesPerson(roles?: UserRole[]): boolean {
    if (!Array.isArray(roles)) {
      return false;
    }
    return !!roles.includes(UserRole.SALESPERSON);
  }

  public static isCustomerByRoles = (roles?: UserRole[]): boolean => {
    if (!Array.isArray(roles)) {
      return false;
    }

    return roles.includes(UserRole.CUSTOMER);
  };

  public static getRoleRank = (roles: UserRole[]): number => {
    const role = ModuleHelper.getUserRole(roles);

    return Object.values(UserRole).indexOf(role);
  };

  public static getMyRoleRank = (): number => {
    return ModuleHelper.getRoleRank(store.getState().auth.user.roles);
  };

  public static getUserRole(roles: UserRole[]): UserRole {
    for (const role in UserRole) {
      if (UserRole.hasOwnProperty(role)) {
        if (roles.includes(role as UserRole)) {
          return role as UserRole;
        }
      }
    }

    return UserRole.UNKNOWN;
  }

  public static getErrorMessage(error: AxiosError): string {
    const message =
      get(error, "response.data.error_description") ??
      get(error, "response.data.message");

    if (!isEmpty(message)) {
      return message;
    }

    return i18next.t("common.defaultErrorMessage");
  }
}
