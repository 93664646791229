import React, { useEffect, useState } from "react";
import {
  INotificationEventSettings,
  INotificationMessages,
} from "../../../../index";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import DealershipService from "../../../services/Dealership.service";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Placeholders, UserRole } from "../../../common/Enums";
import { PromptIfDirty } from "../../../components/prompt/PromptIfDirty";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import ModuleHelper from "../../../util/ModuleHelper";
import { get } from "lodash";
import AutoSuggest from "../../../components/autosuggest/AutoSuggest";
import { useGlobalStyles } from "../../../util/ThemePalette";
import PaperWrapper from "../../../components/paper/PaperWrapper";
import { capitalCase } from "change-case";
import { StringBool } from "../../../types/types";

interface IProps {
  readonly saveDisabled?: boolean;
  readonly dealershipService: DealershipService;
  readonly notificationMessages: INotificationMessages;
  readonly notificationEvents: INotificationEventSettings;
}

export interface IInitialValues {
  notificationMessages: INotificationMessages;
  notificationEvents: INotificationEventSettings;
}

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
});

const validationSchema = Yup.object().shape({});

const NotificationEvents = (props: IProps): React.ReactElement => {
  const classes = useGlobalStyles();
  const { t } = useTranslation();

  const [refreshKey, setRefreshKey] = useState(Date.now());

  const [initialValues, setInitialValues] = useState<IInitialValues>({
    notificationEvents: props.notificationEvents,
    notificationMessages: props.notificationMessages,
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setInitialValues({
      notificationEvents: props.notificationEvents,
      notificationMessages: props.notificationMessages,
    });
  }, [props.notificationEvents, props.notificationMessages]);

  useEffect(() => {
    setRefreshKey(Date.now());
  }, [initialValues]);

  const onSubmit = (values: IInitialValues, submitProps: any): void => {
    setLoading(true);
    props.dealershipService
      .updateNotificationDetails(values)
      .then((response) => {
        const data: IInitialValues = {
          notificationEvents: response.data.notificationEventsSettings,
          notificationMessages: response.data.notificationMessages,
        };

        setInitialValues(data);
        submitProps.resetForm({ data });
        ModuleHelper.grow.success(
          t("notificationSettings.updatedSuccessfully")
        );
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const formRender = (formikProps: FormikProps<IInitialValues>) => {
    const roles = Object.keys(
      formikProps.values.notificationEvents
    ) as UserRole[];
    const events = Object.keys(formikProps.values.notificationMessages);

    return (
      <Form noValidate autoComplete="on">
        <PaperWrapper
          header={"EVENTS"}
          rightHeader={
            <SubmitButton
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              disabled={props.saveDisabled}
              className={classes.saveButton}
            >
              Save
            </SubmitButton>
          }
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={"20%"}>Event</TableCell>
                  {roles.map((role) => (
                    <TableCell width={100} key={role}>
                      {role}
                    </TableCell>
                  ))}
                  <TableCell>{t("common.MESSAGE")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((key) => (
                  <RowRender
                    key={key}
                    field={key}
                    roles={roles}
                    colName={capitalCase(key)}
                    formikProps={formikProps}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PaperWrapper>
        <PromptIfDirty message={t("common.unsavedChanges")} />
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Formik
          key={refreshKey}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {formRender}
        </Formik>
      </React.Fragment>
    );
  };

  return render();
};

export default NotificationEvents;

const RowRender = (props: {
  field: string;
  colName: string;
  roles: UserRole[];
  formikProps: FormikProps<IInitialValues>;
}): React.ReactElement => {
  const handleChange =
    (formikProps: FormikProps<IInitialValues>, path: string) =>
    async (value: any) => {
      formikProps.setFieldValue(path, value, false);
    };

  const render = (): React.ReactElement => {
    const { field, roles, colName, formikProps } = props;
    const message = get(formikProps.values.notificationMessages, field, "");

    return (
      <TableRow>
        <TableCell component="th" scope="row">
          {colName}
        </TableCell>
        {roles.map((role) => (
          <CellRender
            key={role}
            checked={ModuleHelper.stringBool(
              get(
                formikProps.values.notificationEvents,
                `${role}.${field}.isTurnedOn`,
                "false_"
              )
            )}
            disabled={
              !ModuleHelper.stringBool(
                get(
                  formikProps.values.notificationEvents,
                  `${role}.${field}.isConfigurable`,
                  "false_"
                )
              )
            }
            onChange={handleChange(
              formikProps,
              `notificationEvents.${role}.${field}.isTurnedOn`
            )}
          />
        ))}
        <TableCell component="th" scope="row">
          <AutoSuggest
            value={message}
            label={"Message"}
            trigger={["<"]}
            onChange={handleChange(
              formikProps,
              `notificationMessages.${field}`
            )}
            options={Object.values([
              Placeholders.RO_NO,
              Placeholders.CAR_MODEL,
              Placeholders.TIP_AMOUNT,
              Placeholders.DEALER_NAME,
              Placeholders.REVIEW_SCORE,
              Placeholders.TECH_FIRST_NAME,
              Placeholders.CUSTOMER_FIRST_NAME,
              Placeholders.SALESPERSON_FIRST_NAME,
            ])}
          />
        </TableCell>
      </TableRow>
    );
  };

  return render();
};

const CellRender = (props: {
  checked: boolean;
  disabled: boolean;
  onChange(checked: StringBool): void;
}): React.ReactElement => {
  const [checked, setChecked] = useState(props.checked);

  const onChange = (): void => {
    setChecked(!checked);
    props.onChange(ModuleHelper.boolean(!checked));
  };

  const render = (): React.ReactElement => {
    return (
      <TableCell component="th" scope="row">
        <Checkbox
          size={"medium"}
          checked={checked}
          disabled={props.disabled}
          onChange={onChange}
        />
      </TableCell>
    );
  };

  return render();
};
