import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Avatar, Typography, Button } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import {
  DataGrid,
  GridRenderCellParams,
  GridFilterItem,
} from "@mui/x-data-grid";

import { defaultPageSize } from "../../../util/constants";
import ModuleHelper from "../../../util/ModuleHelper";
import { IStore } from "../../../../index";
import UserService from "../../../services/User.service";
import { useGlobalStyles } from "../../../util/ThemePalette";
import PaperWrapper from "../../paper/PaperWrapper";
import CustomHeader from "../../custom_grid_header/CustomHeader";
import {
  IAdvisorStatistic,
  ITechnicianStatistic,
} from "../../../models/statistics";

interface IProps {
  userService: UserService;
}

const TechnicianStatistics = (props: IProps): React.ReactElement => {
  const reviews = "Reviews";
  const additionalRevenue = "Additional Revenue";
  const recommendedServices = "Recommended Services";

  const classes = useGlobalStyles();

  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pending, setPending] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<ITechnicianStatistic[]>([]);
  const [filter, setFilter] = useState<GridFilterItem[]>([]);
  const [showResetFilter, setShowResetFilter] = useState<boolean>(false);

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  useEffect(() => {
    setPending(true);
    props.userService
      .getTechnicianStatistics()
      .then((response) => {
        setStatistics(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setPending(false));
  }, [dealerContext]);

  const moneyCellRender = (
    params: GridRenderCellParams
  ): React.ReactElement => (
    <React.Fragment>
      <AttachMoneyIcon sx={{ fontSize: 15 }} color={"primary"} />
      <Typography>{params.value}</Typography>
    </React.Fragment>
  );

  const handleFilterChange = (e: any) => {
    setFilter(e.items);
    e.items[0].value ? setShowResetFilter(true) : setShowResetFilter(false);
  };

  const handleFilterReset = (e: any): void => {
    e.stopPropagation();
    setFilter([]);
    setShowResetFilter(false);
  };

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IAdvisorStatistic>) => {
        return (
          <section style={{ display: "flex" }}>
            <Avatar
              src={`${params.row?.employee.avatarURL}`}
              alt={params.row?.employee.firstName}
            />
            <Typography
              style={{ marginTop: 12, marginLeft: 5, whiteSpace: "initial" }}
            >
              {params.row?.employee.firstName} {params.row?.employee.lastName}
            </Typography>
          </section>
        );
      },
      headerAlign: "center",
      valueGetter: (e) =>
        e.row.employee.firstName + " " + e.row.employee.lastName,
      sortComparator: (v1, v2) => v1.localeCompare(v2),
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "isCredible",
      headerName: "Trusted",
      minWidth: 130,
      flex: 1,
      type: "boolean",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "picturesCreated",
      headerName: "Pictures Uploaded",
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "videosCreated",
      headerName: "Videos Uploaded",
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "recommendedWorksCreated",
      headerName: "RS Created",
      description: `${recommendedServices} (Created)`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "recommendedWorksSentToCustomer",
      headerName: "RS Sent",
      description: `${recommendedServices} Sent`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "recommendedWorksApprovedByCustomer",
      headerName: "RS Approved",
      description: `${recommendedServices} Approved`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "recommendedWorksDeclinedByCustomer",
      headerName: "RS Declined",
      description: `${recommendedServices} Declined`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "recommendedWorksPendingCustomerResponse",
      headerName: "RS Pending",
      description: `${recommendedServices} Pending`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "recommendedWorksSuccessRate",
      headerName: "RS Success Rate",
      description: `${recommendedServices} (Success Rate)`,
      minWidth: 130,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "additionalCustomerPayedRevenue",
      headerName: "AR Customer",
      description: `${additionalRevenue} (Customer Paid)`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: moneyCellRender,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "additionalWarrantyRevenue",
      headerName: "AR Warranty",
      description: `${additionalRevenue} (Warranty)`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: moneyCellRender,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "revenueTotal",
      headerName: "AR Total",
      description: `${additionalRevenue} (Total)`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: moneyCellRender,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "reviewsReceived",
      headerName: "Reviews",
      description: `${reviews} Received`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "averageScore",
      headerName: "Average Score",
      description: `${reviews} (Average Score)`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
    {
      field: "totalTipAmount",
      headerName: "Total Tip",
      description: `Total Tip Amount`,
      minWidth: 130,
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      renderCell: moneyCellRender,
      renderHeader: (params) => (
        <CustomHeader
          params={params}
          reset={handleFilterReset}
          filter={filter}
        />
      ),
    },
  ];

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper
        header={"TECHNICIANS"}
        rightHeader={
          <Button
            variant={"contained"}
            startIcon={<FilterAltOffIcon />}
            onClick={handleFilterReset}
            style={{ display: showResetFilter ? "flex" : "none" }}
          >
            Reset
          </Button>
        }
      >
        <DataGrid
          rows={statistics}
          columns={columns}
          loading={pending}
          autoHeight={true}
          pageSize={pageSize}
          headerHeight={80}
          className={classes.grid}
          onPageSizeChange={setPageSize}
          onFilterModelChange={handleFilterChange}
          filterModel={{ items: filter }}
        />
      </PaperWrapper>
    );
  };

  return render();
};

export default TechnicianStatistics;
