import { IAdvisorStatistic } from "./index";
import { IBaseUser } from "../user";
import { IAdvisorStatisticsDTO } from "../../../index";
import Utils from "../../util/Utils";
import ModuleHelper from "../../util/ModuleHelper";
import { divide, multiply } from "lodash";
import Advisor from "../user/Advisor";

export default class AdvisorStatistic implements IAdvisorStatistic {
  private _id: number;
  private _additionalCustomerPayedRevenue: number;
  private _additionalWarrantyRevenue: number;
  private _employee: IBaseUser;
  private _recommendedWorksApprovedByAdvisor: number;
  private _recommendedWorksApprovedByCustomer: number;
  private _recommendedWorksDeclinedByCustomer: number;
  private _recommendedWorksPendingCustomerResponse: number;
  private _relatedToOpenRo: number;
  private _recommendedWorksSuccessRate: string;

  constructor(args?: IAdvisorStatisticsDTO) {
    this._employee = new Advisor(args?.employee);
    this._id = Utils.default(args?.employee?.id, 0);
    this._additionalCustomerPayedRevenue = ModuleHelper.centToDollar(
      Utils.default(args?.additionalCustomerPayedRevenue, 0)
    );
    this._additionalWarrantyRevenue = ModuleHelper.centToDollar(
      Utils.default(args?.additionalWarrantyRevenue, 0)
    );
    this._recommendedWorksApprovedByAdvisor = Utils.default(
      args?.recommendedWorksApprovedByAdvisor,
      0
    );
    this._recommendedWorksApprovedByCustomer = Utils.default(
      args?.recommendedWorksApprovedByCustomer,
      0
    );
    this._recommendedWorksDeclinedByCustomer = Utils.default(
      args?.recommendedWorksDeclinedByCustomer,
      0
    );
    this._recommendedWorksPendingCustomerResponse = Utils.default(
      args?.recommendedWorksPendingCustomerResponse,
      0
    );
    this._relatedToOpenRo = Utils.default(args?.relatedToOpenRo, 0);

    this._recommendedWorksSuccessRate = `${Number(
      this.getSuccessRate()
    ).toFixed(2)}%`;
  }

  private getSuccessRate(): number {
    return multiply(
      divide(
        this._recommendedWorksApprovedByCustomer,
        Math.max(
          this._recommendedWorksApprovedByAdvisor -
            this._recommendedWorksPendingCustomerResponse,
          1
        )
      ),
      100
    );
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get additionalCustomerPayedRevenue(): number {
    return this._additionalCustomerPayedRevenue;
  }

  set additionalCustomerPayedRevenue(value: number) {
    this._additionalCustomerPayedRevenue = value;
  }

  get additionalWarrantyRevenue(): number {
    return this._additionalWarrantyRevenue;
  }

  set additionalWarrantyRevenue(value: number) {
    this._additionalWarrantyRevenue = value;
  }

  get employee(): IBaseUser {
    return this._employee;
  }

  set employee(value: IBaseUser) {
    this._employee = value;
  }

  get recommendedWorksApprovedByAdvisor(): number {
    return this._recommendedWorksApprovedByAdvisor;
  }

  set recommendedWorksApprovedByAdvisor(value: number) {
    this._recommendedWorksApprovedByAdvisor = value;
  }

  get recommendedWorksApprovedByCustomer(): number {
    return this._recommendedWorksApprovedByCustomer;
  }

  set recommendedWorksApprovedByCustomer(value: number) {
    this._recommendedWorksApprovedByCustomer = value;
  }

  get recommendedWorksDeclinedByCustomer(): number {
    return this._recommendedWorksDeclinedByCustomer;
  }

  set recommendedWorksDeclinedByCustomer(value: number) {
    this._recommendedWorksDeclinedByCustomer = value;
  }

  get recommendedWorksPendingCustomerResponse(): number {
    return this._recommendedWorksPendingCustomerResponse;
  }

  set recommendedWorksPendingCustomerResponse(value: number) {
    this._recommendedWorksPendingCustomerResponse = value;
  }

  get relatedToOpenRo(): number {
    return this._relatedToOpenRo;
  }

  set relatedToOpenRo(value: number) {
    this._relatedToOpenRo = value;
  }

  get recommendedWorksSuccessRate(): string {
    return this._recommendedWorksSuccessRate;
  }

  set recommendedWorksSuccessRate(value: string) {
    this._recommendedWorksSuccessRate = value;
  }
}
