import React, { CSSProperties } from "react";

interface IProps {
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Logo = (props: IProps): React.ReactElement => {
  return (
    <img
      src={"../images/logo.png"}
      width={props.width ?? "50px"}
      height={props.height ?? "50px"}
      style={props.style}
      className={props.className}
      alt={"Servue"}
    />
  );
};

export default Logo;
