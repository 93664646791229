import React, { useEffect, useState } from "react";
import DealershipService from "../../../services/Dealership.service";
import {
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ModuleHelper from "../../../util/ModuleHelper";
import { Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import SaveIcon from "@mui/icons-material/Save";
import { FormTextField } from "../../../components/shared/form_text_field/FormTextField";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { IStore } from "../../../../index";
import { useTranslation } from "react-i18next";
import { Placeholders, UserRole } from "../../../common/Enums";
import { PromptIfDirty } from "../../../components/prompt/PromptIfDirty";
import FormTagField from "../../../components/shared/form_tags_field/FormTagField";
import { shallowEqual, useSelector } from "react-redux";
import { useGlobalStyles } from "../../../util/ThemePalette";
import PaperWrapper from "../../../components/paper/PaperWrapper";
import DealershipConfig from "../../../models/dealership_config/DealershipConfig";
import AutoSuggest from "../../../components/autosuggest/AutoSuggest";
import VisibleContent from "../../../components/visable_content/VisibleContent";

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
});

const validationSchema = Yup.object().shape({
  _reviewLowScoreEmailList: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `"${value}" is not a valid email. `)),
  _reviewLowScoreValue: Yup.number()
    .min(1, "Range [1-5]")
    .max(5, "Range [1-5]"),
  _reviewRemindCustomerDays: Yup.number()
    .min(0, "Range [0-6]")
    .max(6, "Range [0-6]"),
  _vehicleSoldNotificationTime: Yup.number()
    .min(0, "Range [0-23]")
    .max(23, "Range [0-23]"),
  _reviewRemindCustomerHours: Yup.number()
    .min(0, "Range [0-23]")
    .max(23, "Range [0-23]"),
  _notificationDuration: Yup.number()
    .min(0, "Range [0-30]")
    .max(30, "Range [0-30]"),
  _tipMaxAmountInDollars: Yup.number()
    .min(0, "Range [0-1000]")
    .max(1000, "Range [0-1000]"),
  _tipMinAmountInDollars: Yup.number()
    .min(0, "Range [0-5]")
    .max(5, "Range [0-5]"),
  _askCustomerToLeaveTipAfterScore: Yup.number()
    .min(1, "Range [0-5]")
    .max(5, "Range [0-5]"),
  _tipIncrementationInDollars: Yup.array(),
});

const DealershipSettings = (): React.ReactElement => {
  const dealershipService = new DealershipService();

  const classes = useGlobalStyles();
  const { t } = useTranslation();

  const [pending, setPending] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [refreshKey, setRefreshKey] = useState(Date.now());

  const [config, setConfig] = useState<DealershipConfig>(
    new DealershipConfig()
  );

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  useEffect(() => {
    setRefreshKey(Date.now);
  }, [config]);

  useEffect(() => {
    setPending(true);
    ModuleHelper.loader.show();
    dealershipService
      .getDealershipConfig()
      .then((response) => {
        setConfig(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => {
        setPending(false);
        ModuleHelper.loader.hide();
      });
  }, [dealerContext]);

  const onSubmit = (values: DealershipConfig, submitProps: any): void => {
    setLoading(true);
    dealershipService
      .updateDealershipConfig(values)
      .then((response) => {
        setConfig(response);
        submitProps.resetForm({ response });
        ModuleHelper.grow.success(t("dealership.configUpdatedSuccessfully"));
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const handleTipWhoReceiveChange =
    (field: string, value: UserRole, formikProps: FormikProps<any>) =>
    (event: any) => {
      event.stopPropagation();
      const values = formikProps.values[field] as UserRole[];

      const index = values.findIndex((role) => role === value);

      if (index !== -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }

      formikProps.setFieldValue(field, values);
    };

  const handleCheckboxChange =
    (field: string, formikProps: FormikProps<any>) => (event: any) => {
      event.stopPropagation();
      formikProps.setFieldValue(field, !formikProps.values[field]);
    };

  const formRender = (
    formikProps: FormikProps<DealershipConfig>
  ): React.ReactElement => {
    const { values, setFieldValue } = formikProps;

    return (
      <Form noValidate autoComplete="on">
        <PaperWrapper
          rightHeader={
            <SubmitButton
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              disabled={pending}
              className={classes.saveButton}
            >
              {t("common.save")}
            </SubmitButton>
          }
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "30%" }} />
                  <TableCell style={{ width: "50%" }}>
                    {t("common.value")}
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    {t("common.description")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <VisibleContent visible={values.showReviewReminder()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.remindCustomerToLeaveAfterReview")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"_reviewRemindCustomerDays"}
                            size="small"
                            label="Days"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0, max: 6 } }}
                          />
                        </Grid>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"_reviewRemindCustomerHours"}
                            size="small"
                            label="Hours"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0, max: 23 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent
                  visible={values.showVehicleSoldNotificationTime()}
                >
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.vehicleSoldNotificationAMTime")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"_vehicleSoldNotificationTime"}
                            size="small"
                            label="Hours"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0, max: 23 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showNotificationDuration()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.sendUnseenMessageNotification")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"_notificationDuration"}
                            size="small"
                            label="Minutes"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0, max: 30 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showTipWhoReceive()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.whoCanReceiveTip")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label="Technician"
                          color={
                            values.tipWhoReceive.some(
                              (role) => role === UserRole.TECHNICIAN
                            )
                              ? "primary"
                              : "default"
                          }
                          className={classes.chip}
                          onClick={handleTipWhoReceiveChange(
                            "_tipWhoReceive",
                            UserRole.TECHNICIAN,
                            formikProps
                          )}
                        />
                        <Chip
                          label="Advisor"
                          color={
                            values.tipWhoReceive.some(
                              (role) => role === UserRole.ADVISOR
                            )
                              ? "primary"
                              : "default"
                          }
                          className={classes.chip}
                          onClick={handleTipWhoReceiveChange(
                            "_tipWhoReceive",
                            UserRole.ADVISOR,
                            formikProps
                          )}
                        />
                        <Chip
                          label="Salesman"
                          color={
                            values.tipWhoReceive.some(
                              (role) => role === UserRole.SALESPERSON
                            )
                              ? "primary"
                              : "default"
                          }
                          className={classes.chip}
                          onClick={handleTipWhoReceiveChange(
                            "_tipWhoReceive",
                            UserRole.SALESPERSON,
                            formikProps
                          )}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showMessageSendToCustomer()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.canMessageCustomer")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label="Technician"
                          color={
                            values.messageSendToCustomer.some(
                              (role) => role === UserRole.TECHNICIAN
                            )
                              ? "primary"
                              : "default"
                          }
                          className={classes.chip}
                          onClick={handleTipWhoReceiveChange(
                            "_messageSendToCustomer",
                            UserRole.TECHNICIAN,
                            formikProps
                          )}
                        />
                        <Chip
                          label="Advisor"
                          color={
                            values.messageSendToCustomer.some(
                              (role) => role === UserRole.ADVISOR
                            )
                              ? "primary"
                              : "default"
                          }
                          className={classes.chip}
                          onClick={handleTipWhoReceiveChange(
                            "_messageSendToCustomer",
                            UserRole.ADVISOR,
                            formikProps
                          )}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showTipMaxAmountInDollars()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.theTipMaxAmount")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"_tipMaxAmountInDollars"}
                            size="small"
                            label="$"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0, max: 1000 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showTipMinAmountInDollars()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.theTipMinAmount")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"_tipMinAmountInDollars"}
                            size="small"
                            label="$"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0, max: 5 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent
                  visible={values.showTipIncrementationInDollars()}
                >
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.tipIncrements")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"firstTipIncrementationInDollars"}
                            size="small"
                            label="$"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"secondTipIncrementationInDollars"}
                            size="small"
                            label="$"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"thirdTipIncrementationInDollars"}
                            size="small"
                            label="$"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                        <Grid item lg={3} md={4} xs={12}>
                          <Field
                            name={"forthTipIncrementationInDollars"}
                            size="small"
                            label="$"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showCustomerDefaultName()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.defaultValueForKey", {
                        key: "CustomerFirstName",
                      })}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item md={8} xs={12}>
                          <Field
                            name={"_customerDefaultName"}
                            size="small"
                            label="Customer Name"
                            component={FormTextField}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showDeactivateEmployee()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.deactivateEmployee")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label="Enabled"
                            control={
                              <Checkbox
                                size={"medium"}
                                checked={values.deactivateEmployee}
                                onChange={handleCheckboxChange(
                                  "_deactivateEmployee",
                                  formikProps
                                )}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.deactivateEmployeeDescription")}
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showDeactivateEmployee()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.askCustomerToLeaveTip")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label="Enabled"
                            control={
                              <Checkbox
                                size={"medium"}
                                checked={values.askCustomerToLeaveTip}
                                onChange={handleCheckboxChange(
                                  "_askCustomerToLeaveTip",
                                  formikProps
                                )}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent
                  visible={values.showCheckFetchedFromCdkNotifyCustomerField()}
                >
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t(
                        "dealershipSettings.checkFetchedFromCdkNotifyCustomerField"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label="Enabled"
                            control={
                              <Checkbox
                                size={"medium"}
                                checked={
                                  values.checkFetchedFromCdkNotifyCustomerField
                                }
                                onChange={handleCheckboxChange(
                                  "_checkFetchedFromCdkNotifyCustomerField",
                                  formikProps
                                )}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent
                  visible={values.showAskCustomerToLeaveTipAfterScore()}
                >
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t(
                        "dealershipSettings.sendTipRequestAfterRatingEqualOrGrealterThanThisScore"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Field
                            name={"_askCustomerToLeaveTipAfterScore"}
                            size="small"
                            label="Score"
                            type={"number"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 1, max: 5 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.sendTipRequestValueDescription")}
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showTipTitleMessage()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.tipTitleMessage")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <AutoSuggest
                            value={values.tipTitleMessage}
                            label={"Tip Title Message"}
                            trigger={["<"]}
                            onChange={(value) =>
                              setFieldValue("_tipTitleMessage", value)
                            }
                            options={Object.values([
                              Placeholders.CAR_MODEL,
                              Placeholders.DEALER_NAME,
                              Placeholders.TECH_FIRST_NAME,
                              Placeholders.TECH_LAST_NAME,
                            ])}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showRateTitleMessage()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.rateTitleMessage")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            name={"_rateTitleMessage"}
                            label={t("dealershipSettings.rateTitleMessage")}
                            component={FormTextField}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.rateTitleMessageDescription")}
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showThankYouMessage()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.thankYouScreenMessage")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <AutoSuggest
                            value={values.thankYouMessage}
                            label={"Thank You Screen Message"}
                            trigger={["<"]}
                            onChange={(value) =>
                              setFieldValue("_thankYouMessage", value)
                            }
                            options={Object.values([
                              Placeholders.RO_NO,
                              Placeholders.CAR_MODEL,
                              Placeholders.TIP_AMOUNT,
                              Placeholders.DEALER_NAME,
                              Placeholders.REVIEW_SCORE,
                              Placeholders.TECH_FIRST_NAME,
                              Placeholders.CUSTOMER_FIRST_NAME,
                              Placeholders.SALESPERSON_FIRST_NAME,
                            ])}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showLeaveTipCancelButton()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.leaveTipCancelButton")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            name={"_leaveTipCancelButton"}
                            label={t("dealershipSettings.leaveTipCancelButton")}
                            component={FormTextField}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showLeaveTipCancelButton()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t("dealershipSettings.leaveTipSubmitButton")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            size="small"
                            name={"_leaveTipSubmitButton"}
                            label={t("dealershipSettings.leaveTipSubmitButton")}
                            component={FormTextField}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>

                <VisibleContent visible={values.showLowScoreEmails()}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          {t(
                            "dealershipSettings.sendEmailToUsersWhenCustomerLeaves"
                          )}
                        </Grid>
                        <Grid
                          item
                          xl={3}
                          lg={5}
                          md={6}
                          xs={12}
                          style={{ paddingTop: 0 }}
                        >
                          <Field
                            name={"_reviewLowScoreValue"}
                            size="small"
                            type={"number"}
                            label={"Less Than"}
                            component={FormTextField}
                            InputProps={{ inputProps: { min: 1, max: 5 } }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            label="Enabled"
                            control={
                              <Checkbox
                                size={"medium"}
                                checked={values.sendLowScoreEmails}
                                onChange={handleCheckboxChange(
                                  "_sendLowScoreEmails",
                                  formikProps
                                )}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            id={"reviewLowScoreEmailList"}
                            name={"_reviewLowScoreEmailList"}
                            label={"Emails"}
                            color={"primary"}
                            variant="outlined"
                            placeholder="Type and press enter!"
                            component={FormTagField}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      Description here
                    </TableCell>
                  </TableRow>
                </VisibleContent>
              </TableBody>
            </Table>
          </TableContainer>
        </PaperWrapper>

        <PromptIfDirty message={t("common.unsavedChanges")} />
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Formik
          key={refreshKey}
          initialValues={config}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formRender}
        </Formik>
      </React.Fragment>
    );
  };

  return render();
};

export default DealershipSettings;
