import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { MobileStepper } from "@mui/material";
import { useStyles } from "../../views/repair_order/RepairOrder.styles";
import { get } from "lodash";
import { steps } from "../../util/constants";
import React from "react";

type StepperProps = {
  activeStep: number;
};

export const StepperWrapper = (props: StepperProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container item>
        <Grid item>
          <Typography variant="body2" className={classes.statusLabel}>
            CURRENT
          </Typography>

          <Typography variant="h3" className={classes.statusValue}>
            {get(steps, `${props.activeStep}.label`, "Unknown")}
          </Typography>
        </Grid>
      </Grid>

      <MobileStepper
        steps={3}
        variant="dots"
        position="static"
        color="primary"
        activeStep={props.activeStep}
        classes={{
          dot:
            props.activeStep === 1
              ? classes.inProgressDot
              : classes.completedDot,
          dots: classes.dots,
          root: classes.root,
          dotActive: classes.dotActive,
        }}
        sx={{ maxWidth: "100%", paddingLeft: 0, paddingRight: 0 }}
        nextButton={<></>}
        backButton={<></>}
      />
    </React.Fragment>
  );
};
