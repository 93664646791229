import React, { useRef, useState } from "react";
import { isEmpty } from "lodash";
import RepairOrderService from "../../../services/RepairOrder.service";
import ModuleHelper from "../../../util/ModuleHelper";
import { capitalCase } from "change-case";
import { useStyles } from "./RecommendedWork.styles";
import { styled } from "@mui/material/styles";
import { LoadingButton as LoadingBtn } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import MediaCarouselModal from "../../media/MediaCarouselModal";
import { ILineItem } from "../../../models/line_item";
import { ILIFile } from "../../../models/file";

const LoadingButton = styled(LoadingBtn)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  margin: 2,
  top: 5,
});

interface IProps {
  readonly files?: ILIFile[];
  readonly lineItem: ILineItem;
  readonly repairOrderService: RepairOrderService;

  onRemove(id: number): void;
}

const LaborOperationRender = (props: IProps): React.ReactElement => {
  const classes = useStyles({});
  const mediaRef = useRef<any>();

  const [approvePending, setApprovePending] = useState<boolean>(false);
  const [declinePending, setDeclinePending] = useState<boolean>(false);

  const onApprove = (lineItem: ILineItem) => (event: any) => {
    event.stopPropagation();

    ModuleHelper.confirmDialog.confirm(
      "Approve?",
      "Are you sure you want to approve this work item?",
      [
        { title: "No", color: "info" },
        {
          title: "Yes",
          color: "error",
          onClick: () => {
            setApprovePending(true);
            props.repairOrderService
              .approveDeclineRS(lineItem.id, true)
              .then(() => {
                props.onRemove(lineItem.id);
              })
              .catch((error) => ModuleHelper.grow.showError(error))
              .finally(() => setApprovePending(false));
          },
        },
      ]
    );
  };

  const onDecline = (lineItem: ILineItem) => (event: any) => {
    event.stopPropagation();

    ModuleHelper.confirmDialog.confirm(
      "Decline?",
      "Are you sure you want to decline this work item?",
      [
        { title: "No", color: "info" },
        {
          title: "Yes",
          color: "error",
          onClick: () => {
            setDeclinePending(true);
            props.repairOrderService
              .approveDeclineRS(lineItem.id, false)
              .then(() => {
                props.onRemove(lineItem.id);
              })
              .catch((error) => ModuleHelper.grow.showError(error))
              .finally(() => setDeclinePending(false));
          },
        },
      ]
    );
  };

  const render = (): React.ReactElement => {
    const { lineItem, files } = props;

    const laborOperation = lineItem.getLaborOperation();
    const desc = laborOperation.getOperationDescription();

    return (
      <div className={classes.recommendedWorkDiv}>
        <div className={classes.recommendedWorkItem}>
          <div style={{ textTransform: "capitalize" }}>
            {`${capitalCase(desc)}`}
          </div>

          {!isEmpty(files) && (
            <IconButton
              style={{ height: 20, width: 20 }}
              onClick={() => mediaRef.current.show(files)}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          )}
        </div>
        <div className={classes.parameterDiv}>
          <span>Cost: ${laborOperation.price}</span>
        </div>
        <div
          style={{
            padding: 10,
            fontSize: 14,
            textAlign: "center",
            wordBreak: "break-all",
          }}
        >{`Comment - ${lineItem.remark}`}</div>
        <div className={classes.buttonAlignment}>
          <LoadingButton
            startIcon={<CancelIcon />}
            loading={declinePending}
            variant={"outlined"}
            size={"medium"}
            disableRipple
            color={"error"}
            onClick={onDecline(lineItem)}
            className={classes.buttonBorder}
          >
            Decline
          </LoadingButton>

          <LoadingButton
            startIcon={<DoneIcon />}
            loading={approvePending}
            variant={"outlined"}
            size={"medium"}
            disableRipple
            color={"success"}
            onClick={onApprove(lineItem)}
            className={classes.buttonBorder}
          >
            Approve
          </LoadingButton>
        </div>

        <MediaCarouselModal ref={mediaRef} />
      </div>
    );
  };

  return render();
};

export default LaborOperationRender;
