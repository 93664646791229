import { AxiosResponse } from "axios";
import { IPageResponse, IUserNotificationDTO } from "../../index";
import { defaultTo } from "lodash";

type seen = "ALL" | "SEEN" | "UNSEEN";

interface INotificationApiClient {
  getNotifications(
    page: number,
    limit: number,
    seen: seen
  ): Promise<AxiosResponse<IPageResponse<IUserNotificationDTO[]>>>;

  markAsSeen(notificationId: number): Promise<AxiosResponse>;
}

class NotificationApiClient implements INotificationApiClient {
  private readonly prefix = "user";

  getNotifications(
    page: number,
    limit: number,
    seen: seen
  ): Promise<AxiosResponse<IPageResponse<IUserNotificationDTO[]>>> {
    return window.apiClient.get(`${this.prefix}/notification`, {
      params: {
        page: page,
        perPage: limit,
        seen: seen,
      },
    });
  }

  markAsSeen(notificationId: number): Promise<AxiosResponse> {
    return window.apiClient.put(
      `${this.prefix}/notification?notificationId=${notificationId}`
    );
  }
}

export default class NotificationService {
  private notificationApiClient: INotificationApiClient;

  constructor() {
    this.notificationApiClient = new NotificationApiClient();
  }

  public async markAsSeen(notificationId: number): Promise<AxiosResponse> {
    return this.notificationApiClient.markAsSeen(notificationId);
  }

  public async getUnseen(): Promise<number> {
    const response = await this.notificationApiClient.getNotifications(
      0,
      100,
      "UNSEEN"
    );

    const result = defaultTo(response.data.result, []);

    return result.length;
  }

  public async getNotifications(
    page: number,
    limit: number
  ): Promise<IUserNotificationDTO[]> {
    const response = await this.notificationApiClient.getNotifications(
      page,
      limit,
      "ALL"
    );

    return defaultTo(response.data.result, []);
  }
}
