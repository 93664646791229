import "@fontsource/roboto";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const FONT_FAMILY = "Montserrat";

export const globalColors = {
  orange: "#FC6C09",
  orange400: "#fc7b24",
  orange300: "#fa7e2a",
  orange200: "#fa883a",
  orange100: "#fa934d",
  success: "#2e7d32",
  grey: "#697C91",
  white: "#FFFFFF",
  lightGrey: "#F5F5F5",
  blue: "#4473b6",
  notificationBlue: "#3E6596",
  avatarGray: "#BDBDBD",
};

export const globalStyle = {
  borderRadius: 5,
  titleFontSize: 20,
  border: "1px solid #F3F2F4",
};

export const useGlobalStyles = makeStyles(() => ({
  saveButton: {
    "&&": {
      color: "#000",
      borderRadius: "4px !important",
      backgroundColor: globalColors.orange,
      "&:hover": {
        backgroundColor: globalColors.orange,
        borderColor: "#0062cc",
        boxShadow: "none",
        color: "#000",
      },
      "&:active": {
        boxShadow: "none",
        backgroundColor: globalColors.orange,
        borderColor: "#005cbf",
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
      },
      "& .css-1yt7yx7-MuiLoadingButton-loadingIndicator": {
        color: globalColors.white,
      },
    },
  },
  chip: {
    "&&": {
      "& .MuiChip-label": {
        color: globalColors.white,
        fontWeight: "bold",
      },
      "& .MuiChip-icon": {
        color: globalColors.white,
      },
    },
  },
  alertTitle: {
    "&&": {
      "& .MuiAlert-message": {
        width: "100%",
      },
    },
  },
  grid: {
    "&&": {
      "& .MuiDataGrid-columnHeaderTitle": {
        lineHeight: "20px",
        whiteSpace: "normal",
      },
    },
  },
}));

export const servueTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: globalColors.orange,
    },
    secondary: {
      main: "#333333",
    },
    success: {
      main: globalColors.success,
    },
    grey: {
      100: globalColors.grey,
    },
  },
  components: {
    /** Styling for Typography**/
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 700,
          fontSize: "32px",
        },
        h2: {
          fontSize: "25px",
          fontWeight: 700,
        },
        h3: {
          fontSize: "14px",
          fontWeight: 500,
        },
        h4: {
          fontSize: "16px",
          fontWeight: 400,
        },
        h6: {
          fontSize: "14px",
          fontWeight: 400,
        },
        subtitle1: {
          fontSize: "12px",
          fontWeight: 600,
        },
        body1: {
          fontSize: "13px",
          fontWeight: 400,
        },
        body2: {
          fontSize: "13px",
          fontWeight: 400,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
});
