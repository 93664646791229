import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Container, IconButton, Avatar, Input } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../index";
import SaveIcon from "@mui/icons-material/Save";
import { FormTextField } from "../shared/form_text_field/FormTextField";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { acceptImgTypes } from "../../util/constants";
import UserService from "../../services/User.service";
import { updateUser } from "../../reducers/auth";
import ModuleHelper from "../../util/ModuleHelper";
import { useTranslation } from "react-i18next";
import { get, isEmpty } from "lodash";
import FormPhoneField from "../shared/form_phone_field/FormPhoneField";
import i18n from "i18next";
import Validation from "../../util/Validation";
import Utils from "../../util/Utils";
import { globalColors, useGlobalStyles } from "../../util/ThemePalette";
import PaperWrapper from "../paper/PaperWrapper";
import { IContextUser } from "../../models/user";

const Yup = Utils.validation.Yup;

const validationSchema = Yup.object().shape({
  _firstName: Yup.string().notNA().required("Required"),
  _lastName: Yup.string().notNA().required("Required"),
  _email: Yup.string()
    .email(i18n.t("validation.invalidEmailAddress"))
    .required(i18n.t("validation.required")),
  _mobileNumber: Yup.string()
    .mobileNumber(i18n.t("validation.invalidPhoneNumber"))
    .required(i18n.t("validation.required")),
});

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  width: "100%",

  lineHeight: 1.5,
});

const userService = new UserService();

const Profile = (): JSX.Element => {
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [avatarImage, setAvatarImage] = useState<File>();
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  const onSubmit = (values: IContextUser): void => {
    const promises: any = [
      userService.updateUserDetails({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileNumber: Validation.phoneFormatter(values.mobileNumber as string),
      }),
    ];

    if (avatarImage) {
      promises.push(userService.updateAvatar(avatarImage));
    }

    setLoading(true);
    Promise.all(promises)
      .then((responses) => {
        const user: IContextUser = get(responses, "[0]");
        const avatarUrl = get(responses, "[1].avatarURL");

        if (!isEmpty(avatarUrl)) {
          user.avatarURL = avatarUrl;
        }

        dispatch(updateUser(user));
        ModuleHelper.grow.success(t("common.profileUpdatedSuccessfully"));
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const onUploadFile = ({ target }: any): void => {
    setAvatarImage(target.files[0]);
  };

  const formRender = (): React.ReactElement => {
    return (
      <Form noValidate autoComplete="on">
        <Grid container spacing={2}>
          <Grid style={{ textAlign: "center" }} item xs={12}>
            <IconButton component="label">
              <Input
                type={"file"}
                inputProps={{
                  accept: acceptImgTypes,
                }}
                style={{ display: "none" }}
                onChange={onUploadFile}
              />
              <Avatar
                src={
                  avatarImage
                    ? URL.createObjectURL(avatarImage)
                    : user.avatarURL
                }
                sx={{ backgroundColor: globalColors.avatarGray }}
                style={{
                  width: "100px",
                  height: "100px",
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Field
              size="small"
              name="_firstName"
              label="First Name"
              required={true}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              size="small"
              name="_lastName"
              label="Last Name"
              required={true}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              size="small"
              name="_email"
              label="Email"
              required={true}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              size="small"
              name="_mobileNumber"
              label="Mobile Number"
              required={true}
              component={FormPhoneField}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              disabled={user.id === 0}
              className={globalClasses.saveButton}
            >
              Save
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <Container maxWidth={"xs"}>
        <PaperWrapper sx={{ padding: 1 }}>
          <Formik
            key={user.id}
            initialValues={user}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {formRender}
          </Formik>
        </PaperWrapper>
      </Container>
    );
  };

  return render();
};

export default Profile;
