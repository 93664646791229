import React from "react";
import { Prompt } from "react-router-dom";
import { FormikConsumer } from "formik";

interface IProps {
  message: string;
}

export const PromptIfDirty = (props: IProps): React.ReactElement => (
  <FormikConsumer>
    {(formik) => <Prompt when={formik.dirty} message={props.message} />}
  </FormikConsumer>
);
