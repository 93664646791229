import { IReview } from "./index";
import { IReviewResponseDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class Review implements IReview {
  private _createdAt: string;
  private _id: number;
  private _score: number;
  private _text: string;

  constructor(args?: IReviewResponseDTO) {
    this._id = Utils.default(args?.id, 0);
    this._score = Utils.default(args?.score, 0);
    this._text = Utils.default(args?.text, "");
    this._createdAt = Utils.default(args?.createdAt, "");
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get score(): number {
    return this._score;
  }

  set score(value: number) {
    this._score = value;
  }

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }
}
