import Utils from "../../util/Utils";
import LaborOperation from "../labor_operation/LaborOperation";
import { ILineItem } from "./index";
import { ILaborOperation } from "../labor_operation";
import { IBaseUser } from "../user";
import Duration from "../duration/Duration";
import ModuleHelper from "../../util/ModuleHelper";
import { ChipLabel, Color, StringBool } from "../../types/types";
import { ILineItemDTO, IUpdateRSDTO } from "../../../index";
import { Priority } from "../../common/Enums";
import { IStatusCode } from "../status_code";
import StatusCode from "../status_code/StatusCode";
import { ILIFile } from "../file";
import Technician from "../user/Technician";

export default class LineItem implements ILineItem {
  private _cause: string;
  private _code: string;
  private _estDuration: IDuration;
  private _id: number;
  private _isAddedAfterInit: StringBool;
  private _isApprovedByAdvisor: StringBool;
  private _isApprovedByCustomer: StringBool;
  private _isRecommendedService: StringBool;
  private _laborOperations: ILaborOperation[];
  private _priority: Priority;
  private _remark: string;
  private _spentTime: string;
  private _status: IStatusCode;
  private _technician: IBaseUser;
  private _updated: string;
  private _files: ILIFile[];

  constructor(args?: ILineItemDTO) {
    this._files = [];
    this._status = new StatusCode(args?.status);
    this._technician = new Technician(args?.technician);
    this._estDuration = new Duration(args?.estDuration);
    this._laborOperations = Utils.default(args?.laborOperations, []).map(
      (item) => new LaborOperation(item)
    );

    this._cause = Utils.default(args?.cause, "");
    this._code = Utils.default(args?.code, "");
    this._id = Utils.default(args?.id, 0);
    this._isAddedAfterInit = Utils.default(args?.isAddedAfterInit, "false_");
    this._isApprovedByAdvisor = Utils.default(
      args?.isApprovedByAdvisor,
      "false_"
    );
    this._isApprovedByCustomer = Utils.default(
      args?.isApprovedByCustomer,
      "false_"
    );
    this._isRecommendedService = Utils.default(
      args?.isRecommendedService,
      "false_"
    );
    this._priority = Utils.default(args?.priority, Priority.LOW);
    this._remark = Utils.default(args?.remark, "");
    this._spentTime = Utils.default(args?.spentTime, "");
    this._updated = Utils.default(args?.updated, "");
  }

  get cause(): string {
    return this._cause;
  }

  set cause(value: string) {
    this._cause = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get estDuration(): IDuration {
    return this._estDuration;
  }

  set estDuration(value: IDuration) {
    this._estDuration = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get isAddedAfterInit(): StringBool {
    return this._isAddedAfterInit;
  }

  set isAddedAfterInit(value: StringBool) {
    this._isAddedAfterInit = value;
  }

  get isApprovedByAdvisor(): StringBool {
    return this._isApprovedByAdvisor;
  }

  set isApprovedByAdvisor(value: StringBool) {
    this._isApprovedByAdvisor = value;
  }

  get isApprovedByCustomer(): StringBool {
    return this._isApprovedByCustomer;
  }

  set isApprovedByCustomer(value: StringBool) {
    this._isApprovedByCustomer = value;
  }

  get isRecommendedService(): StringBool {
    return this._isRecommendedService;
  }

  set isRecommendedService(value: StringBool) {
    this._isRecommendedService = value;
  }

  get laborOperations(): ILaborOperation[] {
    return this._laborOperations;
  }

  set laborOperations(value: ILaborOperation[]) {
    this._laborOperations = value;
  }

  get priority(): Priority {
    return this._priority;
  }

  set priority(value: Priority) {
    this._priority = value;
  }

  get remark(): string {
    return this._remark;
  }

  set remark(value: string) {
    this._remark = value;
  }

  get spentTime(): string {
    return this._spentTime;
  }

  set spentTime(value: string) {
    this._spentTime = value;
  }

  get status(): IStatusCode {
    return this._status;
  }

  set status(value: IStatusCode) {
    this._status = value;
  }

  get technician(): IBaseUser {
    return this._technician;
  }

  set technician(value: IBaseUser) {
    this._technician = value;
  }

  get updated(): string {
    return this._updated;
  }

  set updated(value: string) {
    this._updated = value;
  }

  get files(): ILIFile[] {
    return this._files;
  }

  set files(value: ILIFile[]) {
    this._files = value;
  }

  getChipColor(): Color {
    switch (this._isApprovedByCustomer) {
      case "n_2fa":
        return "info";
      case "false_":
        return "error";
      case "true_":
        return "success";
    }
  }

  getChipLabel(): ChipLabel {
    switch (this.isApprovedByCustomer) {
      case "n_2fa":
        return "PENDING";
      case "false_":
        return "DECLINED";
      case "true_":
        return "APPROVED";
    }
  }

  buildDTO(roId: number): IUpdateRSDTO {
    const estDuration: IDuration = {
      days: this.estDuration.days,
      minutes: this.estDuration.minutes,
      hours: this.estDuration.hours,
      years: this.estDuration.years,
      seconds: this.estDuration.seconds,
      months: this.estDuration.months,
      isNegative: this.estDuration.isNegative,
    };

    return {
      liId: this.id,
      relatedToRoId: roId,
      cause: this.cause,
      priority: this.priority,
      remarks: this.remark,
      estDuration: JSON.stringify(estDuration),
      status: {
        id: this.status.id,
        code: this.status.code,
        initDesc: this.status.initDesc,
        additionalDesc: this.status.additionalDesc,
      },
      updateLaborOperation: {
        price: ModuleHelper.dollarToCent(this.getLaborOperation().price),
        opCodeId: this.getLaborOperation().opCode.id,
      },
    };
  }

  getLaborOperation(): ILaborOperation {
    const laborOperation = this.laborOperations[0];
    return Utils.default(laborOperation, new LaborOperation());
  }
}
