import React, { useEffect, useState } from "react";
import { QueryParams } from "../../common/Enums";
import { isEmpty } from "lodash";
import route from "../../common/route";
import ModuleHelper from "../../util/ModuleHelper";
import VehicleService from "../../services/Vehicle.service";
import { useHistory } from "react-router";
import ReviewView from "../../components/review/ReviewView";
import VehicleReview from "../../models/vehicle_review/VehicleReview";
import { IVehicleReview } from "../../models/vehicle_review";
import { IReview } from "../../models/review";
import ReviewService from "../../services/Review.service";
import TipService from "../../services/Tip.service";
import VisibleContent from "../../components/visable_content/VisibleContent";
import PaperWrapper from "../../components/paper/PaperWrapper";
import { Grid } from "@mui/material";

const queryString = require("query-string");

const tipService = new TipService();
const reviewService = new ReviewService();
const vehicleService = new VehicleService();

const VehicleReviewView = (): React.ReactElement => {
  const history = useHistory();

  const [data, setData] = useState<IVehicleReview>(new VehicleReview());
  console.log(data);

  useEffect(() => {
    const params = queryString.parse(history.location.search);

    const vehicleId = params[QueryParams.vehicleId];

    if (isEmpty(vehicleId)) {
      history.push(route.profile.pathname);
      return;
    }

    ModuleHelper.loader.show();
    vehicleService
      .get(vehicleId)
      .then((response) => {
        setData(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => ModuleHelper.loader.hide());
  }, []);

  const setReview = (value: IReview): void => {
    data.review = value;
  };

  const onSubmit = (): Promise<IReview> =>
    reviewService.addVehicleReview({
      text: data.review.text,
      score: data.review.score,
      relatedToVehicleId: data.vehicle.id,
    });

  const handleTipSubmit = (args: any): Promise<any> =>
    tipService.addVehicleTip({
      amount: args.amount,
      cancelURL: args.cancelURL,
      successURL: args.successURL,
      vehicleId: data.vehicle.id,
      tipWhoReceiveList: [data.seller.id],
    });

  const render = (): React.ReactElement => {
    return (
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xl={6} lg={7} md={8} xs={12}>
          <PaperWrapper>
            <VisibleContent visible={data.vehicle.id !== 0}>
              <ReviewView
                tip={data.tip}
                review={data.review}
                vehicle={data.vehicle}
                mainEmployee={data.seller}
                updated={data.vehicle.updated}
                onChange={setReview}
                onSubmit={onSubmit}
                onTipSubmit={handleTipSubmit}
              />
            </VisibleContent>
          </PaperWrapper>
        </Grid>
      </Grid>
    );
  };

  return render();
};

export default VehicleReviewView;
