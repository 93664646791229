import React from "react";
import Downshift from "downshift";
import { makeStyles } from "@mui/styles";
import { Chip, TextField } from "@mui/material";
import { getIn } from "formik";
import { FieldInputProps, FormikProps } from "formik/dist/types";
import { Color } from "../../../types/types";
import { globalColors } from "../../../util/ThemePalette";

const useStyles = makeStyles(() => ({
  chip: {
    margin: "10px 5px 0 0 !important",
    "&&": {
      "& .MuiChip-label": {
        color: globalColors.white,
        fontWeight: "bold",
      },
    },
  },
  chipContainer: {
    "&&": {
      "& .MuiOutlinedInput-root.MuiInputBase-root": {
        display: "block",
      },
      "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon": {
        color: globalColors.white,
      },
    },
  },
}));

interface IProps {
  id?: string;
  name?: string;
  label?: string;
  color?: Color;
  variant?: string;
  fullWidth?: boolean;
  placeholder?: string;

  error?: boolean;
  helperText?: string;

  form: FormikProps<any>;
  field: FieldInputProps<any>;

  onChange(value: string[]): void;
}

export const FormTagField = (props: IProps): React.ReactElement => {
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState<string[]>(
    Array.from(props.field.value)
  );

  function handleKeyDown(event: any) {
    if (event.key === "Enter") {
      event.stopPropagation();
      event.preventDefault();

      const value: string = event.target.value.trim();
      const newSelectedItem = [...selectedItem];
      const duplicatedValues = newSelectedItem.indexOf(value);

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(value);
      setSelectedItem(newSelectedItem);
      setInputValue("");
      props.form.setFieldValue(props.field.name, newSelectedItem);
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      const values = selectedItem.slice(0, selectedItem.length - 1);

      props.form.setFieldValue(props.field.name, values);
    }
  }
  function handleChange(item: any) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
  }

  const handleDelete = (item: string) => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
    props.form.setFieldValue(props.field.name, newSelectedItem);
  };

  function handleInputChange(event: any) {
    setInputValue(event.target.value);
  }

  const render = (): React.ReactElement => {
    const isTouched = getIn(props.form?.touched, props.field?.name);
    const errorMessage = getIn(props.form?.errors, props.field?.name);

    const { error, helperText } = props;

    return (
      <React.Fragment>
        <Downshift
          id="downshift-multiple"
          inputValue={inputValue}
          onChange={handleChange}
          selectedItem={selectedItem}
        >
          {({ getInputProps }) => {
            const { onBlur, onChange, onFocus, ...inputProps }: any =
              getInputProps({
                onKeyDown: handleKeyDown,
                placeholder: props.placeholder,
              });
            return (
              <div className={classes.chipContainer}>
                <TextField
                  size={"small"}
                  label={props.label}
                  variant={props.variant}
                  error={error ?? Boolean(isTouched && errorMessage)}
                  helperText={
                    helperText ??
                    (isTouched && errorMessage ? errorMessage : undefined)
                  }
                  InputProps={{
                    startAdornment: selectedItem.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        color={props.color}
                        className={classes.chip}
                        onDelete={handleDelete(item)}
                      />
                    )),
                    onBlur,
                    onChange: (event) => {
                      handleInputChange(event);
                      onChange(event);
                    },
                    onFocus,
                  }}
                  {...inputProps}
                />
              </div>
            );
          }}
        </Downshift>
      </React.Fragment>
    );
  };

  return render();
};

export default FormTagField;
