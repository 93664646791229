import { ITechnicianStatistic } from "./index";
import { IBaseUser } from "../user";
import { ITechnicianStatisticsDTO } from "../../../index";
import Utils from "../../util/Utils";
import { divide, multiply } from "lodash";
import ModuleHelper from "../../util/ModuleHelper";
import Technician from "../user/Technician";

export default class TechnicianStatistic implements ITechnicianStatistic {
  private _id: number;
  private _employee: IBaseUser;
  private _additionalCustomerPayRevenue: number;
  private _additionalCustomerPayedRevenue: number;
  private _additionalWarrantyRevenue: number;
  private _averageScore: number;
  private _isCredible: boolean;
  private _picturesCreated: number;
  private _picturesSentToCustomer: number;
  private _recommendedWorksApprovedByCustomer: number;
  private _recommendedWorksCreated: number;
  private _recommendedWorksDeclinedByCustomer: number;
  private _recommendedWorksPendingCustomerResponse: number;
  private _recommendedWorksSentToCustomer: number;
  private _reviewsReceived: number;
  private _totalTipAmount: number;
  private _videosCreated: number;
  private _videosSentToCustomer: number;
  private _revenueTotal: number;
  private _recommendedWorksSuccessRate: string;

  constructor(args?: ITechnicianStatisticsDTO) {
    this._employee = new Technician(args?.employee);
    this._id = Utils.default(args?.employee?.id, 0);
    this._additionalCustomerPayRevenue = ModuleHelper.centToDollar(
      Utils.default(args?.additionalCustomerPayRevenue, 0)
    );
    this._additionalCustomerPayedRevenue = ModuleHelper.centToDollar(
      Utils.default(args?.additionalCustomerPayRevenue, 0)
    );
    this._additionalWarrantyRevenue = ModuleHelper.centToDollar(
      Utils.default(args?.additionalWarrantyRevenue, 0)
    );
    this._averageScore = ModuleHelper.round(
      Utils.default(args?.averageScore, 0)
    );
    this._isCredible = Utils.default(args?.isCredible, false);
    this._picturesCreated = Utils.default(args?.picturesCreated, 0);
    this._picturesSentToCustomer = Utils.default(
      args?.picturesSentToCustomer,
      0
    );
    this._revenueTotal = multiply(
      this._additionalWarrantyRevenue,
      this._additionalCustomerPayedRevenue
    );
    this._recommendedWorksApprovedByCustomer = Utils.default(
      args?.recommendedWorksApprovedByCustomer,
      0
    );
    this._recommendedWorksCreated = Utils.default(
      args?.recommendedWorksCreated,
      0
    );
    this._recommendedWorksDeclinedByCustomer = Utils.default(
      args?.recommendedWorksDeclinedByCustomer,
      0
    );
    this._recommendedWorksPendingCustomerResponse = Utils.default(
      args?.recommendedWorksPendingCustomerResponse,
      0
    );
    this._recommendedWorksSentToCustomer = Utils.default(
      args?.recommendedWorksSentToCustomer,
      0
    );
    this._reviewsReceived = Utils.default(args?.reviewsReceived, 0);
    this._totalTipAmount = ModuleHelper.centToDollar(
      Utils.default(args?.totalTipAmount, 0)
    );
    this._videosCreated = Utils.default(args?.videosCreated, 0);
    this._videosSentToCustomer = Utils.default(args?.videosSentToCustomer, 0);

    this._recommendedWorksSuccessRate = `${Number(
      this.getSuccessRate()
    ).toFixed(2)}%`;
  }

  private getSuccessRate(): number {
    return multiply(
      divide(
        this._recommendedWorksApprovedByCustomer,
        Math.max(
          this._recommendedWorksSentToCustomer -
            this._recommendedWorksPendingCustomerResponse,
          1
        )
      ),
      100
    );
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get revenueTotal(): number {
    return this._revenueTotal;
  }

  set revenueTotal(value: number) {
    this._revenueTotal = value;
  }

  get employee(): IBaseUser {
    return this._employee;
  }

  set employee(value: IBaseUser) {
    this._employee = value;
  }

  get additionalCustomerPayRevenue(): number {
    return this._additionalCustomerPayRevenue;
  }

  set additionalCustomerPayRevenue(value: number) {
    this._additionalCustomerPayRevenue = value;
  }

  get additionalCustomerPayedRevenue(): number {
    return this._additionalCustomerPayedRevenue;
  }

  set additionalCustomerPayedRevenue(value: number) {
    this._additionalCustomerPayedRevenue = value;
  }

  get additionalWarrantyRevenue(): number {
    return this._additionalWarrantyRevenue;
  }

  set additionalWarrantyRevenue(value: number) {
    this._additionalWarrantyRevenue = value;
  }

  get averageScore(): number {
    return this._averageScore;
  }

  set averageScore(value: number) {
    this._averageScore = value;
  }

  get isCredible(): boolean {
    return this._isCredible;
  }

  set isCredible(value: boolean) {
    this._isCredible = value;
  }

  get picturesCreated(): number {
    return this._picturesCreated;
  }

  set picturesCreated(value: number) {
    this._picturesCreated = value;
  }

  get picturesSentToCustomer(): number {
    return this._picturesSentToCustomer;
  }

  set picturesSentToCustomer(value: number) {
    this._picturesSentToCustomer = value;
  }

  get recommendedWorksApprovedByCustomer(): number {
    return this._recommendedWorksApprovedByCustomer;
  }

  set recommendedWorksApprovedByCustomer(value: number) {
    this._recommendedWorksApprovedByCustomer = value;
  }

  get recommendedWorksCreated(): number {
    return this._recommendedWorksCreated;
  }

  set recommendedWorksCreated(value: number) {
    this._recommendedWorksCreated = value;
  }

  get recommendedWorksDeclinedByCustomer(): number {
    return this._recommendedWorksDeclinedByCustomer;
  }

  set recommendedWorksDeclinedByCustomer(value: number) {
    this._recommendedWorksDeclinedByCustomer = value;
  }

  get recommendedWorksPendingCustomerResponse(): number {
    return this._recommendedWorksPendingCustomerResponse;
  }

  set recommendedWorksPendingCustomerResponse(value: number) {
    this._recommendedWorksPendingCustomerResponse = value;
  }

  get recommendedWorksSentToCustomer(): number {
    return this._recommendedWorksSentToCustomer;
  }

  set recommendedWorksSentToCustomer(value: number) {
    this._recommendedWorksSentToCustomer = value;
  }

  get reviewsReceived(): number {
    return this._reviewsReceived;
  }

  set reviewsReceived(value: number) {
    this._reviewsReceived = value;
  }

  get totalTipAmount(): number {
    return this._totalTipAmount;
  }

  set totalTipAmount(value: number) {
    this._totalTipAmount = value;
  }

  get videosCreated(): number {
    return this._videosCreated;
  }

  set videosCreated(value: number) {
    this._videosCreated = value;
  }

  get videosSentToCustomer(): number {
    return this._videosSentToCustomer;
  }

  set videosSentToCustomer(value: number) {
    this._videosSentToCustomer = value;
  }

  get recommendedWorksSuccessRate(): string {
    return this._recommendedWorksSuccessRate;
  }

  set recommendedWorksSuccessRate(value: string) {
    this._recommendedWorksSuccessRate = value;
  }
}
