import React, { useEffect, useRef, useState } from "react";
import DealershipService from "../../../services/Dealership.service";
import ModuleHelper from "../../../util/ModuleHelper";
import { IOpCodeDTO, IStore } from "../../../../index";
import { DataGrid, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { defaultPageSize } from "../../../util/constants";
import { GridColumns } from "@mui/x-data-grid/models/colDef/gridColDef";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import EditOpCode from "../../../components/op_code/EditOpCode";
import { shallowEqual, useSelector } from "react-redux";
import PaperWrapper from "../../../components/paper/PaperWrapper";

const OperationalCodes = (): React.ReactElement => {
  const dealershipService = new DealershipService();

  const { t } = useTranslation();
  const editOpCodeRef = useRef<any>();

  const [pending, setPending] = useState<boolean>(false);
  const [opCodes, setOpCodes] = useState<IOpCodeDTO[]>([]);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const dealerContext = useSelector(
    (store: IStore) => store.auth.user.dealerContext,
    shallowEqual
  );

  useEffect(() => {
    getData();
  }, [dealerContext]);

  const getData = (): void => {
    setPending(true);
    dealershipService
      .getOpCodes(0, 10000)
      .then((response) => {
        setOpCodes(response);
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setPending(false));
  };

  const handleDeleteClick =
    (item: GridRowParams<IOpCodeDTO>) => (event: any) => {
      event.stopPropagation();

      ModuleHelper.confirmDialog.confirm(
        "Delete Operational Code?",
        `You are about to delete "${item.row.code}". Are you sure you want to do this?`,
        [
          { title: "Cancel", color: "info" },
          {
            title: "Yes",
            color: "error",
            onClick: () => {
              setPending(true);
              dealershipService
                .deleteOpCode(item.row.id)
                .then(() => {
                  getData();
                  ModuleHelper.grow.success(t("opCode.deletedSuccessfully"));
                })
                .catch((error) => ModuleHelper.grow.showError(error))
                .finally(() => setPending(false));
            },
          },
        ]
      );
    };

  const handleEdit = (item: GridRowParams<IOpCodeDTO>) => (event: any) => {
    event.stopPropagation();
    editOpCodeRef.current.edit(item.row);
  };

  const handleEditOpCodeModelChange = React.useCallback(
    (model: any) => {
      Object.keys(model).forEach((key: any) => {
        const index = opCodes.findIndex((item) => item.id == parseInt(key));

        if (index !== -1) {
          const data = model[key];
          const opCode = opCodes[index];

          opCode.additionalDesc = data.additionalDesc.value;
        }
      });
    },
    [opCodes]
  );

  const columns: GridColumns = [
    { field: "id", headerName: "ID", width: 130, flex: 1 },
    { field: "code", headerName: "Code", width: 130, flex: 1, editable: false },
    {
      field: "initDesc",
      headerName: "CDK Description",
      width: 130,
      flex: 1,
      editable: false,
    },
    {
      field: "additionalDesc",
      headerName: "Customer Description",
      width: 130,
      flex: 1,
      editable: true,
      description: t("opCode.additionalDescriptionInfo"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: (props: GridRowParams) => {
        return [
          <GridActionsCellItem
            key={"update"}
            label={"Update"}
            title={"Update"}
            icon={<EditIcon />}
            onClick={handleEdit(props)}
          />,
          <GridActionsCellItem
            key={"delete"}
            label={"Delete"}
            title={"Delete"}
            color={"error"}
            icon={<DeleteIcon />}
            onClick={handleDeleteClick(props)}
          />,
        ];
      },
    },
  ];

  const handleAddOpCode = (): void => {
    editOpCodeRef.current.add();
  };

  const updateOpCode = (item: IOpCodeDTO) =>
    dealershipService
      .updateOpCode(item)
      .then(() => {
        getData();
        ModuleHelper.grow.success(t("opCode.updatedSuccessfully"));
      })
      .catch((error) => ModuleHelper.grow.showError(error));

  const createOpCode = (item: IOpCodeDTO) =>
    dealershipService
      .createOpCode(item)
      .then(() => {
        getData();
        ModuleHelper.grow.success(t("opCode.createdSuccessfully"));
      })
      .catch((error) => {
        ModuleHelper.grow.showError(error);
      });

  const handleSubmitOpCode = (value: IOpCodeDTO): Promise<any> => {
    if (value.id === 0) {
      return createOpCode(value);
    }

    return updateOpCode(value);
  };

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper
        rightHeader={
          <Button
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={handleAddOpCode}
          >
            Add Op Code
          </Button>
        }
      >
        <Box style={{ marginTop: 15 }}>
          <DataGrid
            editMode={"row"}
            rows={opCodes}
            columns={columns}
            loading={pending}
            autoHeight={true}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            onEditRowsModelChange={handleEditOpCodeModelChange}
          />
        </Box>
        <EditOpCode ref={editOpCodeRef} onSubmit={handleSubmitOpCode} />
      </PaperWrapper>
    );
  };

  return render();
};

export default OperationalCodes;
