import MenuBuilder from "./MenuBuilder";
import route, { IPage } from "../../common/route";

export default class DefaultMenuBuilder extends MenuBuilder {
  build(): IPage[] {
    const pages: IPage[] = [];

    this.buildPages(pages, route.dealership);

    if (this.hasRouteAccess(route.leaderboard)) {
      pages.push(route.leaderboard);
    }

    if (this.hasRouteAccess(route.appStore)) {
      pages.push(route.appStore);
    }

    return pages;
  }
}
