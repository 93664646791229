import React, { useEffect, useState } from "react";
import { Card, Container, Grid, Typography } from "@mui/material";
import Logo from "../../../components/logo/Logo";
import { Field, Form, Formik } from "formik";
import { FormTextField } from "../../../components/shared/form_text_field/FormTextField";
import { useStyles } from "../login/Login.styles";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router";
import { QueryParams } from "../../../common/Enums";
import { isEmpty } from "lodash";
import route from "../../../common/route";
import LoginService from "../../../services/Login.service";
import ModuleHelper from "../../../util/ModuleHelper";
import { loginSuccess } from "../../../reducers/auth";
import { useDispatch } from "react-redux";
import Utils from "../../../util/Utils";
import { IContextUser } from "../../../models/user";
import ContextUser from "../../../models/user/ContextUser";
import VisibleContent from "../../../components/visable_content/VisibleContent";

interface FormValues extends IContextUser {
  password: string;
  confirmPassword: string;
}

const Yup = Utils.validation.Yup;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required("Required"),
  confirmPassword: Yup.string().equalTo(
    Yup.ref("password"),
    '"Confirm Password" must be the same as "Password"'
  ),
});

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  width: "100%",
  padding: "14px 12px",
  borderRadius: "14px",
});

const queryString = require("query-string");

const UserActivate = (): React.ReactElement => {
  const loginService = new LoginService();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [pending, setPending] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [user, setUser] = useState<IContextUser>(new ContextUser());
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    const params = queryString.parse(history.location.search);

    const token = params[QueryParams.token];
    if (isEmpty(token)) {
      history.push(route.login.pathname);
      return;
    }

    setPending(true);
    ModuleHelper.loader.show();
    loginService
      .getUserActivateInfo(token)
      .then((response) => {
        setToken(token);
        setUser(response);
        setPending(false);
        ModuleHelper.loader.hide();
      })
      .catch((error) => {
        history.push(route.login.pathname);
        ModuleHelper.grow.showError(error);
      });
  }, []);

  const onSubmit = (values: FormValues): void => {
    setLoading(true);
    loginService
      .setUserPassword(token, values.password)
      .then(() => {
        loginService
          .login(`${user.username}`, values.password)
          .then((response) => {
            setLoading(false);
            dispatch(loginSuccess(response));
            history.push(route.leaderboard.pathname);
          })
          .catch((error) => {
            setLoading(false);
            ModuleHelper.grow.showError(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        ModuleHelper.grow.showError(error);
      });
  };

  const formRender = (): React.ReactElement => {
    return (
      <Form noValidate autoComplete="on">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Field
              name="_firstName"
              label="First Name"
              size="small"
              type="text"
              InputProps={{
                disabled: true,
              }}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="_lastName"
              label="Last Name"
              size="small"
              type="text"
              InputProps={{
                disabled: true,
              }}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="_mobileNumber"
              label="Mobile Number"
              size="small"
              type="text"
              InputProps={{
                disabled: true,
              }}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="_dealerContext._name"
              label="Dealership"
              size="small"
              type="text"
              InputProps={{
                disabled: true,
              }}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="password"
              label="Password"
              size="small"
              type="password"
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="confirmPassword"
              label="Confirm Password"
              size="small"
              type="password"
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <SubmitButton
              loading={loading}
              type="submit"
              variant="contained"
              size={"large"}
              disableRipple
              color="primary"
              className={classes.verifyButton}
            >
              Confirm
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <VisibleContent visible={!pending}>
        <Container
          component="main"
          maxWidth="xs"
          className={classes.loginWrapper}
        >
          <Card className={classes.card}>
            <Logo className={classes.logo} />

            <Typography component="h2" variant="h5">
              <b>Welcome</b>
            </Typography>

            <Typography variant="h3">{user.firstName}</Typography>

            <Typography variant="body1">Create Your Password</Typography>
            <Formik
              initialValues={{
                ...user,
                ...{
                  password: "",
                  confirmPassword: "",
                },
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {formRender}
            </Formik>
          </Card>
        </Container>
      </VisibleContent>
    );
  };

  return render();
};

export default UserActivate;
