import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { UserRole } from "../../common/Enums";
import { Badge, TextField } from "@mui/material";
import { IAuthState, IDealershipOption, IOption, IStore } from "../../../index";
import { shallowEqual, useSelector } from "react-redux";
import DealershipService from "../../services/Dealership.service";
import UserService from "../../services/User.service";
import ModuleHelper from "../../util/ModuleHelper";
import { get, defaultTo } from "lodash";
import { useStyles } from "./DealershipContext.styles";
import VisibleContent from "../visable_content/VisibleContent";

const DealershipContext = (): React.ReactElement => {
  const userService = new UserService();
  const dealershipService = new DealershipService();

  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const auth: IAuthState = useSelector(
    (store: IStore) => store.auth,
    shallowEqual
  );

  const role = auth.getUserRole();

  const [value] = useState<IDealershipOption>({
    value: defaultTo(get(auth, "user.dealerContext.id"), 0),
    label: defaultTo(get(auth, "user.dealerContext.name"), "Unknown"),
    connectedToCdk: defaultTo(
      get(auth, "user.dealerContext.connectedToCdk"),
      false
    ),
  });

  const [limit] = useState<number>(50);
  const [searchString, setSearchString] = useState<string>();
  const [dealerships, setDealerships] = useState<IOption[]>([]);

  useEffect(() => {
    getData(role, searchString);
  }, [role, searchString]);

  const getData = (role: UserRole, searchString?: string): void => {
    if (role !== UserRole.SUPER_ADMIN) {
      return;
    }

    setLoading(true);
    dealershipService
      .getAllDealerships(0, limit, searchString)
      .then((response) => {
        setDealerships(
          response.map((item) => {
            return {
              value: item.id,
              label: item.name,
              connectedToCdk: item.connectedToCdk,
            };
          })
        );
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const onChangeHandler = (
    event: React.SyntheticEvent,
    item: IOption
  ): void => {
    event.stopPropagation();

    ModuleHelper.loader.show();
    userService
      .switchDealershipContext(item.value)
      .then(() => window.location.reload())
      .catch((error) => {
        ModuleHelper.loader.hide();
        ModuleHelper.grow.showError(error);
      });
  };

  const onSearch = (e: any): void => {
    const value: string = defaultTo(e.target.value, "");

    setSearchString(value);
  };

  const render = (): React.ReactElement => {
    return (
      <React.Fragment>
        <Autocomplete
          fullWidth={true}
          options={dealerships}
          autoHighlight
          disableClearable
          size={"small"}
          loading={loading}
          disabled={role !== UserRole.SUPER_ADMIN}
          value={value}
          className={
            role !== UserRole.SUPER_ADMIN ? classes.arrowHide : undefined
          }
          onChange={onChangeHandler}
          noOptionsText={"No Dealerships"}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              variant={"outlined"}
              label={"Dealership"}
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
              value={searchString}
              onChange={onSearch}
            />
          )}
        />

        <VisibleContent visible={value.connectedToCdk}>
          <Badge
            color={"info"}
            title={"CDK Live Data"}
            badgeContent={"i"}
            className={classes.badge}
          />
        </VisibleContent>
      </React.Fragment>
    );
  };

  return render();
};

export default DealershipContext;
