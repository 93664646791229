import { IPage } from "../../common/route";
import store from "../../config/store";
import { entries, omit, set } from "lodash";
import { Buildable } from "../../../index";

export default abstract class MenuBuilder implements Buildable {
  protected hasRouteAccess(page: IPage) {
    return store.getState().auth.hasRouteAccess(page);
  }

  protected buildSubPages(pages: IPage[], page: IPage) {
    if (!this.hasRouteAccess(page)) {
      return;
    }

    const keys: string[] = [];
    const item = { ...page };

    for (const [key, value] of entries(item.pages)) {
      if (!this.hasRouteAccess(value)) {
        keys.push(key);
      }
    }

    set(item, "pages", omit(item.pages, keys));

    pages.push(item);
  }

  protected buildPages(pages: IPage[], page: IPage) {
    if (!this.hasRouteAccess(page)) {
      return;
    }

    const keys: string[] = [];
    const item = { ...page };

    for (const [key, value] of entries(item.pages)) {
      if (this.hasRouteAccess(value)) {
        switch (key) {
          case "overview":
            keys.push(key);
            pages.push(value);
            break;
        }
      } else {
        keys.push(key);
      }
    }

    set(item, "name", "Dealership");
    set(item, "pages", omit(item.pages, keys));

    pages.push(item);
  }

  build(): IPage[] {
    throw new Error("Unimplemented");
  }
}
