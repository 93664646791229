import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
  Messaging,
  MessagePayload,
} from "firebase/messaging";
import { DeviceType, NotificationPermission } from "../../common/Enums";
import ModuleHelper from "../../util/ModuleHelper";
import { get } from "lodash";
import store from "../../config/store";
import { setNotificationToken } from "../../reducers/main";
import { IApiClient } from "../../config/http_client/ApiClient";

const { detect } = require("detect-browser");

export default class FirebaseService {
  private readonly messaging: Messaging;

  constructor() {
    const app = initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
      databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });

    this.messaging = getMessaging(app);
  }

  public subscribe(apiClient: IApiClient): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const supported = await FirebaseService.supported();

        if (!supported) {
          reject("The browser does not support Web Push API.");
          return;
        }

        if (!("serviceWorker" in navigator)) {
          reject();
          return;
        }

        if (Notification.permission === NotificationPermission.denied) {
          reject("Notification: Permission denied.");
          ModuleHelper.grow.warning(
            'Please allow "Push Notifications" from browser settings!'
          );
          return;
        }

        const token = await getToken(this.messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_KEY,
        });

        const browser = detect();

        await apiClient.post("user/push-notification/token", {
          deviceName: `${browser.os} ${browser.name} (${browser.version})`,
          deviceType: DeviceType.DESKTOP,
          token: token,
        });

        store.dispatch(setNotificationToken(token));

        resolve(token);
        this.messageSubscribe();
      } catch (e) {
        reject(e);
        console.error(e);
      }
    });
  }

  private messageSubscribe() {
    onMessage(this.messaging, (payload: MessagePayload) => {
      console.log("Firebase message received", payload);

      const data = get(payload, "data");
      const title = get(data, "title", "Servues");
      const body = get(data, "body", "");

      const localNotification = new Notification(title, {
        body: body,
      });

      console.log(localNotification);
    });
  }

  public static async supported(): Promise<boolean> {
    return isSupported();
  }
}
