import React, { useImperativeHandle, useState } from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { Field, Form, Formik, FormikProps } from "formik";
import { UserRole } from "../../common/Enums";
import { FormTextField } from "../shared/form_text_field/FormTextField";
import { IStore } from "../../../index";
import i18n from "i18next";
import ModuleHelper from "../../util/ModuleHelper";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import FormPhoneField from "../shared/form_phone_field/FormPhoneField";
import Utils from "../../util/Utils";
import { globalColors, useGlobalStyles } from "../../util/ThemePalette";
import { isEmpty } from "lodash";
import { shallowEqual, useSelector } from "react-redux";
import { FormSelectField } from "../shared/form_select_field/FormSelectField";
import { IContextUser, IEmployee } from "../../models/user";
import VisibleContent from "../visable_content/VisibleContent";
import Employee from "../../models/user/Employee";
import { acceptImgTypes } from "../../util/constants";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  onSubmit(employee: IEmployee, avatarImage?: File): Promise<any>;
}

const Yup = Utils.validation.Yup;

Yup.addMethod(Yup.string, "username", function username(errorMessage) {
  return this.test(`username-test`, errorMessage, function (value) {
    const { path, parent, createError } = this;

    const isAdmin = ModuleHelper.isAdmin(parent._roles);

    if (isAdmin && isEmpty(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

Yup.addMethod(Yup.string, "employeeId", function employeeId(errorMessage) {
  return this.test(`employee-id-test`, errorMessage, function (value) {
    const { path, parent, createError } = this;

    const isEmployee = ModuleHelper.isEmployee(parent._roles);

    if (isEmployee && isEmpty(value)) {
      return createError({ path, message: errorMessage });
    }

    return true;
  });
});

const validationSchema = Yup.object().shape({
  _roles: Yup.array().min(1, i18n.t("validation.required")),
  _email: Yup.string()
    .email(i18n.t("validation.invalidEmailAddress"))
    .required(i18n.t("validation.required")),
  _externalRefId: Yup.string().employeeId(i18n.t("validation.required")),
  _mobileNumber: Yup.string()
    .mobileNumber(i18n.t("validation.invalidPhoneNumber"))
    .required(i18n.t("validation.required")),
  _firstName: Yup.string().notNA().required(i18n.t("validation.required")),
  _lastName: Yup.string().notNA().required(i18n.t("validation.required")),
  _username: Yup.string().username(i18n.t("validation.required")),
});

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  width: "100%",

  top: 3,
  lineHeight: 1.5,
});

const EditUserForm = React.forwardRef((props: IProps, ref) => {
  const classes = useGlobalStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [avatarImage, setAvatarImage] = useState<File>();
  const [user, setUser] = useState<IEmployee>(new Employee());

  const loggedUser: IContextUser = useSelector(
    (store: IStore) => store.auth.user,
    shallowEqual
  );

  const handleClose = (): void => {
    if (!loading) {
      setOpen(false);
      setUser(new Employee());
      setAvatarImage(undefined);
    }
  };

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleEdit = (user: IEmployee): void => {
    setOpen(true);
    setUser(user);
  };

  useImperativeHandle(ref, () => ({
    add: handleAdd,
    edit: handleEdit,
    close: handleClose,
  }));

  const onSubmit = (values: IEmployee): void => {
    setLoading(true);
    props
      .onSubmit(values, avatarImage)
      .then(() => handleClose())
      .finally(() => setLoading(false));
  };

  const getOptions = (isTechnician: boolean): UserRole[] => {
    const canSendRSToRoles = [UserRole.CUSTOMER];

    if (isTechnician) {
      canSendRSToRoles.push(UserRole.ADVISOR);
    }

    return canSendRSToRoles;
  };

  const getRoles = (): UserRole[] => {
    const roles = [
      UserRole.ADVISOR,
      UserRole.TECHNICIAN,
      UserRole.USER_MANAGER,
      UserRole.ADMIN,
      UserRole.SALESPERSON,
    ];

    if (!loggedUser.dealerContext.connectedToCdk) {
      roles.push(UserRole.CUSTOMER);
    }

    // if (loggedUser.dealerContext.connectedToCrm) {
    //   roles.push(UserRole.SALESPERSON);
    // }

    return roles;
  };

  const onUploadFile = ({ target }: any): void => {
    setAvatarImage(target.files[0]);
  };

  const formRender = (formikProps: FormikProps<IEmployee>) => {
    const roles = getRoles();
    const isAdmin = ModuleHelper.isAdmin(formikProps.values.roles);
    const isEmployee = ModuleHelper.isEmployee(formikProps.values.roles);
    const isCustomer = ModuleHelper.isCustomerByRoles(formikProps.values.roles);
    const isAdvisor = ModuleHelper.isAdvisor(formikProps.values.roles);
    const isTechnician = ModuleHelper.isTechnician(formikProps.values.roles);

    return (
      <Form noValidate autoComplete="on" style={{ maxWidth: 500 }}>
        <Box textAlign={"center"}>
          <IconButton component="label">
            <Input
              type={"file"}
              inputProps={{
                accept: acceptImgTypes,
              }}
              style={{ display: "none" }}
              onChange={onUploadFile}
            />
            <Avatar
              src={
                avatarImage ? URL.createObjectURL(avatarImage) : user.avatarURL
              }
              sx={{ backgroundColor: globalColors.avatarGray }}
              style={{
                width: "100px",
                height: "100px",
              }}
            />
          </IconButton>
        </Box>

        <Field
          size="small"
          name="_roles"
          label="Roles"
          options={roles}
          multiple={true}
          required={true}
          getOptionLabel={(option: any) => option}
          disabled={isCustomer && loggedUser.dealerContext.connectedToCdk}
          component={FormSelectField}
        />

        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <Field
              name="_firstName"
              size="small"
              label="First Name"
              required={true}
              component={FormTextField}
            />
            <Field
              name="_email"
              size="small"
              label="Email"
              required={true}
              component={FormTextField}
            />
            <VisibleContent visible={isEmployee}>
              <Field
                size="small"
                name="_externalRefId"
                label="Employee ID"
                required={true}
                InputProps={{
                  disabled: user.id !== 0,
                }}
                component={FormTextField}
              />
            </VisibleContent>
            <VisibleContent visible={isAdmin}>
              <Field
                size="small"
                name="_username"
                label="Username"
                required={true}
                component={FormTextField}
              />
            </VisibleContent>
          </Grid>

          <Grid item xl={6} lg={6} md={6} xs={12}>
            <Field
              name="_lastName"
              size="small"
              label="Last Name"
              required={true}
              component={FormTextField}
            />
            <Field
              size="small"
              name="_mobileNumber"
              label="Mobile Number"
              required={true}
              component={FormPhoneField}
            />
            <VisibleContent visible={isEmployee}>
              <Field
                size="small"
                name="_additionalEmployeeInfo._venmoId"
                label="Venmo ID"
                component={FormTextField}
              />
            </VisibleContent>
          </Grid>

          <VisibleContent visible={isAdvisor || isTechnician}>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_additionalEmployeeInfo._canSendRSToRoles"
                label="Can Send RS To Roles"
                options={getOptions(isTechnician)}
                multiple={true}
                component={FormSelectField}
              />
              <Field
                size="small"
                name="_additionalEmployeeInfo._canSendMessagesToRoles"
                label="Can Send Messages To Roles"
                options={[
                  UserRole.CUSTOMER,
                  UserRole.TECHNICIAN,
                  UserRole.ADVISOR,
                ]}
                multiple={true}
                component={FormSelectField}
              />
            </Grid>
          </VisibleContent>

          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <SubmitButton
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              className={classes.saveButton}
            >
              {user.id === 0 ? "Create" : "Update"}
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={18} textAlign={"center"}>
            {user.id === 0 ? "Create user" : `${user.getName()}`}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            onSubmit={onSubmit}
            initialValues={user}
            validationSchema={validationSchema}
          >
            {formRender}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  };

  return render();
});

export default EditUserForm;
