import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  arrowHide: {
    "&&": {
      "& .MuiAutocomplete-endAdornment": {
        display: "none",
      },
    },
  },
  badge: {
    "&&": {
      "& .MuiBadge-anchorOriginTopRightRectangular": {
        minWidth: 15,
        width: 15,
        height: 15,
        cursor: "help",
      },
    },
  },
}));
