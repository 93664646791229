import React, { CSSProperties } from "react";
import Avatar from "@mui/material/Avatar";
import Utils from "../../util/Utils";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

interface IProps {
  readonly firstName?: string;
  readonly avatarURL?: string;
  readonly defaultURL?: string;
  readonly sx?: SxProps<Theme>;
  readonly style?: CSSProperties;
}

const EmployeeAvatar = (props: IProps): React.ReactElement => {
  const render = (): React.ReactElement => {
    const avatarURL = Utils.default(props.avatarURL, props.defaultURL);

    return (
      <Avatar
        sx={props.sx}
        src={avatarURL}
        alt={props.firstName}
        style={props.style}
      />
    );
  };

  return render();
};
export default EmployeeAvatar;
