import React, { forwardRef, useImperativeHandle } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

type color =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";

export interface IConfirmAction {
  readonly title: string;
  readonly color?: color;

  onClick?(): void;
}

const Transition = React.forwardRef((props: any, ref) => {
  return <Slide ref={ref} {...props} />;
});

const ConfirmDialog = forwardRef((props: any, ref: any): React.ReactElement => {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState<string>();
  const [message, setMessage] = React.useState<string>();
  const [actions, setActions] = React.useState<IConfirmAction[]>([]);

  const confirm = (
    title: string,
    message: string,
    actions: IConfirmAction[]
  ) => {
    setOpen(true);
    setTitle(title);
    setMessage(message);
    setActions(actions);
  };

  const close = (event?: any, reason?: any) => {
    if (reason && reason == "backdropClick") {
      return;
    }

    setOpen(false);
    setActions([]);
    setTitle(undefined);
    setMessage(undefined);
  };

  useImperativeHandle(ref, () => ({
    confirm: confirm,
  }));

  const render = (): React.ReactElement => {
    return (
      <Dialog
        open={open}
        style={{ zIndex: 99999 }}
        disableEscapeKeyDown={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
      >
        <DialogTitle sx={{ fontSize: 18, fontWeight: "bold" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map((action, index) => {
            return (
              <Button
                key={index}
                color={action.color}
                onClick={() => {
                  close();
                  action.onClick?.();
                }}
                style={{ textTransform: "none" }}
              >
                {action.title}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    );
  };

  return render();
});

export default ConfirmDialog;
