import React, { useImperativeHandle, useState } from "react";
import { Dialog, Grid, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { IOpCodeDTO } from "../../../index";
import * as Yup from "yup";
import { FormTextField } from "../shared/form_text_field/FormTextField";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import i18n from "i18next";
import { useGlobalStyles } from "../../util/ThemePalette";

interface IProps {
  onSubmit(values: IOpCodeDTO): Promise<any>;
}

const defaultOpCode = {
  id: 0,
  code: "",
  initDesc: "",
  additionalDesc: "",
};

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  width: "100%",

  top: 3,
  lineHeight: 1.5,
});

const validationSchema = Yup.object().shape({
  code: Yup.string().required(i18n.t("validation.required")),
  initDesc: Yup.string().required(i18n.t("validation.required")),
});

const EditOpCode = React.forwardRef((props: IProps, ref) => {
  const classes = useGlobalStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [opCode, setOpCode] = useState<IOpCodeDTO>({ ...defaultOpCode });

  const handleClose = (): void => {
    if (!loading) {
      setOpen(false);
      setLoading(false);
      setOpCode({ ...defaultOpCode });
    }
  };

  const handleAdd = (): void => {
    setOpen(true);
  };

  const handleEdit = (item: IOpCodeDTO): void => {
    setOpen(true);
    setOpCode(item);
  };

  useImperativeHandle(ref, () => ({
    add: handleAdd,
    edit: handleEdit,
    close: handleClose,
  }));

  const onSubmit = (values: IOpCodeDTO): void => {
    setLoading(true);
    props
      .onSubmit(values)
      .then(() => handleClose())
      .finally(() => setLoading(false));
  };

  const formRender = () => {
    return (
      <Form noValidate autoComplete="on" style={{ maxWidth: 400 }}>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name="code"
              size="small"
              label="Code"
              required={true}
              InputProps={{
                disabled: opCode.id !== 0,
              }}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="initDesc"
              size="small"
              label="CDK Description"
              required={true}
              InputProps={{
                disabled: opCode.id !== 0,
              }}
              component={FormTextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="additionalDesc"
              size="small"
              label="Customer Description"
              component={FormTextField}
            />
          </Grid>

          <Grid item xs={12}>
            <SubmitButton
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              className={classes.saveButton}
            >
              Save
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <section style={{ padding: 15 }}>
          <Typography sx={{ fontSize: 20 }}>
            {opCode.id === 0 ? "Add" : "Edit"} Operational Code
          </Typography>
          <Formik
            onSubmit={onSubmit}
            initialValues={opCode}
            validationSchema={validationSchema}
          >
            {formRender}
          </Formik>
        </section>
      </Dialog>
    );
  };

  return render();
});

export default EditOpCode;
