import { ITipStatistic } from "./index";
import { IEmployee } from "../user";
import { ITipStatisticDto } from "../../../index";
import Utils from "../../util/Utils";
import ModuleHelper from "../../util/ModuleHelper";
import { StringBool } from "../../types/types";
import Employee from "../user/Employee";
import moment from "moment";

export default class TipStatistic implements ITipStatistic {
  private _createdAt: string;
  private _currency: string;
  private _employee: IEmployee;
  private _id: number;
  private _receivedAmount: number;
  private _transferredToEmployees: StringBool;

  constructor(args?: ITipStatisticDto) {
    this._id = Utils.default(args?.id, 0);
    this._employee = new Employee(args?.employee);
    this._createdAt = moment(Utils.default(args?.createdAt, "")).format(
      "MM/DD/YYYY HH:mm:ss"
    );
    this._currency = Utils.default(args?.currency, "");
    this._receivedAmount = ModuleHelper.centToDollar(
      Utils.default(args?.receivedAmount, 0)
    );
    this._transferredToEmployees = Utils.default(
      args?.transferredToEmployees,
      "false_"
    );
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    this._currency = value;
  }

  get employee(): IEmployee {
    return this._employee;
  }

  set employee(value: IEmployee) {
    this._employee = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get receivedAmount(): number {
    return this._receivedAmount;
  }

  set receivedAmount(value: number) {
    this._receivedAmount = value;
  }

  get transferredToEmployees(): StringBool {
    return this._transferredToEmployees;
  }

  set transferredToEmployees(value: StringBool) {
    this._transferredToEmployees = value;
  }

  isTransferred(): boolean {
    return ModuleHelper.stringBool(this._transferredToEmployees);
  }

  setIsTransferred(value: boolean): void {
    this._transferredToEmployees = ModuleHelper.boolean(value);
  }
}
