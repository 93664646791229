import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import route, { IPage } from "../../common/route";
import LoginService from "../../services/Login.service";
import store from "../../config/store";
import { get, entries, isEmpty } from "lodash";

interface Props {
  subPage?: IPage;
  mainPage: IPage;
  exact?: boolean;
  path: string;
  Component: React.FC<RouteComponentProps> | any;
}

const loginService = new LoginService();

const RouteAccess = ({
  Component,
  mainPage,
  subPage,
  path,
  exact,
}: Props): any => {
  const hasAccess = (parentPage: IPage, subPage?: IPage): boolean => {
    if (!store.getState().auth.hasRouteAccess(parentPage)) {
      return false;
    }

    if (!isEmpty(subPage)) {
      return store.getState().auth.hasRouteAccess(subPage);
    }

    return true;
  };

  const getPathname = (): string => {
    const array = Object.values(route);

    for (const item of array) {
      if (item.pathname === route.login.pathname) {
        loginService.logout().finally(() => {
          window.location.reload();
        });
        return "";
      }

      const hasPageAccess = hasAccess(item);

      if (!hasPageAccess) {
        continue;
      }

      const pages = get(item, "pages");

      if (isEmpty(pages)) {
        return item.pathname;
      }

      for (const entry of entries(pages)) {
        const subItem: IPage = get(entry, "[1]");

        const hasSubPageAccess = hasAccess(item, subItem);

        if (hasSubPageAccess) {
          return `${item.pathname}${subItem?.pathname}`;
        }
      }
    }

    loginService.logout().finally(() => {
      window.location.reload();
    });

    return "";
  };

  const render = (): React.ReactElement => {
    const access = hasAccess(mainPage, subPage);

    return (
      <Route
        path={path}
        exact={exact}
        render={(props: RouteComponentProps) =>
          access ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                state: { path: path },
                pathname: getPathname(),
              }}
            />
          )
        }
      />
    );
  };

  return render();
};

export default RouteAccess;
