import React, { useEffect, useState } from "react";
import DealershipService from "../../../services/Dealership.service";
import { IDealershipDTO } from "../../../../index";
import ModuleHelper from "../../../util/ModuleHelper";
import { Box, FormControlLabel, Grid, Switch } from "@mui/material";
import { Formik, Form, Field, FormikProps } from "formik";
import { FormTextField } from "../../../components/shared/form_text_field/FormTextField";
import { urlRegex } from "../../../util/constants";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/config";
import { PromptIfDirty } from "../../../components/prompt/PromptIfDirty";
import DealershipImage from "./DealershipImage";
import FormPhoneField from "../../../components/shared/form_phone_field/FormPhoneField";
import Utils from "../../../util/Utils";
import { useGlobalStyles } from "../../../util/ThemePalette";
import { get } from "lodash";
import VisibleContent from "../../../components/visable_content/VisibleContent";
import PaperWrapper from "../../../components/paper/PaperWrapper";
import { IDealership } from "../../../models/dealership";
import Dealership from "../../../models/dealership/Dealership";
import { FormSelectField } from "../../../components/shared/form_select_field/FormSelectField";

const Yup = Utils.validation.Yup;

const validationSchema = Yup.object().shape({
  _name: Yup.string().required("Required"),
  _timeZone: Yup.string().required("Required"),
  _cdkId: Yup.string().when("_connectedToCdk", {
    is: true,
    then: Yup.string().required(i18n.t("validation.required")),
  }),
  _crmIntegration: Yup.string()
    .when("_connectedToCrm", {
      is: true,
      then: Yup.string().nullable().required(i18n.t("validation.required")),
    })
    .nullable(),
  _crmImportFilePrefix: Yup.string()
    .when("_connectedToCrm", {
      is: true,
      then: Yup.string().nullable().required(i18n.t("validation.required")),
    })
    .nullable(),
  _mainPhone: Yup.string()
    .mobileNumber(i18n.t("validation.invalidPhoneNumber"))
    .required(i18n.t("validation.required")),
  _servicePhone: Yup.string()
    .mobileNumber(i18n.t("validation.invalidPhoneNumber"))
    .required(i18n.t("validation.required")),
  _primaryContactPhone: Yup.string()
    .mobileNumber(i18n.t("validation.invalidPhoneNumber"))
    .required(i18n.t("validation.required")),
  _mainUrl: Yup.string().test(
    "mainUrl",
    i18n.t("validation.invalidUrlAddress"),
    function (value: any) {
      return ModuleHelper.validateValue(value, urlRegex);
    }
  ),
  _serviceUrl: Yup.string().test(
    "serviceUrl",
    i18n.t("validation.invalidUrlAddress"),
    function (value: any) {
      return ModuleHelper.validateValue(value, urlRegex);
    }
  ),
  _primaryContactEmailAddress: Yup.string().email(
    i18n.t("validation.invalidEmailAddress")
  ),
});

const SubmitButton = styled(LoadingButton)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  float: "right",
  lineHeight: 1.5,
  width: "100%",
});

const timezones = ModuleHelper.getTimezones();

interface IProps {
  isNew?: boolean;

  onSuccess?(data: IDealershipDTO): void;
}

const DealershipDetails = (props: IProps): React.ReactElement => {
  const dealershipService = new DealershipService();

  const classes = useGlobalStyles();
  const { t } = useTranslation();

  const [refreshKey, setRefreshKey] = useState(Date.now());
  const [loading, setLoading] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [dealership, serDealership] = useState<IDealership>(new Dealership());

  useEffect(() => {
    setRefreshKey(Date.now());
  }, [dealership]);

  useEffect(() => {
    if (!props.isNew) {
      setPending(true);
      ModuleHelper.loader.show();
      dealershipService
        .getDealership()
        .then((response) => {
          serDealership(response);
        })
        .catch((error) => ModuleHelper.grow.showError(error))
        .finally(() => {
          setPending(false);
          ModuleHelper.loader.hide();
        });
    }
  }, [props.isNew]);

  const onSubmit = (values: IDealership, submitProps: any): void => {
    if (props.isNew) {
      createDealership(values, submitProps);
      return;
    }

    updateDealership(values, submitProps);
  };

  const createDealership = (values: IDealership, submitProps: any) => {
    setLoading(true);
    dealershipService
      .createDealership(values)
      .then((response) => {
        serDealership(response);
        props.onSuccess?.(response);
        submitProps.resetForm(response);
        ModuleHelper.grow.success(t("dealership.createdSuccessfully"));
      })
      .catch((error) => {
        setLoading(false);
        ModuleHelper.grow.showError(error);
      });
  };

  const updateDealership = (values: IDealership, submitProps: any): void => {
    setLoading(true);
    dealershipService
      .updateDealership(values)
      .then((response) => {
        serDealership(response);
        props.onSuccess?.(response);
        submitProps.resetForm(response);
        ModuleHelper.grow.success(t("dealership.updatedSuccessfully"));
      })
      .catch((error) => ModuleHelper.grow.showError(error))
      .finally(() => setLoading(false));
  };

  const formRender = (formikProps: FormikProps<IDealership>) => {
    const setFieldValue = formikProps.setFieldValue;
    const connectedToCdk = get(formikProps, "values.connectedToCdk");
    const connectedToCrm = get(formikProps, "values.connectedToCrm");

    return (
      <Form noValidate autoComplete="on">
        <Grid container justifyContent={"space-between"}>
          <PaperWrapper
            sx={{ marginBottom: 1 }}
            header={"CDK"}
            rightHeader={
              <FormControlLabel
                control={
                  <Switch checked={connectedToCdk} disabled={!props.isNew} />
                }
                label={"CDK Enabled"}
                style={{ color: "white" }}
                labelPlacement={"start"}
                onChange={(e) =>
                  setFieldValue("_connectedToCdk", get(e, "target.checked"))
                }
              />
            }
          >
            <Grid item xs={12}>
              <Field
                size="small"
                name="_cdkId"
                label="CDK ID"
                required={connectedToCdk}
                InputProps={{
                  disabled: !props.isNew,
                }}
                component={FormTextField}
              />
            </Grid>
          </PaperWrapper>

          <PaperWrapper
            sx={{ marginBottom: 1 }}
            header={"CRM"}
            rightHeader={
              <FormControlLabel
                control={
                  <Switch checked={connectedToCrm} disabled={!props.isNew} />
                }
                label={"CRM Enabled"}
                style={{ color: "white" }}
                labelPlacement={"start"}
                onChange={(e) =>
                  setFieldValue("_connectedToCrm", get(e, "target.checked"))
                }
              />
            }
          >
            <Grid item xs={12}>
              <Field
                size="small"
                name="_crmIntegration"
                label="CRM Integration"
                required={connectedToCrm}
                disabled={!props.isNew}
                options={["DealerSocket", "DealerVault"]}
                component={FormSelectField}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                size="small"
                name="_crmImportFilePrefix"
                label="CRM Import File Prefix"
                required={connectedToCrm}
                disabled={!props.isNew}
                component={FormTextField}
              />
            </Grid>
          </PaperWrapper>

          <PaperWrapper header={"Dealership Details"} sx={{ marginBottom: 1 }}>
            <VisibleContent visible={!props.isNew}>
              <Grid item xs={12}>
                <Field
                  name="id"
                  size="_small"
                  label="Dealership ID"
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  component={FormTextField}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  size="small"
                  name="_launchDate"
                  label="Launch Date"
                  InputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  component={FormTextField}
                />
              </Grid>
            </VisibleContent>

            <Grid item xs={12}>
              <Field
                size="small"
                name="_name"
                label="Name"
                required={true}
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_mainUrl"
                label="Main URL"
                required={true}
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_mainPhone"
                label="Main Phone"
                required={true}
                component={FormPhoneField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_serviceUrl"
                label="Service URL"
                required={true}
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_servicePhone"
                label="Service Phone"
                required={true}
                component={FormPhoneField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_primaryContactPhone"
                label="Primary Contact Phone"
                required={true}
                component={FormPhoneField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_timeZone"
                label="Time Zone"
                required={true}
                options={timezones}
                disableClearable={true}
                path={"value"}
                component={FormSelectField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_sellingOrganization"
                label="Selling Organization"
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_mainAddress"
                label="Main Address"
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_serviceAddress"
                label="Service Address"
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_primaryContactName"
                label="Primary Contact Name"
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_primaryContactEmailAddress"
                label="Primary Contact Email Address"
                component={FormTextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                size="small"
                name="_googlePlaceId"
                label="Google Place ID"
                component={FormTextField}
              />
            </Grid>

            <SubmitButton
              sx={{ marginBottom: 1 }}
              startIcon={<SaveIcon />}
              loading={loading}
              type="submit"
              variant="contained"
              size={"medium"}
              disableRipple
              color={"primary"}
              disabled={pending}
              className={classes.saveButton}
            >
              Save
            </SubmitButton>
          </PaperWrapper>
        </Grid>
        <PromptIfDirty message={t("common.unsavedChanges")} />
      </Form>
    );
  };

  const render = (): React.ReactElement => {
    return (
      <PaperWrapper>
        <Formik
          key={refreshKey}
          initialValues={dealership}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {formRender}
        </Formik>

        <VisibleContent visible={!props.isNew}>
          <Box sx={{ marginTop: 1 }}>
            <DealershipImage
              imageURL={dealership.bgrImgPublicURL}
              dealershipService={dealershipService}
            />
          </Box>
        </VisibleContent>
      </PaperWrapper>
    );
  };

  return render();
};

export default DealershipDetails;
