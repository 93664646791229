import { IDurationDTO } from "../../../index";
import Utils from "../../util/Utils";

export default class Duration implements IDuration {
  private _days: number;
  private _hours: number;
  private _isNegative: boolean;
  private _minutes: number;
  private _months: number;
  private _seconds: number;
  private _years: number;

  constructor(duration?: string) {
    const args = this.getDurationDTO(duration);

    this._days = Utils.default(args?.days, 0);
    this._hours = Utils.default(args?.hours, 0);
    this._isNegative = Utils.default(args?.isNegative, false);
    this._minutes = Utils.default(args?.minutes, 0);
    this._months = Utils.default(args?.months, 0);
    this._seconds = Utils.default(args?.seconds, 0);
    this._years = Utils.default(args?.years, 0);
  }

  get days(): number {
    return this._days;
  }

  set days(value: number) {
    this._days = value;
  }

  get hours(): number {
    return this._hours;
  }

  set hours(value: number) {
    this._hours = value;
  }

  get isNegative(): boolean {
    return this._isNegative;
  }

  set isNegative(value: boolean) {
    this._isNegative = value;
  }

  get minutes(): number {
    return this._minutes;
  }

  set minutes(value: number) {
    this._minutes = value;
  }

  get months(): number {
    return this._months;
  }

  set months(value: number) {
    this._months = value;
  }

  get seconds(): number {
    return this._seconds;
  }

  set seconds(value: number) {
    this._seconds = value;
  }

  get years(): number {
    return this._years;
  }

  set years(value: number) {
    this._years = value;
  }

  private getDurationDTO(duration?: string): IDurationDTO {
    try {
      return JSON.parse(`${duration}`);
    } catch (e) {
      return {
        days: 0,
        minutes: 0,
        months: 0,
        seconds: 0,
        years: 0,
        hours: 0,
        isNegative: false,
      };
    }
  }
}
