import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// import ReactGA from "react-ga";
import "./App.css";
import "./i18n/config";
import { useStyles } from "./App.styles";
import AppMenu from "./views/menu/AppMenu";
import Main from "./components/main/Main";
import { useHistory } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import { IStore } from "../index";
import route from "./common/route";
import Login from "./views/public/login/Login";
import AdminOverview from "./views/overview/AdminOverview";
import RouteAccess from "./components/route/RouteAccess";
import Profile from "./components/profile/Profile";
import UsersView from "./views/employees/UsersView";
import ThankYou from "./components/thank_you/ThankYou";
import RepairOrderView from "./views/repair_order/RepairOrderView";
import OperationalCodes from "./views/dealership/operational_codes/OperationalCodes";
import DealershipSettings from "./views/dealership/settings/DealershipSettings";
import NotificationSettings from "./views/dealership/notification_settings/NotificationSettings";
import Dealerships from "./views/dealerships/Dealerships";
import DealershipProfile from "./views/dealership/profile/DealershipProfile";
import UserActivate from "./views/public/user/UserActivate";
import AppStore from "./views/app_store/AppStore";
import VehicleReviewView from "./views/vehicle/VehicleReviewView";
import EmployeeOverview from "./views/overview/EmployeeOverview";
import TipsStatisticView from "./views/tips/TipsStatisticView";
import PageNotFound from "./views/NotFound";

// const trackingId: string = process.env.REACT_APP_TRACKING_ID || "";
// ReactGA.initialize(trackingId);

const App = (): React.ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector((store: IStore) => store.auth.user, shallowEqual);

  useEffect(() => {
    // console.log(trackingId);
    // ReactGA.pageview(window.location.pathname + window.location.search);

    if (user.id === 0) {
      return;
    }

    if (history.location.pathname === route.login.pathname) {
      history.push(route.dealership.pathname);
    }
  }, [user.id]);

  const routeRenderer = (): React.ReactElement => {
    if (user.id == 0) {
      return (
        <Switch>
          <Route path={route.userActivate.pathname} component={UserActivate} />
          <Route component={Login} />
        </Switch>
      );
    }

    return (
      <AppMenu>
        <Switch>
          <RouteAccess
            exact={true}
            Component={AdminOverview}
            mainPage={route.dealership}
            subPage={route.dealership.pages.overview}
            path={`${route.dealership.pathname}${route.dealership.pages.overview.pathname}`}
          />
          <RouteAccess
            exact={true}
            Component={UsersView}
            mainPage={route.dealership}
            subPage={route.dealership.pages.users}
            path={`${route.dealership.pathname}${route.dealership.pages.users.pathname}`}
          />
          <RouteAccess
            exact={true}
            Component={TipsStatisticView}
            mainPage={route.dealership}
            subPage={route.dealership.pages.tipRecords}
            path={`${route.dealership.pathname}${route.dealership.pages.tipRecords.pathname}`}
          />
          <RouteAccess
            exact={true}
            Component={DealershipProfile}
            mainPage={route.dealership}
            subPage={route.dealership.pages.profile}
            path={`${route.dealership.pathname}${route.dealership.pages.profile.pathname}`}
          />
          <RouteAccess
            exact={true}
            Component={OperationalCodes}
            mainPage={route.dealership}
            subPage={route.dealership.pages.opCodes}
            path={`${route.dealership.pathname}${route.dealership.pages.opCodes.pathname}`}
          />
          <RouteAccess
            exact={true}
            Component={DealershipSettings}
            mainPage={route.dealership}
            subPage={route.dealership.pages.settings}
            path={`${route.dealership.pathname}${route.dealership.pages.settings.pathname}`}
          />
          <RouteAccess
            exact={true}
            Component={NotificationSettings}
            mainPage={route.dealership}
            subPage={route.dealership.pages.notificationSettings}
            path={`${route.dealership.pathname}${route.dealership.pages.notificationSettings.pathname}`}
          />
          <RouteAccess
            exact={false}
            Component={Dealerships}
            mainPage={route.dealerships}
            path={route.dealerships.pathname}
          />
          <RouteAccess
            exact={false}
            Component={Profile}
            mainPage={route.profile}
            path={route.profile.pathname}
          />
          <RouteAccess
            exact={false}
            Component={EmployeeOverview}
            mainPage={route.leaderboard}
            path={route.leaderboard.pathname}
          />
          <RouteAccess
            exact={false}
            Component={AppStore}
            mainPage={route.appStore}
            path={route.appStore.pathname}
          />
          <RouteAccess
            exact={false}
            Component={ThankYou}
            mainPage={route.thankYou}
            path={route.thankYou.pathname}
          />
          <RouteAccess
            exact={false}
            Component={RepairOrderView}
            mainPage={route.repairOrder}
            path={route.repairOrder.pathname}
          />
          <RouteAccess
            exact={false}
            Component={VehicleReviewView}
            mainPage={route.vehicle}
            path={route.vehicle.pathname}
          />
          <RouteAccess
            exact={false}
            Component={PageNotFound}
            mainPage={route.notFound}
            path={route.notFound.pathname}
          />
        </Switch>
      </AppMenu>
    );
  };

  return (
    <div className={classes.root}>
      <Main className={classes.content}>{routeRenderer()}</Main>
    </div>
  );
};

export default App;
